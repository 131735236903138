import React, { useState } from 'react';
import AdminNav from './AdminNav';
import { Mail, Globe, Shield, Bell } from 'lucide-react';

const AdminSettings = () => {
  const [activeTab, setActiveTab] = useState('general');

  const tabs = [
    { id: 'general', label: 'General' },
    { id: 'notificaciones', label: 'Notificaciones' },
    { id: 'seguridad', label: 'Seguridad' },
  ];

  return (
    <AdminNav>
      <div className="flex flex-col space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-bold text-white">Configuración</h1>
            <p className="mt-1 text-sm text-zinc-400">Gestiona la configuración de Firepit</p>
          </div>
        </div>

        {/* Tabs */}
        <div className="flex mb-6 space-x-4 border-b border-zinc-700">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`px-4 py-2 -mb-px text-sm font-medium transition-colors border-b-2 ${
                activeTab === tab.id
                  ? 'text-orange-500 border-orange-500'
                  : 'text-zinc-400 border-transparent hover:text-zinc-300'
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>

        {/* Settings Content */}
        <div className="grid gap-6">
          {activeTab === 'general' && (
            <>
              {/* General Settings */}
              <div className="bg-zinc-900 rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <Globe className="w-5 h-5 text-orange-500 mr-3" />
                  <h2 className="text-xl font-semibold text-white">Configuración General</h2>
                </div>
                <p className="text-zinc-400">Configuración general próximamente...</p>
              </div>

              {/* Email Settings */}
              <div className="bg-zinc-900 rounded-lg p-6">
                <div className="flex items-center mb-4">
                  <Mail className="w-5 h-5 text-orange-500 mr-3" />
                  <h2 className="text-xl font-semibold text-white">Configuración de Correo</h2>
                </div>
                <p className="text-zinc-400">Configuración de correo próximamente...</p>
              </div>
            </>
          )}

          {activeTab === 'notificaciones' && (
            <div className="bg-zinc-900 rounded-lg p-6">
              <div className="flex items-center mb-4">
                <Bell className="w-5 h-5 text-orange-500 mr-3" />
                <h2 className="text-xl font-semibold text-white">Configuración de Notificaciones</h2>
              </div>
              <p className="text-zinc-400">Configuración de notificaciones próximamente...</p>
            </div>
          )}

          {activeTab === 'seguridad' && (
            <div className="bg-zinc-900 rounded-lg p-6">
              <div className="flex items-center mb-4">
                <Shield className="w-5 h-5 text-orange-500 mr-3" />
                <h2 className="text-xl font-semibold text-white">Configuración de Seguridad</h2>
              </div>
              <p className="text-zinc-400">Configuración de seguridad próximamente...</p>
            </div>
          )}
        </div>
      </div>
    </AdminNav>
  );
};

export default AdminSettings; 