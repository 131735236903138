import React, { useState, useEffect } from "react";
import { Search, Plus, Edit2, Trash2 } from "lucide-react";
import Modal from "../../common/Modal";
import {
  getVenues,
  createVenue,
  updateVenue,
  deleteVenue,
} from "../../../services/api";
import { useCommunity } from "../../../contexts/CommunityContext";
import VenueForm from "./VenueForm";

const VenueList = () => {
  const [venues, setVenues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState(null);
  const { activeCommunity } = useCommunity();

  useEffect(() => {
    if (activeCommunity?.id) {
      fetchVenues();
    }
  }, [activeCommunity]);

  const fetchVenues = async () => {
    try {
      const response = await getVenues(activeCommunity.id);
      setVenues(response.data || response);
      setError(null);
    } catch (err) {
      setError("Failed to load venues");
      console.error("Error fetching venues:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModals = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
    setSelectedVenue(null);
  };

  const handleEdit = (venue) => {
    setSelectedVenue(venue);
    setShowEditModal(true);
  };

  const handleSubmit = async (formData, isEdit = false) => {
    try {
      let response;
      if (isEdit && selectedVenue?.id) {
        response = await updateVenue(activeCommunity.id, selectedVenue.id, formData);
      } else {
        response = await createVenue(activeCommunity.id, formData);
      }

      const newVenue = response.data || response;

      if (isEdit) {
        setVenues(venues.map((venue) => 
          venue.id === newVenue.id ? newVenue : venue
        ));
      } else {
        setVenues([...venues, newVenue]);
      }

      handleCloseModals();
    } catch (err) {
      console.error("Error saving venue:", err);
      setError(err.message || "Failed to save venue");
      throw err;
    }
  };

  const handleDelete = async () => {
    try {
      await deleteVenue(activeCommunity.id, selectedVenue.id);
      setVenues(venues.filter((venue) => venue.id !== selectedVenue.id));
      handleCloseModals();
    } catch (err) {
      console.error("Error deleting venue:", err);
      setError("Failed to delete venue");
    }
  };

  const filteredVenues = venues.filter(
    (venue) =>
      venue.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      venue.address?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      venue.city?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center py-8">
        <div className="w-8 h-8 border-b-2 border-orange-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-6">
      {/* Header with Search and Create Button */}
      <div className="flex items-center justify-between">
        <div className="relative flex-1 max-w-md">
          <Search className="absolute w-5 h-5 text-zinc-400 -translate-y-1/2 pointer-events-none left-4 top-1/2" />
          <input
            type="text"
            style={{ paddingLeft: "44px" }}
            className="w-full px-4 text-white placeholder-zinc-400 bg-zinc-900 border border-zinc-700 rounded-lg h-11 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
            placeholder="Buscar sedes..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <button
          className="flex items-center px-4 py-2 space-x-2 text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600"
          onClick={() => setShowCreateModal(true)}
        >
          <Plus className="w-5 h-5" />
          <span>Nueva Sede</span>
        </button>
      </div>

      {/* Venues List */}
      <div className="rounded-lg bg-zinc-900/50">
        {error && (
          <div className="p-4 mb-6 text-red-500 border border-red-500 rounded-lg bg-red-500/10">
            {error}
          </div>
        )}

        {filteredVenues.length === 0 ? (
          <div className="py-8 text-center text-zinc-400">
            {searchTerm
              ? "No venues found matching your search."
              : "No venues found. Create one to get started!"}
          </div>
        ) : (
          <div className="divide-y divide-zinc-800">
            {filteredVenues.map((venue) => (
              <div
                key={venue.id}
                className="flex items-start justify-between p-6 transition-colors hover:bg-zinc-800/50"
              >
                <div className="flex-1">
                  <div className="flex items-center justify-between mb-2">
                    <h3 className="text-lg font-semibold text-white">{venue.name}</h3>
                    <div className="flex space-x-2">
                      <button
                        className="p-2 text-zinc-400 transition-colors rounded-lg hover:text-white hover:bg-zinc-700"
                        onClick={() => handleEdit(venue)}
                        title="Edit venue"
                      >
                        <Edit2 className="w-4 h-4" />
                      </button>
                      <button
                        className="p-2 text-zinc-400 transition-colors rounded-lg hover:text-red-500 hover:bg-zinc-700"
                        onClick={() => {
                          setSelectedVenue(venue);
                          setShowDeleteModal(true);
                        }}
                        title="Delete venue"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                  <p className="text-sm text-zinc-400">
                    {venue.description}
                  </p>
                  <div className="flex flex-col mt-2 space-y-1 text-sm text-zinc-400">
                    {venue.address && (
                      <span>{venue.address}</span>
                    )}
                    <span>
                      {[venue.city, venue.state, venue.postal_code]
                        .filter(Boolean)
                        .join(", ")}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Modals */}
      <Modal
        isOpen={showCreateModal}
        onClose={handleCloseModals}
        title="Nueva Sede"
        size="medium"
      >
        <VenueForm
          onSubmit={(data) => handleSubmit(data, false)}
          onCancel={handleCloseModals}
        />
      </Modal>

      <Modal
        isOpen={showEditModal}
        onClose={handleCloseModals}
        title="Edit Venue"
        size="medium"
      >
        <VenueForm
          venue={selectedVenue}
          onSubmit={(data) => handleSubmit(data, true)}
          onCancel={handleCloseModals}
        />
      </Modal>

      <Modal
        isOpen={showDeleteModal}
        onClose={handleCloseModals}
        title="Delete Venue"
        size="small"
      >
        <div className="p-6">
          <p className="mb-6">
            Are you sure you want to delete "{selectedVenue?.name}"?
          </p>
          <div className="flex justify-end space-x-3">
            <button
              className="px-4 py-2 text-zinc-400 transition-colors hover:text-white"
              onClick={handleCloseModals}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 text-white transition-colors bg-red-500 rounded-lg hover:bg-red-600"
              onClick={handleDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default VenueList; 