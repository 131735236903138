import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useCommunity } from '../../contexts/CommunityContext';
import Modal from '../common/Modal';
import api from '../../services/api';
import './UserMenu.css';

const UserMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [communities, setCommunities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const menuRef = useRef(null);
    const navigate = useNavigate();
    const { logout } = useAuth();
    const { activeCommunity, setCurrentCommunity } = useCommunity();

    const [notifications, setNotifications] = useState([
        {
            id: 1,
            title: 'Nuevo evento',
            message: 'Se ha creado un nuevo evento en tu comunidad',
            time: '5 min',
            unread: true
        },
        {
            id: 2,
            title: 'Invitación',
            message: 'Has sido invitado a unirte a una nueva comunidad',
            time: '1 hora',
            unread: true
        }
    ]);

    const user = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        if (isOpen) {
            fetchCommunities();
        }
    }, [isOpen]);

    const fetchCommunities = async () => {
        try {
            setLoading(true);
            setError('');
            const response = await api.get('/my-communities');
            setCommunities(response || []);
        } catch (err) {
            console.error('Error fetching communities:', err);
            setError('Failed to load communities');
        } finally {
            setLoading(false);
        }
    };

    const handleLogout = async () => {
        try {
            setIsOpen(false);
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const getInitials = (name) => {
        if (!name) return '';
        
        return name
            .split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase()
            .slice(0, 2);
    };

    const handleCommunitySelect = async (community) => {
        try {
            await api.post(`/communities/${community.id}/set-active`);
            
            setCurrentCommunity(community);
            setIsOpen(false);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error switching community:', error);
            setError('Failed to switch community');
        }
    };

    const handleNotificationClick = (notification) => {
        setNotifications(prev => 
            prev.map(n => n.id === notification.id ? { ...n, unread: false } : n)
        );
    };

    const unreadCount = notifications.filter(n => n.unread).length;

    const availableCommunities = communities.filter(
        community => community.id !== activeCommunity?.id
    );

    return (
        <div className="relative" ref={menuRef}>
            <button 
                className="flex items-center gap-3 p-2 transition-colors rounded-lg hover:bg-black/10"
                onClick={() => setIsOpen(true)}
            >
                <span className="hidden text-white md:block">{user.name}</span>
                {user.avatar_url ? (
                    <div className="relative">
                        <img src={user.avatar_url} alt={user.name} className="w-8 h-8 rounded-full" />
                        {unreadCount > 0 && (
                            <div className="absolute -top-1 -right-1 min-w-[18px] h-[18px] text-xs font-bold text-white bg-red-600 rounded-full px-1 flex items-center justify-center">
                                {unreadCount}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="relative flex items-center justify-center w-8 h-8 text-sm font-bold text-white bg-orange-500 rounded-full">
                        {getInitials(user.name)}
                        {unreadCount > 0 && (
                            <div className="absolute -top-1 -right-1 min-w-[18px] h-[18px] text-xs font-bold text-white bg-red-600 rounded-full px-1 flex items-center justify-center">
                                {unreadCount}
                            </div>
                        )}
                    </div>
                )}
            </button>

            <Modal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                title="Mi Cuenta"
                size="small"
                hideHeader={true}
            >
                <div className="flex flex-col divide-y divide-zinc-100">
                    {/* Active Community */}
                    {activeCommunity && (
                        <div className="p-4">
                            <div className="text-xs font-medium text-zinc-500 uppercase">
                                Comunidad Activa
                            </div>
                            <button 
                                className="flex items-center w-full gap-3 px-3 py-2 mt-2 text-left text-orange-600 transition-colors rounded-lg bg-orange-50"
                                onClick={() => {
                                    setIsOpen(false);
                                    navigate('/dashboard');
                                }}
                            >
                                {activeCommunity.image_url ? (
                                    <img src={activeCommunity.image_url} alt={activeCommunity.name} className="w-8 h-8 rounded-lg" />
                                ) : (
                                    <div className="flex items-center justify-center w-8 h-8 text-sm font-bold text-white bg-orange-500 rounded-lg">
                                        {getInitials(activeCommunity.name)}
                                    </div>
                                )}
                                <span className="font-medium">{activeCommunity.name}</span>
                            </button>
                        </div>
                    )}

                    {/* Other Communities */}
                    {availableCommunities.length > 0 && (
                        <div className="p-4">
                            <div className="text-xs font-medium text-zinc-500 uppercase">
                                Otras Comunidades
                            </div>
                            <div className="mt-2 space-y-1">
                                {availableCommunities.map(community => (
                                    <button
                                        key={community.id}
                                        className="flex items-center w-full gap-3 px-3 py-2 text-left transition-colors rounded-lg hover:bg-zinc-50"
                                        onClick={() => {
                                            handleCommunitySelect(community);
                                            setIsOpen(false);
                                        }}
                                    >
                                        {community.image_url ? (
                                            <img src={community.image_url} alt={community.name} className="w-8 h-8 rounded-lg" />
                                        ) : (
                                            <div className="flex items-center justify-center w-8 h-8 text-sm font-bold text-white bg-zinc-500 rounded-lg">
                                                {getInitials(community.name)}
                                            </div>
                                        )}
                                        <span className="font-medium text-zinc-700">{community.name}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}

                    {/* Notifications */}
                    <div className="p-4">
                        <div className="text-xs font-medium text-zinc-500 uppercase">
                            Notificaciones
                        </div>
                        <div className="mt-2 space-y-2">
                            {notifications.map(notification => (
                                <div
                                    key={notification.id}
                                    className={`p-3 rounded-lg cursor-pointer transition-colors ${
                                        notification.unread ? 'bg-blue-50' : 'bg-zinc-50'
                                    }`}
                                    onClick={() => handleNotificationClick(notification)}
                                >
                                    <div className="text-sm font-medium text-zinc-900">
                                        {notification.title}
                                    </div>
                                    <div className="mt-1 text-sm text-zinc-600">
                                        {notification.message}
                                    </div>
                                    <div className="mt-1 text-xs text-zinc-500">
                                        {notification.time}
                                    </div>
                                </div>
                            ))}
                            {notifications.length === 0 && (
                                <div className="p-3 text-sm text-center text-zinc-500 rounded-lg bg-zinc-50">
                                    No hay notificaciones
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Admin Link */}
                    {user.role === 'admin' && (
                        <div className="p-4">
                            <Link
                                to="/admin"
                                className="flex items-center justify-center w-full gap-2 px-4 py-2 font-medium text-white transition-colors bg-zinc-900 rounded-lg hover:bg-zinc-800"
                                onClick={() => setIsOpen(false)}
                            >
                                Administración del Sitio
                            </Link>
                        </div>
                    )}

                    {/* Logout */}
                    <div className="p-4">
                        <button
                            onClick={handleLogout}
                            className="flex items-center justify-center w-full gap-2 px-4 py-2 font-medium text-white transition-colors bg-red-600 rounded-lg hover:bg-red-700"
                        >
                            Cerrar Sesión
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default UserMenu; 