import React, { useState } from 'react';
import api from '../../services/api';
import './CommunityForm.css';

const CommunityForm = ({ community, onSuccess, onCancel }) => {
    const [formData, setFormData] = useState({
        name: community?.name || '',
        description: community?.description || '',
        image_url: community?.image_url || '',
        is_active: community?.is_active ?? true
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
        // Clear field-specific error when user starts typing
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        }
        if (!formData.description.trim()) {
            newErrors.description = 'Description is required';
        }
        if (formData.image_url && !formData.image_url.match(/^https?:\/\/.+/)) {
            newErrors.image_url = 'Please enter a valid URL';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            setIsSubmitting(true);
            setError('');

            const response = community
                ? await api.put(`/communities/${community.id}`, formData)
                : await api.post('/communities', formData);

            // API service returns the data directly
            if (response) {
                onSuccess(response);
            } else {
                throw new Error('No response data received');
            }
        } catch (err) {
            console.error('Error submitting community:', err);
            if (err.errors) {
                setErrors(err.errors);
            } else {
                setError(err.message || 'Failed to save community. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="community-form">
            {error && <div className="form-error">{error}</div>}

            <div className="form-group">
                <label htmlFor="name">Name *</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter community name"
                    className={errors.name ? 'error' : ''}
                    disabled={isSubmitting}
                />
                {errors.name && <span className="error-message">{errors.name}</span>}
            </div>

            <div className="form-group">
                <label htmlFor="description">Description *</label>
                <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Enter community description"
                    rows="4"
                    className={errors.description ? 'error' : ''}
                    disabled={isSubmitting}
                />
                {errors.description && <span className="error-message">{errors.description}</span>}
            </div>

            <div className="form-group">
                <label htmlFor="image_url">Image URL</label>
                <input
                    type="url"
                    id="image_url"
                    name="image_url"
                    value={formData.image_url}
                    onChange={handleChange}
                    placeholder="Enter image URL"
                    className={errors.image_url ? 'error' : ''}
                    disabled={isSubmitting}
                />
                {errors.image_url && <span className="error-message">{errors.image_url}</span>}
            </div>

            <div className="form-group checkbox-group">
                <label>
                    <input
                        type="checkbox"
                        name="is_active"
                        checked={formData.is_active}
                        onChange={handleChange}
                        disabled={isSubmitting}
                    />
                    Active
                </label>
            </div>

            <div className="form-actions">
                <button
                    type="button"
                    onClick={onCancel}
                    className="cancel-button"
                    disabled={isSubmitting}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="submit-button"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Saving...' : (community ? 'Update' : 'Create')}
                </button>
            </div>
        </form>
    );
};

export default CommunityForm; 