import React, { useState, useEffect } from "react";
import { Plus, Trash, Check, AlertCircle, Edit } from "lucide-react";
import Modal from "../../common/Modal";
import api from "../../../services/api";

const TicketForm = ({
  formData,
  setFormData,
  event,
  error,
  loading,
  onSubmit,
  onClose,
  submitText,
}) => (
  <form onSubmit={onSubmit} className="space-y-4">
    {error && (
      <div className="p-3 mb-4 text-sm text-red-500 border border-red-500 rounded-lg bg-red-500/10">
        {error}
      </div>
    )}

    <div className="grid grid-cols-2 gap-4">
      <div>
        <label className="block mb-1 text-sm font-medium text-zinc-700">
          Tipo de ticket
        </label>
        <input
          type="text"
          value={formData.type}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, type: e.target.value }))
          }
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
          required
        />
      </div>
      <div>
        <label className="block mb-1 text-sm font-medium text-zinc-700">
          Precio (MXN)
        </label>
        <input
          type="number"
          min="0"
          step="0.01"
          value={formData.price}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              price: parseFloat(e.target.value),
            }))
          }
          className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
          required
        />
      </div>
    </div>

    <div>
      <label className="block mb-1 text-sm font-medium text-zinc-700">
        Cantidad disponible
      </label>
      <input
        type="number"
        min="1"
        value={formData.quantity_available}
        onChange={(e) =>
          setFormData((prev) => ({
            ...prev,
            quantity_available: parseInt(e.target.value),
          }))
        }
        className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
        required
      />
    </div>

    <div className="grid grid-cols-2 gap-4">
      <div>
        <label className="block mb-1 text-sm font-medium text-zinc-700">
          Inicio de venta
        </label>
        <div className="grid grid-cols-2 gap-2">
          <input
            type="date"
            value={formData.sale_starts_at}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                sale_starts_at: e.target.value,
              }))
            }
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
            required
          />
          <input
            type="time"
            value={formData.sale_starts_time}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                sale_starts_time: e.target.value,
              }))
            }
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
            required
          />
        </div>
      </div>
      <div>
        <label className="block mb-1 text-sm font-medium text-zinc-700">
          Fin de venta
        </label>
        <div className="grid grid-cols-2 gap-2">
          <input
            type="date"
            value={formData.sale_ends_at}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                sale_ends_at: e.target.value,
              }))
            }
            max={
              event?.registration_deadline
                ? event.registration_deadline.split("T")[0]
                : undefined
            }
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
            required
          />
          <input
            type="time"
            value={formData.sale_ends_time}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                sale_ends_time: e.target.value,
              }))
            }
            className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
            required
          />
        </div>
      </div>
    </div>

    <div>
      <label className="block mb-1 text-sm font-medium text-zinc-700">
        Descripción
      </label>
      <textarea
        value={formData.description}
        onChange={(e) =>
          setFormData((prev) => ({
            ...prev,
            description: e.target.value,
          }))
        }
        className="w-full px-3 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
        rows={3}
      />
    </div>

    <div className="flex items-center">
      <input
        type="checkbox"
        id="is_active"
        checked={formData.is_active}
        onChange={(e) =>
          setFormData((prev) => ({
            ...prev,
            is_active: e.target.checked,
          }))
        }
        className="w-4 h-4 text-orange-500 border-zinc-300 rounded focus:ring-orange-500"
      />
      <label htmlFor="is_active" className="block ml-2 text-sm text-zinc-700">
        Activar ticket inmediatamente
      </label>
    </div>

    <div className="flex justify-end space-x-3">
      <button
        type="button"
        onClick={onClose}
        className="px-4 py-2 text-sm text-zinc-500 transition-colors hover:text-zinc-700"
        disabled={loading}
      >
        Cancelar
      </button>
      <button
        type="submit"
        className="px-4 py-2 text-sm text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600 disabled:opacity-50"
        disabled={loading}
      >
        {loading ? "Guardando..." : submitText}
      </button>
    </div>
  </form>
);

const TicketStatusButton = ({ ticket, loading, onToggle }) => (
  <button
    onClick={() => onToggle(ticket)}
    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium transition-colors ${
      ticket.is_active
        ? "bg-green-100 text-green-800 hover:bg-red-100 hover:text-red-800"
        : "bg-zinc-100 text-zinc-800 hover:bg-green-100 hover:text-green-800"
    }`}
    disabled={loading}
  >
    {ticket.is_active ? "Activo" : "Inactivo"}
  </button>
);

const CreateTicketModal = ({ event, isOpen, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    type: "",
    price: 0,
    quantity_available: 0,
    sale_starts_at: "",
    sale_starts_time: "",
    sale_ends_at: "",
    sale_ends_time: "",
    description: "",
    is_active: false,
  });

  useEffect(() => {
    if (isOpen && event?.registration_deadline) {
      const deadline = new Date(event.registration_deadline);
      setFormData((prev) => ({
        ...prev,
        sale_ends_at: deadline.toISOString().split("T")[0],
        sale_ends_time: deadline.toTimeString().slice(0, 5),
      }));
    }

    if (!isOpen) {
      setError("");
      setFormData({
        type: "",
        price: 0,
        quantity_available: 0,
        sale_starts_at: "",
        sale_starts_time: "",
        sale_ends_at: "",
        sale_ends_time: "",
        description: "",
        is_active: false,
      });
    }
  }, [isOpen, event?.registration_deadline]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      setError("");

      const ticketData = {
        ...formData,
        sale_starts_at: `${formData.sale_starts_at}T${formData.sale_starts_time}:00`,
        sale_ends_at: `${formData.sale_ends_at}T${formData.sale_ends_time}:00`,
      };

      await api.post(`/events/${event.id}/tickets`, ticketData);
      onSuccess();
      onClose();
    } catch (err) {
      console.error("Error creating ticket:", err);
      setError(err.response?.data?.message || "Error al crear el ticket");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Nuevo Ticket" size="medium">
      <div className="p-6">
        <TicketForm
          formData={formData}
          setFormData={setFormData}
          event={event}
          error={error}
          loading={loading}
          onSubmit={handleSubmit}
          onClose={onClose}
          submitText="Guardar Ticket"
        />
      </div>
    </Modal>
  );
};

const EditTicketModal = ({ event, ticket, isOpen, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    type: "",
    price: 0,
    quantity_available: 0,
    sale_starts_at: "",
    sale_starts_time: "",
    sale_ends_at: "",
    sale_ends_time: "",
    description: "",
    is_active: false,
  });

  useEffect(() => {
    if (isOpen && ticket) {
      const saleStartsDate = new Date(ticket.sale_starts_at);
      const saleEndsDate = new Date(ticket.sale_ends_at);

      setFormData({
        type: ticket.type,
        price: parseFloat(ticket.price),
        quantity_available: ticket.quantity_available,
        sale_starts_at: saleStartsDate.toISOString().split("T")[0],
        sale_starts_time: saleStartsDate.toTimeString().slice(0, 5),
        sale_ends_at: saleEndsDate.toISOString().split("T")[0],
        sale_ends_time: saleEndsDate.toTimeString().slice(0, 5),
        description: ticket.description || "",
        is_active: ticket.is_active,
      });
    }

    if (!isOpen) {
      setError("");
    }
  }, [isOpen, ticket]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      setError("");

      const ticketData = {
        ...formData,
        sale_starts_at: `${formData.sale_starts_at}T${formData.sale_starts_time}:00`,
        sale_ends_at: `${formData.sale_ends_at}T${formData.sale_ends_time}:00`,
      };

      await api.put(`/events/${event.id}/tickets/${ticket.id}`, ticketData);
      onSuccess();
      onClose();
    } catch (err) {
      console.error("Error updating ticket:", err);
      setError(err.response?.data?.message || "Error al actualizar el ticket");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Editar Ticket"
      size="medium"
    >
      <div className="p-6">
        <TicketForm
          formData={formData}
          setFormData={setFormData}
          event={event}
          error={error}
          loading={loading}
          onSubmit={handleSubmit}
          onClose={onClose}
          submitText="Actualizar Ticket"
        />
      </div>
    </Modal>
  );
};

const DeleteTicketModal = ({ event, ticket, isOpen, onClose, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleDelete = async () => {
    try {
      setLoading(true);
      setError("");
      await api.delete(`/events/${event.id}/tickets/${ticket.id}`);
      onSuccess();
      onClose();
    } catch (err) {
      console.error("Error deleting ticket:", err);
      setError(err.response?.data?.message || "Error al eliminar el ticket");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Eliminar Ticket"
      size="small"
    >
      <div className="p-6">
        {error && (
          <div className="p-3 mb-4 text-sm text-red-500 border border-red-500 rounded-lg bg-red-500/10">
            {error}
          </div>
        )}

        <div className="mb-6">
          <h3 className="mb-2 text-lg font-medium text-zinc-900">
            ¿Estás seguro de eliminar este ticket?
          </h3>
          <p className="text-sm text-zinc-600">
            Esta acción no se puede deshacer. El ticket{" "}
            <span className="font-medium">{ticket?.type}</span> será eliminado
            permanentemente.
          </p>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 text-sm text-zinc-500 transition-colors hover:text-zinc-700"
            disabled={loading}
          >
            Cancelar
          </button>
          <button
            type="button"
            onClick={handleDelete}
            className="px-4 py-2 text-sm text-white transition-colors bg-red-500 rounded-lg hover:bg-red-600 disabled:opacity-50"
            disabled={loading}
          >
            {loading ? "Eliminando..." : "Eliminar Ticket"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const TicketModal = ({ event, isOpen, onClose }) => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingTicket, setEditingTicket] = useState(null);
  const [deletingTicket, setDeletingTicket] = useState(null);

  useEffect(() => {
    if (isOpen && event?.id) {
      fetchTickets();
    }

    if (!isOpen) {
      setShowAddForm(false);
      setEditingTicket(null);
      setDeletingTicket(null);
      setTickets([]);
      setError("");
    }
  }, [isOpen, event?.id]);

  const fetchTickets = async () => {
    try {
      setLoading(true);
      console.log("Fetching tickets for event:", event.id);
      const response = await api.get(`/events/${event.id}/tickets`);
      console.log("Tickets response:", response);
      setTickets(response || []);
      setError("");
    } catch (err) {
      console.error("Error fetching tickets:", err);
      setError("Error al cargar los tickets");
    } finally {
      setLoading(false);
    }
  };

  const handleToggleActive = async (ticket) => {
    try {
      setLoading(true);
      await api.patch(`/events/${event.id}/tickets/${ticket.id}/toggle-active`);
      await fetchTickets();
    } catch (err) {
      console.error("Error toggling ticket status:", err);
      setError("Error al cambiar el estado del ticket");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (ticket) => {
    setDeletingTicket(ticket);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Gestionar Tickets"
        size="large"
      >
        <div className="p-6">
          {error && (
            <div className="p-3 mb-4 text-sm text-red-500 border border-red-500 rounded-lg bg-red-500/10">
              {error}
            </div>
          )}

          {/* Tickets List */}
          <div className="mb-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold text-zinc-900">
                Tickets disponibles
              </h3>
              <button
                onClick={() => setShowAddForm(true)}
                className="flex items-center px-3 py-2 text-sm text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600"
                disabled={loading}
              >
                <Plus className="w-4 h-4 mr-2" />
                Agregar Ticket
              </button>
            </div>

            {loading ? (
              <div className="text-center text-zinc-500">Cargando...</div>
            ) : tickets.length === 0 ? (
              <div className="flex flex-col items-center justify-center p-8 text-zinc-500 border-2 border-dashed rounded-lg">
                <AlertCircle className="w-12 h-12 mb-2 text-zinc-400" />
                <p>No hay tickets configurados</p>
                <button
                  onClick={() => setShowAddForm(true)}
                  className="mt-2 text-sm text-orange-500 hover:text-orange-600"
                >
                  Agregar el primer ticket
                </button>
              </div>
            ) : (
              <div className="overflow-hidden border border-zinc-200 rounded-lg">
                {/* Desktop Table */}
                <div className="hidden md:block">
                  <table className="min-w-full divide-y divide-zinc-200">
                    <thead className="bg-zinc-50">
                      <tr>
                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-zinc-500 uppercase">
                          Tipo
                        </th>
                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-zinc-500 uppercase">
                          Precio
                        </th>
                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-zinc-500 uppercase">
                          Disponibles
                        </th>
                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-zinc-500 uppercase">
                          Estado
                        </th>
                        <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-zinc-500 uppercase">
                          Acciones
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-zinc-200">
                      {tickets.map((ticket) => (
                        <tr key={ticket.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="font-medium text-zinc-900">
                              {ticket.type}
                            </div>
                            {ticket.description && (
                              <div className="text-sm text-zinc-500">
                                {ticket.description}
                              </div>
                            )}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            ${parseFloat(ticket.price).toFixed(2)} MXN
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {ticket.quantity_available - ticket.quantity_sold}{" "}
                            de {ticket.quantity_available}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <TicketStatusButton
                              ticket={ticket}
                              loading={loading}
                              onToggle={handleToggleActive}
                            />
                          </td>
                          <td className="px-6 py-4 space-x-2 whitespace-nowrap">
                            <button
                              onClick={() => setEditingTicket(ticket)}
                              className="inline-flex items-center px-2 py-1 text-xs font-medium text-blue-700 bg-blue-100 rounded-md hover:bg-blue-200"
                              disabled={loading}
                            >
                              <Edit className="w-3 h-3 mr-1" />
                              Editar
                            </button>
                            {ticket.quantity_sold === 0 &&
                              tickets.length > 1 && (
                                <button
                                  onClick={() => handleDelete(ticket)}
                                  className="inline-flex items-center px-2 py-1 text-xs font-medium text-red-700 bg-red-100 rounded-md hover:bg-red-200"
                                  disabled={loading}
                                >
                                  Eliminar
                                </button>
                              )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Mobile Cards */}
                <div className="block divide-y divide-zinc-200 md:hidden">
                  {tickets.map((ticket) => (
                    <div key={ticket.id} className="p-4 space-y-3">
                      <div>
                        <div className="font-medium text-zinc-900">
                          {ticket.type}
                        </div>
                        {ticket.description && (
                          <div className="text-sm text-zinc-500">
                            {ticket.description}
                          </div>
                        )}
                      </div>

                      <div className="grid grid-cols-2 gap-2 text-sm">
                        <div>
                          <div className="text-zinc-500">Precio</div>
                          <div className="font-medium">
                            ${parseFloat(ticket.price).toFixed(2)} MXN
                          </div>
                        </div>
                        <div>
                          <div className="text-zinc-500">Disponibles</div>
                          <div className="font-medium">
                            {ticket.quantity_available - ticket.quantity_sold}{" "}
                            de {ticket.quantity_available}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center justify-between">
                        <TicketStatusButton
                          ticket={ticket}
                          loading={loading}
                          onToggle={handleToggleActive}
                        />

                        <div className="flex space-x-2">
                          <button
                            onClick={() => setEditingTicket(ticket)}
                            className="inline-flex items-center px-2 py-1 text-xs font-medium text-blue-700 bg-blue-100 rounded-md hover:bg-blue-200"
                            disabled={loading}
                          >
                            <Edit className="w-3 h-3 mr-1" />
                            Editar
                          </button>
                          {ticket.quantity_sold === 0 && tickets.length > 1 && (
                            <button
                              onClick={() => handleDelete(ticket)}
                              className="inline-flex items-center px-2 py-1 text-xs font-medium text-red-700 bg-red-100 rounded-md hover:bg-red-200"
                              disabled={loading}
                            >
                              Eliminar
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>

      <CreateTicketModal
        event={event}
        isOpen={showAddForm}
        onClose={() => setShowAddForm(false)}
        onSuccess={fetchTickets}
      />

      <EditTicketModal
        event={event}
        ticket={editingTicket}
        isOpen={!!editingTicket}
        onClose={() => setEditingTicket(null)}
        onSuccess={fetchTickets}
      />

      <DeleteTicketModal
        event={event}
        ticket={deletingTicket}
        isOpen={!!deletingTicket}
        onClose={() => setDeletingTicket(null)}
        onSuccess={fetchTickets}
      />
    </>
  );
};

export default TicketModal;
