import { Award, Bolt, ChartLine, icons, LogOut, LogOutIcon, Rocket, Share, SquareArrowOutUpRight, UserRoundCheck } from 'lucide-react';
import React, { useState } from 'react';
import { Helmet } from "react-helmet-async";
const Embajadores = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    motivation: '',
    impact: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const benefits = [
    {
	  icon: <Rocket className='w-20 h-20 mb-2 text-orange-400' />,
      title: 'Impacto',
      description: 'Transforma ideas en acciones concretas que cambian vidas. Sé el catalizador que hace posible lo imposible.'
    },
    {
	  icon: <UserRoundCheck className='w-20 h-20 mb-2 text-orange-400' />,
      title: 'Liderazgo',
      description: 'Desarrolla habilidades de liderazgo con propósito y conviértete en un referente dentro de tu comunidad.'
    },
    {
      icon: <Award className='w-20 h-20 mb-2 text-orange-400' />,
      title: 'Reconocimiento',
      description: 'Forma parte de una red influyente y recibe visibilidad como agente de cambio.'
    },
    {
	  icon: <ChartLine className='w-20 h-20 mb-2 text-orange-400' />,
      title: 'Crecimiento',
      description: 'Accede a mentorías, talleres exclusivos y oportunidades únicas para tu desarrollo personal y profesional.'
    }
  ];

  const exclusiveBenefits = [
    {
        title: "Reconocimiento de Marca",
        description: "Tu marca, presente en eventos, materiales promocionales, publicaciones digitales, ganando visibilidad ante una audiencia interesada en innovación y emprendimiento."
    },
    {
        title: "Networking Estratégico",
        description: "Conecta con emprendedores, desarrolladores y otros patrocinadores, creando oportunidades de colaboración y crecimiento empresarial."
    },
    {
        title: "Promoción en Redes Sociales",
        description: "Recibe menciones y publicaciones destacadas en nuestras plataformas sociales, posicionando tu marca como un aliado de la innovación."
    },
    {
        title: "Participación Activa",
        description: "Involúcrate directamente en paneles, talleres o actividades dentro de los eventos, mostrando tu experiencia y valores como organización."
    },
    {
        title: "Reporte de Impacto",
        description: "Recibe un informe detallado sobre el impacto generado por tu participación como patrocinador, destacando métricas clave y resultados del ecosistema."
    },
    {
        title: "Alianzas Estratégicas",
        description: "Forma parte de un ecosistema colaborativo que facilita la creación de alianzas con instituciones educativas, startups y empresas consolidadas."
    }
  ];

  const stories = [
    {
      quote: "Una de las razones por las que el interés en emprendimiento en Hermosillo se ha mantenido vigente es gracias al esfuerzo constante de Firepit… Innovando en las formas de hacer comunidad.",
      author: "Alejandro Varela",
      role: "CEO, Guevara Propiedad Intelectual — Desde 2021"
    }
  ];

  return (
    <div className="min-h-screen bg-zinc-900">
	<Helmet>
	  <title>Firepit — Conviertete en un embajador de la comunidad</title>
	  <meta name="description" content="Los embajadores del cambio son líderes apasionados que conectan personas, recursos e ideas para transformar comunidades. ¿Estás listo para dar el siguiente paso?"/>
	  
	  {/* OpenGraph tags */}
	  <meta property="og:title" content="Firepit — Conviertete en un embajador de la comunidad" />
	  <meta property="og:description" content="Los embajadores del cambio son líderes apasionados que conectan personas, recursos e ideas para transformar comunidades. ¿Estás listo para dar el siguiente paso?" />
	  <meta property="og:type" content="website" />
	  <meta property="og:image" content="https://res.firep1t.com/static/embajadores.png" />
	  <meta property="og:url" content="https://firep1t.com/embajadores" />
	  
	  {/* Additional meta tags */}
	  <meta property="og:site_name" content="Firepit" />
	  <meta name="twitter:card" content="summary_large_image" />
	  <meta name="twitter:site" content="@firep1t_" />
	</Helmet>

      {/* Hero Section */}

      <section className="relative h-[600px] mt-20 flex items-center bg-[linear-gradient(rgba(15,15,15,0.3),rgba(0,0,0,0.7),rgba(24,24,27,1)),url('https://res.firep1t.com/static/embajadores.png')] bg-center bg-cover">
        <div className="absolute inset-0" />
        <div className="relative z-10 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h2 className="mb-4 text-sm font-bold tracking-widest text-orange-400 uppercase">Únete al grupo exclusivo de Embajadores</h2>
            <h1 className="mb-6 text-4xl font-bold md:text-6xl">
				Conviértete en el puente entre ideas y la acción.
            </h1>
			<p className="mt-6 text-lg leading-8 text-zinc-300">
				Los embajadores del cambio son líderes apasionados que conectan personas, recursos e ideas para transformar comunidades. ¿Estás listo para dar el siguiente paso?
			</p>
          </div>
        </div>
      </section>

      {/* Why become an ambassador section */}
      <div className="pt-24 pb-16 bg-zinc-900">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight text-orange-400 sm:text-4xl">
              ¿Que significa ser embajador de Firepit?
            </h2>
            <p className="mt-6 text-lg leading-8 text-zinc-300">
              Ser embajador es una oportunidad de liderar, inspirar y marcar una diferencia real.
            </p>
          </div>
          <div className="grid max-w-5xl grid-cols-1 mx-auto mt-16 gap-x-8 gap-y-12 sm:grid-cols-2">
            {benefits.map((benefit) => (
              <div key={benefit.title} className="flex gap-4 p-6 rounded-lg bg-zinc-700/50">
				{benefit.icon}
                <div className='flex flex-col gap'>
					<h3 className="text-xl font-semibold text-white">{benefit.title}</h3>
					<p className="mt-3 text-zinc-300">{benefit.description}</p>
				</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="h-[2px] max-w-5xl mx-auto bg-zinc-700"></div>
		
      {/* Impact Stories */}
      <div className="py-24 bg-zinc-900">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto text-center">

		  	<h2 className="mb-4 text-sm font-bold tracking-widest text-orange-400 uppercase">Historias de Impacto</h2>
            <h1 className="mb-6 text-4xl font-bold md:text-6xl">
				Es el testimonio de nuestros  {" "}
				<span className="border-b-4 border-orange-500">Embajadores</span>.
            </h1>
          </div>
          <div className="grid max-w-5xl grid-cols-1 gap-8 mx-auto mt-16 sm:grid-cols-2">
            {stories.map((story) => (
              <div key={story.author} className="relative p-6 rounded-lg bg-zinc-800">
                <div className="relative">
                  <blockquote className="text-lg text-zinc-300">
                    "{story.quote}"
                  </blockquote>
                  <footer className="mt-4">
                    <p className="text-base font-semibold text-white">{story.author}</p>
                    <p className="text-sm text-orange-400">{story.role}</p>
                  </footer>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Exclusive Benefits */}
      <div className="py-24 bg-zinc-800">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              ¿Qué obtienes al ser Embajador?
            </h2>
            <p className="mt-6 text-lg leading-8 text-zinc-300">
              Unirte como embajador significa acceder a una experiencia única diseñada para potenciar tu impacto.
            </p>
          </div>
          <div className="grid max-w-5xl grid-cols-1 mx-auto mt-16 gap-x-8 gap-y-12 sm:grid-cols-2">
            {exclusiveBenefits.map((benefit) => (
              <div key={benefit.title} className="p-6 transition-all duration-300 rounded-lg bg-zinc-700/50 ring-1 ring-orange-400/20 hover:ring-4 hover:bg-orange-200/10 hover:ring-orange-500">
                <h3 className="text-xl font-semibold text-white">{benefit.title}</h3>
                <p className="mt-3 text-zinc-300">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Application Form */}
      <div id="postulate" className="py-24 bg-zinc-900">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Da el primer paso hacia el cambio
            </h2>
            <p className="mt-6 text-lg leading-8 text-zinc-300">
              Forma parte de este movimiento transformador y lleva tus ideas a un nuevo nivel.
            </p>
		    <a href='https://calendar.app.google/WtuoLQyJ5A2d3jZE7' target="_blank" className="inline-flex items-center px-6 py-3 mx-auto mt-6 text-lg font-semibold text-white transition-colors bg-orange-500 rounded-md hover:bg-orange-600" rel="noreferrer">
				<SquareArrowOutUpRight className="w-4 h-4 mr-2" />
				Agenda una reunión
		    </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Embajadores; 