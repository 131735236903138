import React, { useState, useEffect } from "react";
import { Search, Plus, Edit2, Trash2, CheckCircle, Ticket } from "lucide-react";
import Modal from "../../common/Modal";
import {
  getCommunityEvents,
  createCommunityEvent,
  updateCommunityEvent,
  deleteCommunityEvent,
} from "../../../services/api";
import { useCommunity } from "../../../contexts/CommunityContext";
import EventForm from "./EventForm";
import TicketModal from "./TicketModal";

const EventList = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { activeCommunity } = useCommunity();

  useEffect(() => {
    if (activeCommunity?.id) {
      fetchEvents();
    }
  }, [activeCommunity]);

  const fetchEvents = async () => {
    try {
      const response = await getCommunityEvents(activeCommunity.id);
      setEvents(response || []);
      setError(null);
    } catch (err) {
      setError("Failed to load events");
      console.error("Error fetching events:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModals = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
    setShowTicketModal(false);
    setSelectedEvent(null);
  };

  const handleEdit = (event) => {
    setSelectedEvent(event);
    setShowEditModal(true);
  };

  const handleManageTickets = (event) => {
    setSelectedEvent(event);
    setShowTicketModal(true);
  };

  const handleSubmit = async (formData, isEdit = false) => {
    try {
      let response;
      if (isEdit && selectedEvent?.id) {
        response = await updateCommunityEvent(
          activeCommunity.id,
          selectedEvent.id,
          formData
        );
      } else {
        response = await createCommunityEvent(activeCommunity.id, formData);
      }

      const newEvent = response.data || response;

      if (isEdit) {
        setEvents(
          events.map((event) => (event.id === newEvent.id ? newEvent : event))
        );
      } else {
        setEvents([...events, newEvent]);
      }

      handleCloseModals();
    } catch (err) {
      console.error("Error saving event:", err);
      setError(err.message || "Failed to save event");
      throw err;
    }
  };

  const handleDelete = async (event) => {
    try {
      await deleteCommunityEvent(activeCommunity.id, event.id);
      setEvents(events.filter((e) => e.id !== event.id));
      handleCloseModals();
    } catch (err) {
      console.error("Error deleting event:", err);
      setError(err.message || "Failed to delete event");
    }
  };

  const handlePublish = async (event) => {
    try {
      const response = await updateCommunityEvent(
        activeCommunity.id,
        event.id,
        {
          status: "published",
        }
      );

      const updatedEvent = response.data || response;
      const eventWithRelations = {
        ...updatedEvent,
        venue: updatedEvent.venue || event.venue,
        program: updatedEvent.program || event.program,
      };

      setEvents(
        events.map((e) =>
          e.id === eventWithRelations.id ? eventWithRelations : e
        )
      );
      setError(null);
    } catch (err) {
      console.error("Error publishing event:", err);
      setError("Failed to publish event");
    }
  };

  const filteredEvents = events.filter(
    (event) =>
      event.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center py-8">
        <div className="w-8 h-8 border-b-2 border-orange-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-6">
      {/* Header with Search and Create Button */}
      <div className="flex items-center justify-between">
        <div className="relative flex-1 max-w-md">
          <Search className="absolute w-5 h-5 text-zinc-400 -translate-y-1/2 pointer-events-none left-4 top-1/2" />
          <input
            type="text"
            style={{ paddingLeft: "44px" }}
            className="w-full px-4 text-white placeholder-zinc-400 bg-zinc-900 border border-zinc-700 rounded-lg h-11 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
            placeholder="Buscar eventos..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <button
          className="flex items-center px-4 py-2 space-x-2 text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600"
          onClick={() => setShowCreateModal(true)}
        >
          <Plus className="w-5 h-5" />
          <span>Crear Evento</span>
        </button>
      </div>

      {/* Events List */}
      <div className="rounded-lg bg-zinc-900/50">
        {error && (
          <div className="p-4 mb-6 text-red-500 border border-red-500 rounded-lg bg-red-500/10">
            {error}
          </div>
        )}

        {filteredEvents.length === 0 ? (
          <div className="py-8 text-center text-zinc-400">
            {searchTerm
              ? "No events found matching your search."
              : "No events found. Create one to get started!"}
          </div>
        ) : (
          <div className="divide-y divide-zinc-800">
            {filteredEvents.map((event) => (
              <div
                key={event.id}
                className="flex items-start justify-between p-6 transition-colors hover:bg-zinc-800/50"
              >
                <div className="flex-1">
                  <div className="flex items-center justify-between mb-2">
                    <h3 className="text-lg font-semibold text-white">{event.name}</h3>
                    <div className="flex space-x-2">
                      {event.status === "draft" && (
                        <button
                          className="p-2 text-zinc-400 transition-colors rounded-lg hover:text-green-500 hover:bg-zinc-700"
                          onClick={() => handlePublish(event)}
                          title="Publish event"
                        >
                          <CheckCircle className="w-4 h-4" />
                        </button>
                      )}
                      <button
                        className="p-2 text-zinc-400 transition-colors rounded-lg hover:text-white hover:bg-zinc-700"
                        onClick={() => handleEdit(event)}
                        title="Edit event"
                      >
                        <Edit2 className="w-4 h-4" />
                      </button>
                      <button
                        className="p-2 text-zinc-400 transition-colors rounded-lg hover:text-red-500 hover:bg-zinc-700"
                        onClick={() => {
                          setSelectedEvent(event);
                          setShowDeleteModal(true);
                        }}
                        title="Delete event"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                      <button
                        className="p-2 text-zinc-400 transition-colors rounded-lg hover:text-blue-500 hover:bg-zinc-700"
                        onClick={() => handleManageTickets(event)}
                        title="Manage tickets"
                      >
                        <Ticket className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                  <p className="text-sm text-zinc-400">
                    {event.description}
                  </p>
                  <div className="flex flex-col mt-2 space-y-1 text-sm text-zinc-400">
                    <div className="flex items-center gap-2">
                      <span className="text-orange-400">{event.program?.name}</span>
                      <span>•</span>
                      <span>
                        {event.venue?.name}
                        {event.venue?.address && ` - ${event.venue.address}`}
                        {event.venue?.city && `, ${event.venue.city}`}
                      </span>
                    </div>
                    <div className="flex items-center gap-4">
                      <span>
                        {new Date(event.start_date).toLocaleDateString()} at{" "}
                        {new Date(event.start_date).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>
                      <span
                        className={`px-2 py-1 text-xs font-semibold rounded-full ${
                          event.status === "published"
                            ? "bg-green-500/20 text-green-400"
                            : event.status === "draft"
                            ? "bg-yellow-500/20 text-yellow-400"
                            : event.status === "cancelled"
                            ? "bg-red-500/20 text-red-400"
                            : "bg-zinc-500/20 text-zinc-400"
                        }`}
                      >
                        {event.status.charAt(0).toUpperCase() + event.status.slice(1)}
                      </span>
                      <span>Max Capacity: {event.max_capacity}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Modals */}
      <Modal
        isOpen={showCreateModal}
        onClose={handleCloseModals}
        title="Crear Evento"
        size="medium"
      >
        <EventForm
          onSubmit={(data) => handleSubmit(data, false)}
          onCancel={handleCloseModals}
        />
      </Modal>

      <Modal
        isOpen={showEditModal}
        onClose={handleCloseModals}
        title="Editar Evento"
        size="medium"
      >
        <EventForm
          event={selectedEvent}
          onSubmit={(data) => handleSubmit(data, true)}
          onCancel={handleCloseModals}
        />
      </Modal>

      <Modal
        isOpen={showDeleteModal}
        onClose={handleCloseModals}
        title="Delete Event"
        size="small"
      >
        <div className="p-6">
          <p className="mb-6">
            Are you sure you want to delete "{selectedEvent?.name}"?
          </p>
          <div className="flex justify-end space-x-3">
            <button
              className="px-4 py-2 text-zinc-400 transition-colors hover:text-white"
              onClick={handleCloseModals}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 text-white transition-colors bg-red-500 rounded-lg hover:bg-red-600"
              onClick={() => handleDelete(selectedEvent)}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>

      <TicketModal
        event={selectedEvent}
        isOpen={showTicketModal}
        onClose={handleCloseModals}
      />
    </div>
  );
};

export default EventList;
