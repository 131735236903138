import React from 'react';
import Modal from '../common/Modal';
import ProgramForm from './ProgramForm';

const CreateProgramModal = ({ isOpen, onClose, onSubmit }) => {
  const initialData = {
    name: '',
    short_description: '',
    description: '',
    logo_url: '',
    photo_url: '',
    is_active: true
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Create Program"
      size="medium"
    >
      <ProgramForm
        initialData={initialData}
        onSubmit={(formData) => onSubmit(formData)}
        onCancel={onClose}
        submitLabel="Create"
      />
    </Modal>
  );
};

export default CreateProgramModal; 