import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Dashboard from "./components/Dashboard";
import AuthCallback from "./components/auth/AuthCallback";
import PrivateRoute from "./components/auth/PrivateRoute";
import UserMenu from "./components/user/UserMenu";
import Index from "./components/pages/Index";
import About from "./components/pages/About";
import Modal from "./components/common/Modal";
import { useAuth } from "./contexts/AuthContext";
import { LogIn as LoginIcon, Menu as MenuIcon } from "lucide-react";
import Logo from "./components/common/Logo";
import Footer from "./components/common/Footer";
import AdminDashboard from "./components/admin/Dashboard";
import AdminCommunities from "./components/admin/Communities";
import AdminPrograms from "./components/admin/Programs";
import AdminSettings from "./components/admin/Settings";
import CommunityAdminDashboard from "./components/communities/CommunityAdminDashboard";
import ProgramsList from "./components/programs/ProgramsList";
import ProgramDetail from "./components/programs/ProgramDetail";
import CommunityPublic from "./components/pages/CommunityPublic";
import Embajadores from "./components/pages/Embajadores";
import Mentores from "./components/pages/Mentores";
import Organizadores from "./components/pages/Organizadores";
import { Helmet } from 'react-helmet-async';

const TitleComponent = () => {
  const location = useLocation();

  useEffect(() => {
    // Google Tag Manager
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-FX9N78WTDG`;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-FX9N78WTDG', {
      page_path: location.pathname + location.search
    });

    return () => {
      document.head.removeChild(script);
    };
  }, [location]);

  const getMetaInfo = () => {
    const defaultDescription = "Firepit: Plataforma líder que conecta comunidades de innovación y emprendimiento tecnológico. Participa en eventos, programas especializados y crece en una comunidad vibrante.";
    const defaultImage = "https://firep1t.com/og-image.jpg";

    switch (location.pathname) {
      case "/":
        return {
          title: "Firepit - Creando emprendimiento a través de la comunidad.",
          description: defaultDescription,
          type: "website",
          image: defaultImage
        };
      case "/about":
        return {
          title: "Firepit - Set the World on Fire",
          description: "Descubre cómo Firepit impulsa la innovación y el emprendimiento a través de comunidades activas, programas especializados y eventos de alto impacto.",
          type: "website",
          image: defaultImage
        };
      case "/programas":
        return {
          title: "Programas Firepit - Verticales de Impacto en Innovación",
          description: "Explora nuestros programas divididos en tres verticales de impacto: comunidad, ideación y especialización. Únete a eventos y comunidades especializadas.",
          type: "website",
          image: defaultImage
        };
      case "/dashboard":
        return {
          title: "Dashboard | Firepit",
          description: "Accede a tu panel personalizado de Firepit - Gestiona tus eventos, programas y conexiones.",
          type: "website",
          image: defaultImage
        };
      case "/login":
        return {
          title: "Iniciar Sesión | Firepit",
          description: "Inicia sesión en Firepit y accede a tu comunidad de innovación y emprendimiento.",
          type: "website",
          image: defaultImage
        };
      case "/register":
        return {
          title: "Registro | Firepit",
          description: "Únete a Firepit - Creando emprendimiento a través de la comunidad.",
          type: "website",
          image: defaultImage
        };
      default:
        return {
          title: "FirePit - Creando emprendimiento a través de la comunidad.",
          description: defaultDescription,
          type: "website",
          image: defaultImage
        };
    }
  };

  const getGlobalJsonLd = () => {
    return {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "Firepit",
      "alternateName": "El Ciclo del Fuego",
      "url": "https://firep1t.com",
      "logo": "https://firep1t.com/logo.png",
      "sameAs": [
        "https://facebook.com/firep1t",
        "https://twitter.com/firep1t_",
        "https://instagram.com/firep1t",
        "https://linkedin.com/company/firep1t"
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "customer support",
        "email": "global@firep1t.com",
        "availableLanguage": ["Spanish", "English"]
      },
      "description": "Plataforma líder que conecta comunidades de innovación y emprendimiento tecnológico. Participa en eventos, programas especializados y crece en una comunidad vibrante.",
      "address": {
        "@type": "PostalAddress",
        "addressCountry": "MX",
        "addressLocality": "Hermosillo",
        "addressRegion": "Sonora"
      }
    };
  };

  const metaInfo = getMetaInfo();

  return (
    <Helmet>
      <title>{metaInfo.title}</title>
      <meta name="description" content={metaInfo.description} />
      
      {/* OpenGraph Meta Tags */}
      <meta property="og:title" content={metaInfo.title} />
      <meta property="og:description" content={metaInfo.description} />
      <meta property="og:type" content={metaInfo.type} />
      <meta property="og:image" content={metaInfo.image} />
      <meta property="og:url" content={`https://firep1t.com${location.pathname}`} />
      <meta property="og:site_name" content="Firepit" />
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@firep1t_" />
      <meta name="twitter:creator" content="@firep1t_" />
      <meta name="twitter:title" content={metaInfo.title} />
      <meta name="twitter:description" content={metaInfo.description} />
      <meta name="twitter:image" content={metaInfo.image} />
      
      {/* Additional Meta Tags */}
      <meta name="application-name" content="Firepit" />
      <meta name="apple-mobile-web-app-title" content="Firepit" />
      <meta name="theme-color" content="#000000" />
      <link rel="canonical" href={`https://firep1t.com${location.pathname}`} />
      
      {/* Locale Meta Tags */}
      <meta property="og:locale" content="es_MX" />
      <meta name="language" content="Spanish" />

      {/* Global JSON-LD structured data */}
      <script type="application/ld+json">
        {JSON.stringify(getGlobalJsonLd())}
      </script>

      {/* Favicon and App Icons */}
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#000000" />
    </Helmet>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const { isAuthenticated } = useAuth();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);
  const [showRegister, setShowRegister] = useState(false);
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      const maxScrollHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      setMaxScroll(maxScrollHeight);
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleRegisterSuccess = () => {
    setShowRegister(false);
    navigate("/dashboard");
  };

  const scrollPercentage = (scrollPosition / maxScroll) * 100;
  const headerClasses = `fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
    scrollPercentage > 70
      ? "bg-black py-2"
      : "bg-black/90 backdrop-blur-lg py-2"
  }`;

  return (
    <div className="w-full min-h-screen text-white bg-black">
      <ScrollToTop />
      <TitleComponent />

      {/* Header */}
      <header className={headerClasses + " border-b border-zinc-800"}>
        <div className="px-4 mx-auto md:max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            {/* Mobile menu button */}
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="p-2 text-zinc-400 md:hidden hover:text-white"
            >
              <MenuIcon className="w-6 h-6" />
            </button>

            {/* Desktop Navigation */}
            <nav className="hidden space-x-6 md:flex">
              <Link to="/nosotros" className="text-zinc-400 hover:text-white">
                Nosotros
              </Link>
              <Link to="/programas" className="text-zinc-400 hover:text-white">
                Programas
              </Link>
              <Link
                to="/embajadores"
                className="text-zinc-400 hover:text-white"
              >
                Embajadores
              </Link>
            </nav>
            <Link
              to="/"
              className="absolute font-bold text-orange-500 transform -translate-x-1/2 left-1/2"
            >
              <Logo />
            </Link>
            <div className="px-4 py-2">
              {isAuthenticated ? (
                <UserMenu />
              ) : (
                <div className="flex items-center space-x-4">
                  <Link
                    to="/login"
                    className="font-medium text-white transition-colors duration-200 hover:text-primary"
                  >
                    <LoginIcon className="w-6 h-6" />
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        <div className={`md:hidden ${isMobileMenuOpen ? "block" : "hidden"}`}>
          <div className="px-2 pt-2 pb-3 space-y-1 border-t border-zinc-800">
            <Link
              to="/nosotros"
              className="block px-3 py-2 text-zinc-400 hover:text-white"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Nosotros
            </Link>
            <Link
              to="/programas"
              className="block px-3 py-2 text-zinc-400 hover:text-white"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Programas
            </Link>
            <Link
              to="/embajadores"
              className="block px-3 py-2 text-zinc-400 hover:text-white"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Embajadores
            </Link>
          </div>
        </div>
      </header>

      <main>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/login" element={<Login />} />
          <Route path="/nosotros" element={<About />} />
          <Route path="/register" element={<Register />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/programas" element={<ProgramsList />} />
          <Route path="/programas/:slug" element={<ProgramDetail />} />
          <Route path="/embajadores" element={<Embajadores />} />
          <Route path="/mentores" element={<Mentores />} />
          <Route path="/organizadores" element={<Organizadores />} />
          <Route path="/:slug" element={<CommunityPublic />} />
          <Route path="/:slug/:eventSlug" element={<CommunityPublic />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <PrivateRoute requireAdmin={true}>
                <AdminDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/communities"
            element={
              <PrivateRoute requireAdmin={true}>
                <AdminCommunities />
              </PrivateRoute>
            }
          />

          <Route
            path="/admin/programs"
            element={
              <PrivateRoute requireAdmin={true}>
                <AdminPrograms />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/settings"
            element={
              <PrivateRoute requireAdmin={true}>
                <AdminSettings />
              </PrivateRoute>
            }
          />
          <Route
            path="/communities/:id/admin"
            element={
              <PrivateRoute>
                <CommunityAdminDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/communities/:id/admin/settings"
            element={
              <PrivateRoute>
                <CommunityAdminDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/communities/:communityId"
            element={<CommunityPublic />}
          />
        </Routes>
      </main>

      <Modal
        isOpen={showRegister}
        onClose={() => setShowRegister(false)}
        title="Registro"
      >
        <Register
          isModal={true}
          onSuccess={handleRegisterSuccess}
          onLoginClick={() => {
            setShowRegister(false);
            navigate("/login");
          }}
        />
      </Modal>
      <Footer></Footer>
    </div>
  );
}

export default App;
