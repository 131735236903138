import React, { useState, useEffect } from "react";
import { createVenue, getVenues, getPrograms } from "../../../services/api";
import { useCommunity } from "../../../contexts/CommunityContext";
import Modal from "../../common/Modal";
import VenueForm from "./VenueForm";
import { ChevronLeft, ChevronRight, Plus } from "lucide-react";
import "../CommunityForm.css";
import "./EventForm.css";

const EventForm = ({ event, onSubmit, onCancel }) => {
  const { activeCommunity } = useCommunity();
  const [currentStep, setCurrentStep] = useState(1);

  const extractTimeFromDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const [formData, setFormData] = useState({
    title: event?.name || "",
    description: event?.description || "",
    start_date: event?.start_date
      ? new Date(event.start_date).toISOString().split("T")[0]
      : "",
    start_time: event?.start_date
      ? extractTimeFromDate(event.start_date)
      : "",
    end_date: event?.end_date
      ? new Date(event.end_date).toISOString().split("T")[0]
      : "",
    end_time: event?.end_date
      ? extractTimeFromDate(event.end_date)
      : "",
    venue_id: event?.venue_id || "",
    program_id: event?.program_id || "",
    max_attendees: event?.max_capacity || "",
    registration_deadline: event?.registration_deadline
      ? new Date(event.registration_deadline).toISOString().split("T")[0]
      : "",
    registration_deadline_time: event?.registration_deadline
      ? extractTimeFromDate(event.registration_deadline)
      : "",
    status: event?.status || "draft",
    is_public: event?.is_private === false,
  });

  const [venues, setVenues] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [showVenueModal, setShowVenueModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    if (event) {
      setFormData({
        title: event.name || "",
        description: event.description || "",
        start_date: event.start_date
          ? new Date(event.start_date).toISOString().split("T")[0]
          : "",
        start_time: event.start_date
          ? extractTimeFromDate(event.start_date)
          : "",
        end_date: event.end_date
          ? new Date(event.end_date).toISOString().split("T")[0]
          : "",
        end_time: event.end_date
          ? extractTimeFromDate(event.end_date)
          : "",
        venue_id: event.venue_id || "",
        program_id: event.program_id || "",
        max_attendees: event.max_capacity || "",
        registration_deadline: event.registration_deadline
          ? new Date(event.registration_deadline).toISOString().split("T")[0]
          : "",
        registration_deadline_time: event.registration_deadline
          ? extractTimeFromDate(event.registration_deadline)
          : "",
        status: event.status || "draft",
        is_public: event.is_private === false,
      });
    }
  }, [event]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (activeCommunity?.id) {
          const [venuesResponse, programsResponse] = await Promise.all([
            getVenues(activeCommunity.id),
            getPrograms()
          ]);
          
          setVenues(Array.isArray(venuesResponse) ? venuesResponse : (venuesResponse.data || []));
          const programsData = Array.isArray(programsResponse) ? programsResponse : programsResponse.data;
          setPrograms(programsData ? programsData.filter(program => program.is_active) : []);
        }
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Error al cargar los datos requeridos');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [activeCommunity]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const handleCreateVenue = async (venueData) => {
    try {
      if (!activeCommunity?.id) {
        throw new Error('No se encontró una comunidad activa');
      }

      const response = await createVenue(activeCommunity.id, venueData);
      const newVenue = response.data || response;
      
      if (newVenue && newVenue.id) {
        setVenues(prev => [...prev, newVenue]);
        setFormData(prev => ({ ...prev, venue_id: newVenue.id }));
        setShowVenueModal(false);
        setError('');
      } else {
        throw new Error('Datos de lugar no válidos recibidos');
      }
    } catch (err) {
      console.error('Error al crear el lugar:', err);
      setError(err.message || 'Error al crear el lugar');
      throw err;
    }
  };

  const validateStep = (step, isSubmitting = false) => {
    const newErrors = {};

    // Only validate if we're submitting or it's not an edit
    const shouldValidate = isSubmitting || !event;

    if (step === 1) {
      if (shouldValidate && !formData.title.trim()) {
        newErrors.title = "El título es obligatorio";
      }
      if (shouldValidate && !formData.description.trim()) {
        newErrors.description = "La descripción es obligatoria";
      }
    }

    if (step === 2) {
      if (shouldValidate) {
        if (!formData.start_date) newErrors.start_date = "La fecha de inicio es obligatoria";
        if (!formData.start_time) newErrors.start_time = "La hora de inicio es obligatoria";
        if (!formData.end_date) newErrors.end_date = "La fecha de finalización es obligatoria";
        if (!formData.end_time) newErrors.end_time = "La hora de finalización es obligatoria";
        if (!formData.venue_id) newErrors.venue_id = "El lugar es obligatorio";
        if (!formData.program_id) newErrors.program_id = "El programa es obligatorio";

        if (formData.start_date && formData.start_time && formData.end_date && formData.end_time) {
          const startDateTime = new Date(`${formData.start_date}T${formData.start_time}`);
          const endDateTime = new Date(`${formData.end_date}T${formData.end_time}`);
          
          if (!isNaN(startDateTime.getTime()) && !isNaN(endDateTime.getTime())) {
            if (endDateTime < startDateTime) {
              newErrors.end_date = "La fecha/hora de finalización debe ser después de la fecha/hora de inicio";
            }
          }
        }
      }
    }

    if (step === 3) {
      if (shouldValidate) {
        if (!formData.max_attendees) {
          newErrors.max_attendees = "El número máximo de asistentes es obligatorio";
        } else if (isNaN(formData.max_attendees) || parseInt(formData.max_attendees) <= 0) {
          newErrors.max_attendees = "El número máximo de asistentes debe ser un número positivo";
        }
        if (!formData.registration_deadline) {
          newErrors.registration_deadline = "La fecha límite de registro es obligatoria";
        }
        if (!formData.registration_deadline_time) {
          newErrors.registration_deadline_time = "La hora de la fecha límite de registro es obligatoria";
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep(currentStep)) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep(prev => prev - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep(currentStep, true)) return;

    try {
      setIsSubmitting(true);
      setError('');

      if (!activeCommunity?.id) {
        throw new Error('No se encontró una comunidad activa');
      }

      const submitData = {
        ...formData,
        max_participants: formData.max_attendees
      };

      await onSubmit(submitData);
    } catch (err) {
      console.error('Error al enviar el formulario:', err);
      setError(err.message || 'Error al guardar el evento');
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-6">
        <div className="w-8 h-8 border-b-2 border-orange-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="form-group">
              <label htmlFor="title">Título *</label>
              <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Ingresa el título del evento"
                className={errors.title ? "error" : ""}
                disabled={isSubmitting}
                autoComplete="off"
              />
              {errors.title && <span className="error-message">{errors.title}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="description">Descripción *</label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Ingresa la descripción del evento"
                className={errors.description ? "error" : ""}
                disabled={isSubmitting}
                rows="4"
                autoComplete="off"
              />
              {errors.description && <span className="error-message">{errors.description}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="status">Estado</label>
              <select
                id="status"
                name="status"
                value={formData.status}
                onChange={handleChange}
                disabled={isSubmitting}
                className={errors.status ? "error" : ""}
              >
                <option value="draft">Borrador</option>
                <option value="published">Publicado</option>
                <option value="registration_open">Registro Abierto</option>
                <option value="cancelled">Cancelado</option>
                <option value="finished">Finalizado</option>
              </select>
              {errors.status && <span className="error-message">{errors.status}</span>}
              <div className="mt-2 text-sm text-zinc-400">
                {formData.status === "draft" && (
                  "El evento no es visible para los usuarios regulares. Solo los administradores pueden verlo."
                )}
                {formData.status === "published" && (
                  "El evento es visible, pero el registro aún no está abierto."
                )}
                {formData.status === "registration_open" && (
                  "El evento es visible y los usuarios pueden registrarse hasta la fecha límite de registro."
                )}
                {formData.status === "cancelled" && (
                  "El evento está cancelado y solo es visible para los administradores."
                )}
                {formData.status === "finished" && (
                  "El evento ha concluido."
                )}
              </div>
            </div>
          </>
        );

      case 2:
        return (
          <>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="start_date">Fecha de Inicio *</label>
                <input
                  type="date"
                  id="start_date"
                  name="start_date"
                  value={formData.start_date}
                  onChange={handleChange}
                  className={errors.start_date ? "error" : ""}
                  disabled={isSubmitting}
                  autoComplete="off"
                />
                {errors.start_date && <span className="error-message">{errors.start_date}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="start_time">Hora de Inicio *</label>
                <input
                  type="time"
                  id="start_time"
                  name="start_time"
                  value={formData.start_time}
                  onChange={handleChange}
                  className={errors.start_time ? "error" : ""}
                  disabled={isSubmitting}
                  autoComplete="off"
                />
                {errors.start_time && <span className="error-message">{errors.start_time}</span>}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="end_date">Fecha de Finalización *</label>
                <input
                  type="date"
                  id="end_date"
                  name="end_date"
                  value={formData.end_date}
                  onChange={handleChange}
                  className={errors.end_date ? "error" : ""}
                  disabled={isSubmitting}
                  autoComplete="off"
                />
                {errors.end_date && <span className="error-message">{errors.end_date}</span>}
              </div>

              <div className="form-group">
                <label htmlFor="end_time">Hora de Finalización *</label>
                <input
                  type="time"
                  id="end_time"
                  name="end_time"
                  value={formData.end_time}
                  onChange={handleChange}
                  className={errors.end_time ? "error" : ""}
                  disabled={isSubmitting}
                  autoComplete="off"
                />
                {errors.end_time && <span className="error-message">{errors.end_time}</span>}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="venue_id">Sede *</label>
              <div className="select-with-button">
                <select
                  id="venue_id"
                  name="venue_id"
                  value={formData.venue_id}
                  onChange={handleChange}
                  className={errors.venue_id ? "error" : ""}
                  disabled={isSubmitting}
                >
                  <option value="">Selecciona una sede</option>
                  {venues.map(venue => (
                    <option key={venue.id} value={venue.id}>
                      {venue.name}
                    </option>
                  ))}
                </select>
                <button
                  type="button"
                  className="flex items-center px-4 py-2 font-bold text-zinc-200 transition-colors bg-zinc-800 rounded-lg hover:text-white disabled:opacity-50"
                  onClick={() => setShowVenueModal(true)}
                  disabled={isSubmitting}
                >
                  <Plus className="w-4 h-4 mr-2"></Plus> Nueva Sede
                </button>
              </div>
              {errors.venue_id && <span className="error-message">{errors.venue_id}</span>}
            </div>

            <div className="form-group">
              <label htmlFor="program_id">Programa *</label>
              <select
                id="program_id"
                name="program_id"
                value={formData.program_id}
                onChange={handleChange}
                className={errors.program_id ? "error" : ""}
                disabled={isSubmitting}
              >
                <option value="">Selecciona un programa</option>
                {programs.map(program => (
                  <option key={program.id} value={program.id}>
                    {program.name}
                  </option>
                ))}
              </select>
              {errors.program_id && <span className="error-message">{errors.program_id}</span>}
            </div>
          </>
        );

      case 3:
        return (
          <>
            <div className="form-group">
              <label htmlFor="max_attendees">Máximo de Asistentes *</label>
              <input
                type="number"
                id="max_attendees"
                name="max_attendees"
                value={formData.max_attendees}
                onChange={handleChange}
                placeholder="Enter maximum number of attendees"
                className={errors.max_attendees ? "error" : ""}
                disabled={isSubmitting}
                min="1"
                autoComplete="off"
              />
              {errors.max_attendees && <span className="error-message">{errors.max_attendees}</span>}
            </div>

			<label htmlFor="registration_deadline" className="pb-2 border-b border-zinc-200">Límite de Registro </label>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="registration_deadline">Fecha *</label>
                <input
                  type="date"
                  id="registration_deadline"
                  name="registration_deadline"
                  value={formData.registration_deadline}
                  onChange={handleChange}
                  className={errors.registration_deadline ? "error" : ""}
                  disabled={isSubmitting}
                  autoComplete="off"
                />
                {errors.registration_deadline && (
                  <span className="error-message">{errors.registration_deadline}</span>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="registration_deadline_time">Hora *</label>
                <input
                  type="time"
                  id="registration_deadline_time"
                  name="registration_deadline_time"
                  value={formData.registration_deadline_time}
                  onChange={handleChange}
                  className={errors.registration_deadline_time ? "error" : ""}
                  disabled={isSubmitting}
                  autoComplete="off"
                />
                {errors.registration_deadline_time && (
                  <span className="error-message">{errors.registration_deadline_time}</span>
                )}
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className="event-form">
      {error && <div className="mb-4 error-message">{error}</div>}
      
      {/* Progress Steps */}
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center w-full">
          {[
            { step: 1, label: 'Información' },
            { step: 2, label: 'Tiempo y Sede' },
            { step: 3, label: 'Registros' }
          ].map(({ step, label }) => (
            <div
              key={step}
              className={`flex items-center ${step !== 3 ? 'flex-1' : ''}`}
            >
              <div className="flex flex-col items-center">
                <div
                  className={`w-10 h-10 rounded-full flex items-center justify-center text-sm font-medium border-2 transition-colors
                    ${currentStep === step
                      ? 'border-orange-500 text-orange-500'
                      : currentStep > step
                      ? 'border-green-500 bg-green-500 text-white'
                      : 'border-zinc-600 text-zinc-500'
                    }`}
                >
                  {currentStep > step ? '✓' : step}
                </div>
                <span className={`mt-2 w-28 text-sm text-center font-medium
                  ${currentStep === step
                    ? 'text-orange-500'
                    : currentStep > step
                    ? 'text-green-500'
                    : 'text-zinc-500'
                  }`}
                >
                  {label}
                </span>
              </div>
              {step !== 3 && (
                <div
                  className={`h-0.5 w-full mx-4 transition-colors ${
                    currentStep > step ? 'bg-green-500' : 'bg-zinc-700'
                  }`}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <form onSubmit={(e) => {
        // Only allow form submission on the final step
        if (currentStep !== 3) {
          e.preventDefault();
          return;
        }
        handleSubmit(e);
      }} autoComplete="off">
        {renderStepContent()}

        <div className="flex justify-between mt-6">
          <button
            type="button"
            onClick={handleBack}
            disabled={currentStep === 1 || isSubmitting}
            className="flex items-center px-4 py-2 text-zinc-200 transition-colors bg-zinc-800 rounded-lg hover:text-white disabled:opacity-50"
          >
            <ChevronLeft className="w-4 h-4 mr-2" />
            Volver
          </button>
          
          <div className="flex space-x-3">
            <button
              type="button"
              onClick={onCancel}
              disabled={isSubmitting}
              className="px-4 py-2 text-zinc-800 transition-colors rounded-lg hover:bg-zinc-200 disabled:opacity-50"
            >
              Cancelar
            </button>
            
            {currentStep < 3 ? (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleNext();
                }}
                disabled={isSubmitting}
                className="flex items-center px-4 py-2 text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600 disabled:opacity-50"
              >
                Continuar
                <ChevronRight className="w-4 h-4 ml-2" />
              </button>
            ) : (
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600 disabled:opacity-50"
              >
                {isSubmitting
                  ? "Guardando..."
                  : event
                  ? "Actualizar Evento"
                  : "Crear Evento"}
              </button>
            )}
          </div>
        </div>
      </form>

      <Modal
        isOpen={showVenueModal}
        onClose={() => setShowVenueModal(false)}
        title="Create New Venue"
        size="medium"
      >
        <VenueForm
          onSubmit={handleCreateVenue}
          onCancel={() => setShowVenueModal(false)}
        />
      </Modal>
    </div>
  );
};

export default EventForm;
