import React, { useState, useEffect } from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { dracula } from '@uiw/codemirror-theme-dracula';
import DOMPurify from 'dompurify';
import Modal from '../common/Modal';

const HtmlEditorModal = ({ isOpen, onClose, initialValue, onSave }) => {
  const [htmlContent, setHtmlContent] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  // Reset content when modal opens with new initialValue
  useEffect(() => {
    if (isOpen) {
      setHtmlContent(initialValue || '');
      setIsDirty(false);
      setIsClosing(false);
    }
  }, [isOpen, initialValue]);

  const handleChange = (value) => {
    setHtmlContent(value);
    setIsDirty(true);
  };

  const handleApply = () => {
    onSave(htmlContent);
    setIsDirty(false);
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 300); // Match animation duration
  };

  const handleCancel = () => {
    setHtmlContent(initialValue || '');
    setIsDirty(false);
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 300); // Match animation duration
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCancel}
      title="Editor HTML"
      size="xl"
      footer={
        <div className="flex justify-end space-x-3">
          <button
            type="button"
            onClick={handleCancel}
            className="px-4 py-2 text-zinc-700 transition-colors hover:text-zinc-900"
          >
            Cancelar
          </button>
          <button
            type="button"
            onClick={handleApply}
            disabled={!isDirty}
            className="px-4 py-2 text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:opacity-50"
          >
            Aplicar Cambios
          </button>
        </div>
      }
    >
      <div className="grid h-[70vh] grid-cols-2 gap-4">
        {/* Editor */}
        <div className="overflow-auto rounded-lg">
          <CodeMirror
            value={htmlContent}
            height="100%"
            theme={dracula}
            extensions={[html()]}
            onChange={handleChange}
            className="h-full text-base"
          />
        </div>

        {/* Preview */}
        <div className="p-4 overflow-auto bg-zinc-50 rounded-lg">
          <div
            className="prose max-w-none"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(htmlContent)
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default HtmlEditorModal;