import React, { useState } from 'react';
import { Settings, Users, AlertTriangle, Power, Edit2Icon } from 'lucide-react';
import EventList from './communityadmin/EventList';
import VenueList from './communityadmin/VenueList';
import CommunityForm from './communityadmin/CommunityForm';
import Modal from '../common/Modal';
import { useCommunity } from '../../contexts/CommunityContext';
import { updateCommunity } from '../../services/api';
import { useNavigate } from 'react-router-dom';

const CommunityAdminDashboard = () => {
  const [activeTab, setActiveTab] = useState('events');
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  
  const { activeCommunity, setCurrentCommunity } = useCommunity();
  const navigate = useNavigate();

  const tabs = [
    { id: 'events', label: 'Eventos' },
    { id: 'venues', label: 'Sedes' },
  ];

  const handleUpdateCommunity = async (formData) => {
    try {
      setIsSubmitting(true);
      setError(null);
      const response = await updateCommunity(activeCommunity.id, formData);
      const updatedCommunity = response.data || response;
      
      // Preserve relations by merging the updated data with existing relations
      const communityWithRelations = {
        ...updatedCommunity,
        programs: activeCommunity.programs,
        venues: activeCommunity.venues,
        events: activeCommunity.events,
        members: activeCommunity.members,
      };
      
      // Update active community context since we're editing the active community
      setCurrentCommunity(communityWithRelations);
      
      setShowSettingsModal(false);
    } catch (err) {
      console.error('Error updating community:', err);
      setError(err.message || 'Failed to update community');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDisableCommunity = async () => {
    try {
      setIsSubmitting(true);
      setError(null);
      const response = await updateCommunity(activeCommunity.id, {
        ...activeCommunity,
        is_active: false
      });
      const updatedCommunity = response.data || response;
      
      // Preserve relations for the disabled community as well
      const communityWithRelations = {
        ...updatedCommunity,
        programs: activeCommunity.programs,
        venues: activeCommunity.venues,
        events: activeCommunity.events,
        members: activeCommunity.members,
      };
      
      setCurrentCommunity(communityWithRelations);
      navigate('/communities');
    } catch (err) {
      console.error('Error disabling community:', err);
      setError(err.message || 'Failed to disable community');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container px-4 py-8 mx-auto">
      {/* Community Header */}
      <div className="flex items-center justify-between mt-20 mb-8">
        <div>
          <div className="flex items-center gap-4">
            <h1 className="text-3xl font-bold text-white">{activeCommunity?.name}</h1>
            <button
              className="flex items-center px-3 py-1 space-x-2 text-red-500 transition-colors rounded-lg bg-red-500/10 hover:bg-red-500/20"
              onClick={() => setShowDisableModal(true)}
              title="Desactivar Comunidad"
            >
              <Power className="w-4 h-4" />
            </button>
          </div>
          <p className="mt-1 text-sm text-zinc-400">{activeCommunity?.description}</p>
        </div>
        <div className="flex space-x-3">
          <button
            className="flex items-center px-4 py-2 space-x-2 text-zinc-400 transition-colors bg-zinc-800 rounded-lg hover:text-white"
            onClick={() => {/* TODO: Add members management */}}
          >
            <Users className="w-4 h-4" />
            <span>Miembros</span>
          </button>
          <button
            className="flex items-center px-4 py-2 space-x-2 text-zinc-400 transition-colors bg-zinc-800 rounded-lg hover:text-white"
            onClick={() => setShowSettingsModal(true)}
          >
            <Edit2Icon className="w-4 h-4" />
            <span>Editar</span>
          </button>
        </div>
      </div>

      {/* Tabs */}
      <div className="flex mb-6 space-x-4 border-b border-zinc-700">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`px-4 py-2 -mb-px text-sm font-medium transition-colors border-b-2 ${
              activeTab === tab.id
                ? 'text-orange-500 border-orange-500'
                : 'text-zinc-400 border-transparent hover:text-zinc-300'
            }`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Content */}
      <div className="mt-6">
        {activeTab === 'events' && <EventList />}
        {activeTab === 'venues' && <VenueList />}
      </div>

      {/* Settings Modal */}
      <Modal
        isOpen={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
        title="Configuración de Comunidad"
        size="medium"
      >
        <div className="p-6">
          {error && (
            <div className="p-4 mb-6 text-red-500 border border-red-500 rounded-lg bg-red-500/10">
              {error}
            </div>
          )}
          <CommunityForm
            community={activeCommunity}
            onSubmit={handleUpdateCommunity}
            onCancel={() => setShowSettingsModal(false)}
          />
        </div>
      </Modal>

      {/* Disable Confirmation Modal */}
      <Modal
        isOpen={showDisableModal}
        onClose={() => setShowDisableModal(false)}
        title="Desactivar Comunidad"
        size="small"
      >
        <div className="p-6">
          <div className="flex items-center p-4 mb-6 space-x-3 text-red-500 border border-red-500 rounded-lg bg-red-500/10">
            <AlertTriangle className="flex-shrink-0 w-5 h-5" />
            <p>Esto ocultará la comunidad y evitará cualquier nueva actividad. Los miembros no podrán acceder a eventos o sedes hasta que la vuelves a activar.</p>
          </div>
          <p className="mb-6">
            ¿Estás seguro de que deseas desactivar "{activeCommunity?.name}"?
          </p>
          <div className="flex justify-end space-x-3">
            <button
              className="px-4 py-2 text-zinc-400 transition-colors hover:text-white"
              onClick={() => setShowDisableModal(false)}
              disabled={isSubmitting}
            >
              Cancelar
            </button>
            <button
              className="px-4 py-2 text-white transition-colors bg-red-500 rounded-lg hover:bg-red-600 disabled:opacity-50"
              onClick={handleDisableCommunity}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Desactivando..." : "Desactivar Comunidad"}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CommunityAdminDashboard; 