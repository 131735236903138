import React from "react";
import { Link } from "react-router-dom";
import Newsletter from "../common/Newsletter";
import { Helmet } from "react-helmet-async";

export default function About() {
  return (
    <div className="indexPage">
	  <Helmet>
		<title>Firepit - Conoce más sobre nosotros</title>
		<meta name="description" content="En Firepit estamos creando emprendimiento a través de la comunidad, conectando a emprendedores, mentores, embajadores y organizadores."/>
		
		{/* OpenGraph tags */}
		<meta property="og:title" content="Firepit - Conoce más sobre nosotros" />
		<meta property="og:description" content="En Firepit estamos creando emprendimiento a través de la comunidad, conectando a emprendedores, mentores, embajadores y organizadores." />
		<meta property="og:type" content="website" />
		<meta property="og:image" content="https://res.firep1t.com/static/organizers.png" />
		<meta property="og:url" content="https://firep1t.com/nosotros" />
		
		{/* Additional meta tags */}
		<meta property="og:site_name" content="Firepit" />
		<meta name="twitter:card" content="summary_large_image" />
		<meta name="twitter:site" content="@firep1t_" />
	  </Helmet>
	
      {/* Hero Section */}
      <section className="relative h-[600px] mt-20 flex items-center bg-[linear-gradient(rgba(15,15,15,0.3),rgba(0,0,0,0.7),rgba(24,24,27,1)),url('https://res.firep1t.com/static/organizers.png')] bg-center bg-cover">
        <div className="absolute inset-0" />
        <div className="relative z-10 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h2 className="mb-4 text-sm font-bold tracking-widest uppercase">Set the world on fire</h2>
            <h1 className="mb-6 text-4xl font-bold md:text-6xl">
              Creando emprendimiento a través {" "}
              <span className="border-b-4 border-orange-500">de la comunidad</span>.
            </h1>
          </div>
        </div>
      </section>

      {/* Newsletter Section */}
      <Newsletter />
    </div>
  );
} 