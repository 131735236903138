import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import api from "../../services/api";
import UpcomingEvents from "./UpcomingEvents";
import DOMPurify from 'dompurify';

const ProgramDetail = () => {
  const { slug } = useParams();
  const [program, setProgram] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProgram = async () => {
      try {
        const response = await api.get(`/programs/${slug}`);
        setProgram(response);
        setLoading(false);
      } catch (err) {
        setError("Error al cargar el programa");
        setLoading(false);
      }
    };

    fetchProgram();
  }, [slug]);

  const getJsonLd = () => {
    return {
      "@context": "https://schema.org",
      "@type": "Course",
      "name": program.name,
      "description": program.short_description,
      "provider": {
        "@type": "Organization",
        "name": "Firepit",
        "url": "https://firep1t.com"
      },
      "courseCode": program.slug,
      "image": program.photo_url || program.logo_url || 'https://firep1t.com/default-program.jpg',
      "url": `https://firep1t.com/programas/${program.slug}`,
      "inLanguage": "es",
      "educationalLevel": program.type?.name || "Personal and Professional Development",
      "teaches": program.short_description,
      "hasCourseInstance": program.events?.map(event => ({
        "@type": "CourseInstance",
        "name": event.name,
        "description": event.description,
        "startDate": event.start_date,
        "endDate": event.end_date || event.start_date,
        "location": event.venue ? {
          "@type": "Place",
          "name": event.venue.name,
          "address": {
            "@type": "PostalAddress",
            "addressLocality": event.venue.city,
            "addressRegion": event.venue.state,
            "addressCountry": event.venue.country
          }
        } : undefined
      })) || [],
      "offers": {
        "@type": "Offer",
        "category": "Professional Development Program",
        "availability": program.is_active ? "https://schema.org/InStock" : "https://schema.org/SoldOut"
      }
    };
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-16 h-16 border-4 border-orange-500 rounded-full border-t-transparent animate-spin"></div>
      </div>
    );
  }

  if (error || !program) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-red-500">{error || "Programa no encontrado"}</p>
      </div>
    );
  }

  return (
    <div className="w-full mt-20">
      <Helmet>
        <title>{program.name} - Programas - Firepit</title>
        <meta name="description" content={program.short_description} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={`${program.name} | Programas Firepit`} />
        <meta property="og:description" content={program.short_description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={program.photo_url || program.logo_url || 'https://firep1t.com/default-program.jpg'} />
        <meta property="og:url" content={`https://firep1t.com/programas/${program.slug}`} />
        
        {/* Additional meta tags */}
        <meta property="og:site_name" content="Firepit" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@firep1t_" />
        
        {/* Program specific meta tags */}
        {program.type && program.type.name !== 'Default' && (
          <meta property="article:section" content={program.type.name} />
        )}
        {program.start_date && (
          <meta property="article:published_time" content={new Date(program.start_date).toISOString()} />
        )}

        {/* JSON-LD structured data */}
        <script type="application/ld+json">
          {JSON.stringify(getJsonLd())}
        </script>
      </Helmet>

      <div className="relative h-64 mb-8 overflow-hidden rounded-lg md:h-96">
        {program.photo_url ? (
          <div className="relative w-full h-full">
            <img
              src={program.photo_url}
              alt={program.name}
              className="absolute object-cover w-full h-full"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/60 to-black" />
          </div>
        ) : (
          <div className="flex items-center justify-center w-full h-full bg-zinc-800">
            <span className="text-zinc-500">---</span>
          </div>
        )}
        {program.logo_url && (
          <div className="absolute inset-0 flex items-end justify-center mb-20">
            <img 
              src={program.logo_url} 
              alt={`${program.name} logo`}
              className="w-[275px]"
            />
          </div>
        )}
      </div>
      <div className="container px-4 pb-8 mx-auto">
        <div className="max-w-4xl mx-auto">
          <h1 className="mb-2 text-4xl font-bold text-white">{program.name}</h1>
          {program.type && program.type.name !== 'Default' && (
            <div className="mb-6 text-sm font-medium text-orange-500">
              {program.type.name}
            </div>
          )}

          <div className="p-6 mb-8 rounded-lg bg-zinc-900">
            <h2 className="mb-4 text-2xl font-semibold text-white">
              Descripción
            </h2>
            <div 
              className="text-zinc-300"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(program.description) }}
            />
          </div>

          {program.requirements && (
            <div className="p-6 mb-8 rounded-lg bg-zinc-900">
              <h2 className="mb-4 text-2xl font-semibold text-white">
                Requisitos
              </h2>
              <p className="whitespace-pre-wrap text-zinc-300">
                {program.requirements}
              </p>
            </div>
          )}

          {program.benefits && (
            <div className="p-6 mb-8 rounded-lg bg-zinc-900">
              <h2 className="mb-4 text-2xl font-semibold text-white">
                Beneficios
              </h2>
              <p className="whitespace-pre-wrap text-zinc-300">
                {program.benefits}
              </p>
            </div>
          )}

          {program.start_date && (
            <div className="p-6 mb-8 rounded-lg bg-zinc-900">
              <h2 className="mb-4 text-2xl font-semibold text-white">Fechas</h2>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div>
                  <p className="text-zinc-400">Fecha de inicio</p>
                  <p className="text-white">
                    {new Date(program.start_date).toLocaleDateString()}
                  </p>
                </div>
                {program.end_date && (
                  <div>
                    <p className="text-zinc-400">Fecha de finalización</p>
                    <p className="text-white">
                      {new Date(program.end_date).toLocaleDateString()}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="py-6">
            <UpcomingEvents programId={program.id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramDetail;
