import axios from 'axios';

// Create axios instance with custom config
const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8000/api',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    },
    withCredentials: true // This is important for handling cookies/session
});

// Function to get CSRF token from cookie
const getCSRFToken = () => {
    const name = 'XSRF-TOKEN=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    
    for (let cookie of cookieArray) {
        cookie = cookie.trim();
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return null;
};

// Add a request interceptor
api.interceptors.request.use(
    async (config) => {
        // Get CSRF cookie before making request if we don't have it
        if (!getCSRFToken()) {
            const baseURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';
            await axios.get(`${baseURL}/sanctum/csrf-cookie`, {
                withCredentials: true
            });
        }
        
        // Set the CSRF token in the header
        const token = getCSRFToken();
        if (token) {
            config.headers['X-XSRF-TOKEN'] = decodeURIComponent(token);
        }

        // Add Authorization header if token exists
        const authToken = localStorage.getItem('token');
        if (authToken) {
            config.headers['Authorization'] = `Bearer ${authToken}`;
        }
        
        return config;
    },
    (error) => {
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => {
        // Return the data directly
        return response.data;
    },
    (error) => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('Response error:', error.response.data);
            return Promise.reject(error.response.data);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Network error:', error.request);
            return Promise.reject(new Error('Network error - no response received'));
        } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error:', error.message);
            return Promise.reject(error);
        }
    }
);

// Test function to verify API connection
api.testConnection = async () => {
    try {
        const response = await api.get('/test');
        return { success: true, data: response };
    } catch (error) {
        return { success: false, error: error.message };
    }
};

// Community endpoints
export const getCommunities = () => api.get('/communities');
export const getMyCommunities = () => api.get('/my-communities');
export const getActiveCommunity = () => api.get('/active-community');
export const setActiveCommunity = (communityId) => api.post(`/communities/${communityId}/set-active`);
export const createCommunity = (data) => api.post('/communities', data);
export const updateCommunity = (id, data) => api.put(`/communities/${id}`, data);
export const deleteCommunity = (id) => api.delete(`/communities/${id}`);
export const joinCommunity = (id) => api.post(`/communities/${id}/join`);
export const leaveCommunity = (id) => api.post(`/communities/${id}/leave`);

// Program endpoints
export const getPrograms = () => api.get('/programs');
export const getProgram = (slug) => api.get(`/programs/${slug}`);
export const createProgram = (data) => api.post('/programs', data);
export const updateProgram = (id, data) => api.put(`/programs/${id}`, data);
export const deleteProgram = (id) => api.delete(`/programs/${id}`);

// Event endpoints
export const getCommunityEvents = (communityId) => api.get(`/communities/${communityId}/events`);
export const getCommunityEvent = (communityId, eventId) => api.get(`/communities/${communityId}/events/${eventId}`);
export const createCommunityEvent = (communityId, data) => api.post(`/communities/${communityId}/events`, data);
export const updateCommunityEvent = (communityId, eventId, data) => api.put(`/communities/${communityId}/events/${eventId}`, data);
export const deleteCommunityEvent = (communityId, eventId) => api.delete(`/communities/${communityId}/events/${eventId}`);
export const getCommunityStats = (communityId) => api.get(`/communities/${communityId}/stats`);

// Venue endpoints
export const getVenues = (communityId) => api.get(`/communities/${communityId}/venues`);
export const createVenue = (communityId, data) => api.post(`/communities/${communityId}/venues`, data);
export const updateVenue = (communityId, venueId, data) => api.put(`/communities/${communityId}/venues/${venueId}`, data);
export const deleteVenue = (communityId, venueId) => api.delete(`/communities/${communityId}/venues/${venueId}`);

// Event attendees
export const getEventAttendees = (communityId, eventId) => api.get(`/communities/${communityId}/events/${eventId}/attendees`);
export const updateEventStatus = (communityId, eventId, status) => api.patch(`/communities/${communityId}/events/${eventId}/status`, { status });

export default api; 