import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Send, Flag, Zap, Link as LinkIcon } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import CommunityUpcomingEvents from '../communities/CommunityUpcomingEvents';
import api from '../../services/api';
import Newsletter from "../common/Newsletter";
import { Helmet } from "react-helmet-async";

export default function Index() {
  const { user } = useAuth();
  const [communities, setCommunities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCommunities = async () => {
      try {
        const response = await api.get('/communities');
        setCommunities(response.data);
      } catch (error) {
        console.error('Error fetching communities:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCommunities();
  }, []);

  return (
    <div className="indexPage">
	  <Helmet>
        <title>Firepit - Transforma tus ideas en realidad.</title>
        <meta name="description" content="La plataforma líder que conecta comunidades de innovación y emprendimiento tecnológico. Participa en eventos, programas especializados y crece en una comunidad vibrante."/>
        
        {/* OpenGraph tags */}
        <meta property="og:title" content="Firepit - Transforma tus ideas en realidad." />
        <meta property="og:description" content="La plataforma líder que conecta comunidades de innovación y emprendimiento tecnológico. Participa en eventos, programas especializados y crece en una comunidad vibrante." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://res.firep1t.com/static/header.png" />
        <meta property="og:url" content="https://firep1t.com/" />
        
        {/* Additional meta tags */}
        <meta property="og:site_name" content="Firepit" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@firep1t_" />
      </Helmet>

      {/* Hero Section */}
      <section className="relative h-[80vh] flex items-center">
        <div className="absolute inset-0 bg-[linear-gradient(rgba(15,15,15,0.7),rgba(0,0,0,0.4),rgba(0,0,0,1)),url('https://res.firep1t.com/static/header.png')] bg-center bg-cover" />
        <div className="relative z-10 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-3xl text-center">
            <h2 className="mt-32 mb-4 text-sm font-bold tracking-widest uppercase">Con Firepit</h2>
            <h1 className="mb-6 text-5xl font-bold md:text-7xl">
              Transforma tus<br/> ideas en {" "}
              <span className="border-b-4 border-orange-500">realidad</span>.
            </h1>
          </div>
        </div>
      </section>

      {/* Communities or Events Section */}
      <section className="py-0">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {loading ? (
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {[1, 2, 3].map((i) => (
                <div key={i} className="p-6 bg-zinc-800 animate-pulse rounded-xl">
                  <div className="h-10 mb-4 rounded bg-zinc-700"></div>
                  <div className="w-2/3 h-6 rounded bg-zinc-700"></div>
                </div>
              ))}
            </div>
          ) : communities.length === 1 ? (
            <CommunityUpcomingEvents communityId={communities[0].id} />
          ) : (
            <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
              {communities.map((community) => (
                <Link
                  key={community.id}
                  to={`/${community.slug}`}
                  className="flex flex-col bg-zinc-900 rounded-xl p-4 transition-transform hover:scale-[1.02]"
                >
                  <div className="flex items-center gap-4">
                    {community.image_url ? (
                      <img
                        src={community.image_url}
                        alt={community.name}
                        className="object-cover w-8 h-8 rounded-full"
                      />
                    ) : (
                      <div className="flex items-center justify-center w-8 h-8 font-medium text-white bg-orange-500 rounded-full">
                        {community.name.substring(0, 2).toUpperCase()}
                      </div>
                    )}
                    <div>
                      <h3 className="text-xl font-bold text-white">{community.name}</h3>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </section>

      {/* Involvement Section */}
      <section className="pt-16 pb-6">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h2 className="mb-12 text-3xl font-bold text-center">Involúcrate</h2>
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
            {[
              {
                icon: <Send className="w-16 h-16 mb-6 text-orange-500" />,
                title: "Embajador",
                description: "Apoya los eventos de tu ciudad, ayuda a crecer la comunidad.",
				link: "embajadores"
              },
              {
                icon: <Flag className="w-16 h-16 mb-6 text-orange-500" />,
                title: "Organizador",
                description: "Inclúyenos en el calendario de tu ciudad, construye un ecosistema.",
				link: "organizadores"
              },
              {
                icon: <Zap className="w-16 h-16 mb-6 text-orange-500" />,
                title: "Mentor",
                description: "Únete a la red global de mentores, facilita la innovación.",
				link: "mentores"
              },
              {
                icon: <LinkIcon className="w-16 h-16 mb-6 text-orange-500" />,
                title: "Asistente",
                description: "Participa en eventos y forma parte de la comunidad.",
				link: "programas"
              }
            ].map((item, index) => (
              <div key={index} className="flex flex-col items-center p-6 rounded-lg">
                {item.icon}
                <h3 className="mb-2 text-xl font-bold">{item.title}</h3>
                <p className="mb-4 text-center text-zinc-400">{item.description}</p>
                {item.link ? <Link to={`/${item.link}`} className="text-orange-500 hover:text-orange-400">
				  Ver más
                </Link> : <div className="text-orange-500/50 hover:text-orange-400/50">
                  Próximamente...
                </div>}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Newsletter Section */}
	  <Newsletter className="p-6 bg-gradient-to-b from-black to-zinc-900" />
    </div>
  );
} 