import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Helmet } from 'react-helmet-async';
import {
  MapPin,
  X,
  Calendar,
  Clock,
  Users,
  Edit2,
  Plus,
  Mail,
  Check,
  Ticket,
} from "lucide-react";
import { Link } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { useCommunity } from "../../contexts/CommunityContext";
import Modal from "../common/Modal";
import EventForm from "../communities/communityadmin/EventForm";
import api from "../../services/api";

const EventModal = ({ event: initialEvent, onClose, onEventUpdate }) => {
  const [isClosing, setIsClosing] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [event, setEvent] = useState(initialEvent);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const { user } = useAuth();
  const { activeCommunity } = useCommunity();

  const canEditEvent = useMemo(() => {
    return (
      user?.role === "admin" ||
      (activeCommunity?.id === event?.community_id &&
        activeCommunity?.pivot?.role === "admin")
    );
  }, [
    user?.role,
    activeCommunity?.id,
    activeCommunity?.pivot?.role,
    event?.community_id,
  ]);

  const getEventStatusInfo = () => {
    const now = new Date();
    const startDate = new Date(event.start_date);
    const registrationDeadline = event.registration_deadline
      ? new Date(event.registration_deadline)
      : null;

    if (startDate < now) {
      return {
        text: "Evento finalizado",
        buttonText: "Evento pasado",
        showButton: false,
        statusClass: "text-zinc-500",
      };
    }

    if (isRegistered) {
      return {
        text: "Registrado",
        buttonText: "Invitar a alguien",
        showButton: true,
        statusClass: "text-green-500",
        isRegistered: true,
      };
    }

    if (
      event.status === "registration_open" &&
      registrationDeadline &&
      now <= registrationDeadline &&
      tickets.length > 0
    ) {
      return {
        text: "Registro abierto",
        buttonText: "Obtén tu entrada",
        showButton: true,
        statusClass: "text-green-500",
      };
    }

    if (event.status === "registration_open" && tickets.length === 0) {
      return {
        text: "Entradas agotadas",
        buttonText: "Entradas agotadas",
        showButton: false,
        statusClass: "text-yellow-500",
      };
    }

    if (event.status === "published") {
      return {
        text: "Próximamente",
        buttonText: "Próximamente",
        showButton: false,
        statusClass: "text-blue-500",
      };
    }

    if (event.status === "cancelled") {
      return {
        text: "Evento cancelado",
        buttonText: "Cancelado",
        showButton: false,
        statusClass: "text-red-500",
      };
    }

    return {
      text: "Registro cerrado",
      buttonText: "Registro cerrado",
      showButton: false,
      statusClass: "text-zinc-500",
    };
  };

  const statusInfo = getEventStatusInfo();

  const handleRegistrationClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (statusInfo.isRegistered) {
        setShowInviteModal(true);
      } else {
        setShowRegistrationModal(true);
      }
    },
    [statusInfo?.isRegistered]
  );

  const [registrationForm, setRegistrationForm] = useState({
    name: user?.name || "",
    email: user?.email || "",
    phone: user?.phone || "",
    password: "",
    confirmPassword: "",
  });

  const [inviteForm, setInviteForm] = useState({
    invites: [],
  });

  useEffect(() => {
    const loadEventData = async () => {
      setEvent(initialEvent);
      if (initialEvent?.id && user?.id) {
        await checkRegistrationStatus(initialEvent.id);
        await fetchTickets(initialEvent.id);
      }
    };
    loadEventData();
  }, [initialEvent?.id, user?.id]);

  const checkRegistrationStatus = async (eventId) => {
    if (!user) {
      setIsRegistered(false);
      return;
    }
    try {
      const response = await api.get(`/events/${eventId}/registration-status`);
      const isRegisteredValue = response?.is_registered || false;
      setIsRegistered(isRegisteredValue);
    } catch (err) {
      console.error("Error checking registration status:", err);
      setIsRegistered(false);
    }
  };

  const fetchTickets = async (eventId) => {
    try {
      const response = await api.get(`/events/${eventId}/tickets`);
      // Filter out unavailable tickets
      const availableTickets = (response || []).filter(ticket => {
        const now = new Date();
        const saleStart = new Date(ticket.sale_starts_at);
        const saleEnd = new Date(ticket.sale_ends_at);
        return (
          ticket.is_active &&
          now >= saleStart &&
          now <= saleEnd &&
          ticket.quantity_available > ticket.quantity_sold
        );
      });
      setTickets(availableTickets);
    } catch (err) {
      console.error("Error fetching tickets:", err);
    }
  };

  const handleRegistrationSubmit = async (e) => {
    e.preventDefault();

    if (tickets.length > 1 && !selectedTicket) {
      setError("Por favor selecciona un ticket");
      return;
    }

    // Use the first available ticket if there's only one
    const ticketToUse = tickets.length === 1 ? tickets[0] : selectedTicket;
    if (!ticketToUse) {
      setError("No hay tickets disponibles");
      return;
    }

    setLoading(true);
    setError("");

    try {
      let userId = user?.id;

      // If user is not logged in, create an account first
      if (!userId) {
        if (registrationForm.password !== registrationForm.confirmPassword) {
          setError("Las contraseñas no coinciden");
          return;
        }

        const signupResponse = await api.post("/auth/signup", {
          name: registrationForm.name,
          email: registrationForm.email,
          phone: registrationForm.phone,
          password: registrationForm.password,
        });
        userId = signupResponse.data.user.id;
      }

      // Always use registerWithTicket endpoint
      await api.post(`/events/${event.id}/tickets/${ticketToUse.id}/register`, {
        name: registrationForm.name,
        email: registrationForm.email,
        phone: registrationForm.phone,
      });

      setIsRegistered(true);
      setShowRegistrationModal(false);
      setShowInviteModal(true);
    } catch (err) {
      console.error("Registration error:", err);
      setError(err.response?.data?.message || "Error al registrarse");
    } finally {
      setLoading(false);
    }
  };

  const handleInviteSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      await Promise.all(
        inviteForm.invites.map((invite) =>
          api.post(`/events/${event.id}/invitations`, {
            email: invite.email,
            name: invite.name,
          })
        )
      );
      setShowInviteModal(false);
    } catch (err) {
      setError(err.response?.data?.message || "Error al enviar invitaciones");
    } finally {
      setLoading(false);
    }
  };

  const handleAddInvite = () => {
    if (inviteForm.invites.length >= 4) return;
    setInviteForm((prev) => ({
      ...prev,
      invites: [...prev.invites, { name: "", email: "" }],
    }));
  };

  const handleRemoveInvite = (index) => {
    setInviteForm((prev) => ({
      ...prev,
      invites: prev.invites.filter((_, i) => i !== index),
    }));
  };

  const handleInviteChange = (index, field, value) => {
    setInviteForm((prev) => ({
      ...prev,
      invites: prev.invites.map((invite, i) =>
        i === index ? { ...invite, [field]: value } : invite
      ),
    }));
  };

  const handleClose = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
    }, 300);
  }, [onClose]);

  const handleEdit = (e) => {
    e.stopPropagation();
    setShowEditModal(true);
  };

  const handleUpdateEvent = async (formData) => {
    try {
      const response = await api.put(
        `/communities/${event.community_id}/events/${event.id}`,
        formData
      );
      const updatedEvent = response.data || response;
      setEvent(updatedEvent);
      if (onEventUpdate) {
        onEventUpdate(updatedEvent);
      }
      setShowEditModal(false);
    } catch (error) {
      console.error("Error updating event:", error);
      throw error;
    }
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        if (showEditModal) {
          setShowEditModal(false);
        } else if (showRegistrationModal) {
          setShowRegistrationModal(false);
        } else {
          handleClose();
        }
      }
    };

    window.addEventListener("keydown", handleEscape);
    document.body.style.overflow = "hidden";

    return () => {
      window.removeEventListener("keydown", handleEscape);
      document.body.style.overflow = "auto";
    };
  }, [handleClose, showEditModal, showRegistrationModal]);

  const getJsonLd = () => {
    return {
      "@context": "https://schema.org",
      "@type": "Event",
      "name": event.name,
      "description": event.description,
      "image": event.image_url || 'https://firep1t.com/default-event.jpg',
      "startDate": event.start_date,
      "endDate": event.end_date || event.start_date,
      "eventStatus": event.status === "published" ? "https://schema.org/EventScheduled" : "https://schema.org/EventCancelled",
      "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
      "location": event.venue ? {
        "@type": "Place",
        "name": event.venue.name,
        "address": {
          "@type": "PostalAddress",
          "streetAddress": event.venue.address,
          "addressLocality": event.venue.city,
          "addressRegion": event.venue.state,
          "postalCode": event.venue.postal_code,
          "addressCountry": event.venue.country
        }
      } : undefined,
      "organizer": {
        "@type": "Organization",
        "name": "Firepit",
        "url": "https://firep1t.com"
      },
      "offers": tickets.length > 0 ? tickets.map(ticket => ({
        "@type": "Offer",
        "name": ticket.type,
        "price": ticket.price,
        "priceCurrency": "MXN",
        "availability": ticket.quantity_available > ticket.quantity_sold ? "https://schema.org/InStock" : "https://schema.org/SoldOut",
        "validFrom": event.start_date,
        "url": `https://firep1t.com/${event.community.slug}/${event.slug}`
      })) : undefined
    };
  };

  if (!event) return null;

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString("es-MX", {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const formatTime = (date) => {
    return new Date(date)
      .toLocaleTimeString("es-MX", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .toUpperCase();
  };

  return (
    <>
      <Helmet>
        <title>{event.name} | Firepit</title>
        <meta name="description" content={event.description} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={`${event.name} | Firepit`} />
        <meta property="og:description" content={event.description} />
        <meta property="og:type" content="event" />
        <meta property="og:image" content={event.image_url || 'https://firep1t.com/default-event.jpg'} />
        <meta property="og:url" content={`https://firep1t.com/${event.community.slug}/${event.slug}`} />
        
        {/* Event specific meta tags */}
        <meta property="event:start_time" content={new Date(event.start_date).toISOString()} />
        {event.end_date && (
          <meta property="event:end_time" content={new Date(event.end_date).toISOString()} />
        )}
        <meta property="event:location" content={event.venue?.name} />

        {/* JSON-LD structured data */}
        <script type="application/ld+json">
          {JSON.stringify(getJsonLd())}
        </script>
      </Helmet>

      <div className="fixed inset-0 z-50 flex items-end justify-center">
        {/* Backdrop */}
        <div
          className={`fixed inset-0 bg-black/80 ${
            isClosing ? "animate-fade-out" : "animate-fade-in"
          }`}
          onClick={handleClose}
          style={{
            animationFillMode: "forwards",
            animationDuration: "300ms",
          }}
        />

        {/* Modal Content */}
        <div
          className={`relative w-full max-w-3xl max-h-[90vh] flex flex-col ${
            isClosing ? "animate-slide-down" : "animate-slide-up"
          }`}
          style={{
            animationFillMode: "forwards",
            animationDuration: "300ms",
            animationTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          {/* Scrollable Container */}
          <div className="flex-1 min-h-0 overflow-y-auto bg-white overscroll-contain rounded-t-2xl modal-content">
            {/* Event Image/Header */}
            <div className="relative h-[400px] overflow-hidden">
              {event.image_url ? (
                <img
                  src={event.image_url}
                  alt={event.name}
                  className="absolute inset-0 object-cover w-full h-full"
                />
              ) : (
                <div className="absolute inset-0 bg-zinc-800" />
              )}

              {/* Gradient Overlay */}
              <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black" />

              {/* Header Buttons */}
              <div className="absolute flex items-center gap-2 top-4 right-4">
                {canEditEvent && (
                  <button
                    onClick={handleEdit}
                    className="p-2 transition-colors rounded-full hover:bg-black/20"
                  >
                    <Edit2 className="w-6 h-6 text-white" />
                  </button>
                )}
                <button
                  onClick={handleClose}
                  className="p-2 transition-colors rounded-full hover:bg-black/20"
                >
                  <X className="w-6 h-6 text-white" />
                </button>
              </div>

              {/* Event Info */}
              <div className="absolute bottom-0 left-0 right-0 p-6">
                <div className="mb-2 text-sm font-medium text-orange-500">
                  {event.program?.name || "Firepit Mix"}
                </div>
                <h2 className="mb-2 text-3xl font-bold text-white">
                  {event.name}
                </h2>
              </div>
            </div>

            {/* Event Details */}
            <div className="p-6 space-y-8 overflow-y-auto">
              {/* Date & Time Section */}
              <div className="space-y-4">
                <div className="flex items-center space-x-3 text-zinc-700">
                  <Calendar className="flex-shrink-0 w-5 h-5 text-orange-500" />
                  <span className="font-medium capitalize">
                    {formatDate(event.start_date)}
                  </span>
                </div>
                <div className="flex items-center space-x-3 text-zinc-700">
                  <Clock className="flex-shrink-0 w-5 h-5 text-orange-500" />
                  <span className="font-medium">
                    {formatTime(event.start_date)}
                  </span>
                </div>
              </div>

              {/* Location Section */}
              {event.venue && (
                <div className="space-y-2">
                  <h3 className="text-xl font-bold text-zinc-900">
                    {event.venue.name}
                  </h3>
                  <div className="flex items-start space-x-3 text-zinc-600">
                    <MapPin className="flex-shrink-0 w-5 h-5 mt-0.5 text-orange-500" />
                    <div className="space-y-1">
                      <div>{event.venue.address}</div>
                      <div>
                        {event.venue.city}, {event.venue.state}{" "}
                        {event.venue.postal_code}
                      </div>
                      <div>{event.venue.country}</div>
                    </div>
                  </div>
                </div>
              )}

              {/* Capacity */}
              <div className="flex items-center space-x-3 text-zinc-700">
                <Users className="flex-shrink-0 w-5 h-5 text-orange-500" />
                <span>Capacidad máxima: {event.max_capacity} personas</span>
              </div>

              {/* Description */}
              <div className="py-4 border-t border-zinc-100">
                <h3 className="mb-4 text-lg font-semibold text-zinc-900">
                  Acerca del evento
                </h3>
                <p className="text-zinc-700 whitespace-pre-wrap">
                  {event.description}
                </p>
              </div>

              {/* Registration Deadline */}
              {event.registration_deadline && (
                <div className="py-4 text-sm border-t border-zinc-100">
                  <p className={statusInfo.statusClass + " font-medium mb-1"}>
                    {statusInfo.text}
                  </p>
                  <p className="text-zinc-500">
                    {event.status === "registration_open"
                      ? "Registro abierto hasta el "
                      : "Fecha límite de registro: "}{" "}
                    {formatDate(event.registration_deadline)} a las{" "}
                    {formatTime(event.registration_deadline)}
                  </p>
                </div>
              )}

              {/* Spacer for fixed footer */}
              <div className="h-[45px]" />
            </div>
          </div>

          {/* Fixed Footer */}
          <div className="absolute bottom-0 left-0 right-0 p-6 bg-white border-t border-zinc-100">
            <div className="flex items-center justify-between gap-4">
              <Link
                to={`/programas/${event.program?.slug}`}
                className="text-sm text-zinc-600 whitespace-nowrap hover:text-orange-500"
              >
                ¿Qué es {event.program?.name}?
              </Link>
              {statusInfo.showButton ? (
                <button
                  type="button"
                  onClick={handleRegistrationClick}
                  className="flex items-center justify-center gap-2 flex-1 px-8 py-3 text-lg font-medium text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600 max-w-[280px] cursor-pointer z-50"
                >
                  {statusInfo.isRegistered && <Check className="w-5 h-5" />}
                  {statusInfo.buttonText}
                </button>
              ) : (
                <div
                  className={`flex-1 px-8 py-3 text-lg font-medium text-center rounded-lg max-w-[280px] bg-zinc-100 ${statusInfo.statusClass}`}
                >
                  {statusInfo.buttonText}
                </div>
              )}
            </div>
          </div>
        </div>

        <style>
          {`
            @keyframes slide-up {
              from {
                transform: translateY(100%);
              }
              to {
                transform: translateY(0);
              }
            }

            @keyframes slide-down {
              from {
                transform: translateY(0);
              }
              to {
                transform: translateY(100%);
              }
            }

            @keyframes fade-in {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }

            @keyframes fade-out {
              from {
                opacity: 1;
              }
              to {
                opacity: 0;
              }
            }

            .animate-slide-up {
              animation: slide-up 300ms cubic-bezier(0.4, 0, 0.2, 1);
            }

            .animate-slide-down {
              animation: slide-down 300ms cubic-bezier(0.4, 0, 0.2, 1);
            }

            .animate-fade-in {
              animation: fade-in 300ms ease-out;
            }

            .animate-fade-out {
              animation: fade-out 300ms ease-out;
            }
          `}
        </style>
      </div>

      {/* Edit Modal */}
      <Modal
        isOpen={showEditModal}
        onClose={() => setShowEditModal(false)}
        title="Editar Evento"
        size="medium"
      >
        <EventForm
          event={event}
          onSubmit={handleUpdateEvent}
          onCancel={() => setShowEditModal(false)}
        />
      </Modal>

      {/* Registration Modal */}
      <Modal
        isOpen={showRegistrationModal}
        onClose={() => setShowRegistrationModal(false)}
        title="Registro para el evento"
        size="medium"
      >
        <form onSubmit={handleRegistrationSubmit} className="p-6">
          {error && (
            <div className="p-3 mb-4 text-sm text-red-500 border border-red-500 rounded-lg bg-red-500/10">
              {error}
            </div>
          )}

          {/* Ticket Selection */}
          {tickets.length > 1 && (
            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-zinc-700">
                Selecciona tu ticket
              </label>
              <div className="grid gap-3">
                {tickets.map((ticket) => (
                  <label
                    key={ticket.id}
                    className={`flex items-center justify-between p-3 border rounded-lg cursor-pointer transition-colors ${
                      selectedTicket?.id === ticket.id
                        ? "border-orange-500 bg-orange-50"
                        : "border-zinc-200 hover:border-orange-200"
                    }`}
                  >
                    <div className="flex items-center">
                      <input
                        type="radio"
                        name="ticket"
                        value={ticket.id}
                        checked={selectedTicket?.id === ticket.id}
                        onChange={() => setSelectedTicket(ticket)}
                        className="w-4 h-4 text-orange-500 border-zinc-300 focus:ring-orange-500"
                      />
                      <div className="ml-3">
                        <div className="font-medium text-zinc-900">{ticket.type}</div>
                        {ticket.description && (
                          <div className="text-sm text-zinc-500">
                            {ticket.description}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="text-right">
                      <div className="font-medium text-zinc-900">
                        ${parseFloat(ticket.price).toFixed(2)} MXN
                      </div>
                      <div className="text-sm text-zinc-500">
                        {ticket.quantity_available - ticket.quantity_sold} disponibles
                      </div>
                    </div>
                  </label>
                ))}
              </div>
            </div>
          )}

          {/* Personal Information */}
          <div className="grid gap-4 mb-4">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block mb-1 text-sm font-medium text-zinc-700">
                  Nombre completo
                </label>
                <input
                  type="text"
                  value={registrationForm.name}
                  onChange={(e) =>
                    setRegistrationForm((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                  className="w-full px-3 py-2 border border-zinc-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                  required
                />
              </div>
              <div>
                <label className="block mb-1 text-sm font-medium text-zinc-700">
                  Teléfono
                </label>
                <input
                  type="tel"
                  value={registrationForm.phone}
                  onChange={(e) =>
                    setRegistrationForm((prev) => ({
                      ...prev,
                      phone: e.target.value,
                    }))
                  }
                  className="w-full px-3 py-2 border border-zinc-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                />
              </div>
            </div>

            <div>
              <label className="block mb-1 text-sm font-medium text-zinc-700">
                Correo electrónico
              </label>
              <input
                type="email"
                value={registrationForm.email}
                onChange={(e) =>
                  setRegistrationForm((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }))
                }
                className="w-full px-3 py-2 border border-zinc-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                required
              />
            </div>

            {/* Password fields for non-logged in users */}
            {!user && (
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block mb-1 text-sm font-medium text-zinc-700">
                    Contraseña
                  </label>
                  <input
                    type="password"
                    value={registrationForm.password}
                    onChange={(e) =>
                      setRegistrationForm((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }
                    className="w-full px-3 py-2 border border-zinc-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                    required
                  />
                </div>
                <div>
                  <label className="block mb-1 text-sm font-medium text-zinc-700">
                    Confirmar contraseña
                  </label>
                  <input
                    type="password"
                    value={registrationForm.confirmPassword}
                    onChange={(e) =>
                      setRegistrationForm((prev) => ({
                        ...prev,
                        confirmPassword: e.target.value,
                      }))
                    }
                    className="w-full px-3 py-2 border border-zinc-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                    required
                  />
                </div>
              </div>
            )}
          </div>

          {/* Submit Button */}
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => setShowRegistrationModal(false)}
              className="px-4 py-2 text-sm text-zinc-500 transition-colors hover:text-zinc-700"
              disabled={loading}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="px-6 py-2 text-sm text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600 disabled:opacity-50"
              disabled={loading}
            >
              {loading ? "Procesando..." : "Registrarme"}
            </button>
          </div>
        </form>
      </Modal>

      {/* Invite Modal */}
      <Modal
        isOpen={showInviteModal}
        onClose={() => setShowInviteModal(false)}
        title="¡Registro exitoso!"
        size="medium"
      >
        <div className="p-6">
          {/* Success Animation and Message */}
          <div className="flex flex-col items-center justify-center mb-8 text-center">
            <div className="flex items-center justify-center w-20 h-20 mb-4 text-white bg-green-500 rounded-full animate-bounce">
              <Check className="w-10 h-10" />
            </div>
            <h3 className="mb-2 text-2xl font-bold text-zinc-900">
              ¡Estás registrado!
            </h3>
            <p className="text-lg text-zinc-600">
              {event.program?.name} - {event.name}
            </p>
          </div>

          {/* Event Details Summary */}
          <div className="p-4 mb-8 rounded-lg bg-zinc-50">
            <div className="grid gap-4">
              <div className="flex items-center gap-3">
                <Calendar className="flex-shrink-0 w-5 h-5 text-orange-500" />
                <span className="font-medium capitalize">
                  {formatDate(event.start_date)}
                </span>
              </div>
              <div className="flex items-center gap-3">
                <Clock className="flex-shrink-0 w-5 h-5 text-orange-500" />
                <span className="font-medium">
                  {formatTime(event.start_date)}
                </span>
              </div>
              <div className="flex items-center gap-3">
                <MapPin className="flex-shrink-0 w-5 h-5 text-orange-500" />
                <span className="font-medium">
                  {event.venue?.name}
                </span>
              </div>
            </div>
          </div>

          {/* Invite Section */}
          <div className="mb-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold text-zinc-900">
                ¿Quieres invitar a alguien más?
              </h3>
              {inviteForm.invites.length < 4 && (
                <button
                  type="button"
                  onClick={handleAddInvite}
                  className="flex items-center px-3 py-2 space-x-2 text-sm text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600"
                >
                  <Plus className="w-4 h-4" />
                  <span>Agregar invitado</span>
                </button>
              )}
            </div>

            {inviteForm.invites.length === 0 ? (
              <p className="text-zinc-500">
                Puedes invitar hasta 4 personas a unirse al evento.
              </p>
            ) : (
              <form onSubmit={handleInviteSubmit}>
                <div className="space-y-3">
                  {inviteForm.invites.map((invite, index) => (
                    <div
                      key={index}
                      className="flex items-start gap-2 p-3 border border-zinc-200 rounded-lg"
                    >
                      <div className="grid flex-1 grid-cols-2 gap-3">
                        <input
                          type="text"
                          placeholder="Nombre"
                          value={invite.name}
                          onChange={(e) =>
                            handleInviteChange(index, "name", e.target.value)
                          }
                          className="w-full px-3 py-2 text-sm border border-zinc-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                          required
                        />
                        <input
                          type="email"
                          placeholder="Email"
                          value={invite.email}
                          onChange={(e) =>
                            handleInviteChange(index, "email", e.target.value)
                          }
                          className="w-full px-3 py-2 text-sm border border-zinc-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                          required
                        />
                      </div>
                      <button
                        type="button"
                        onClick={() => handleRemoveInvite(index)}
                        className="p-1 text-zinc-400 transition-colors rounded-full hover:text-red-500 hover:bg-red-50"
                      >
                        <X className="w-5 h-5" />
                      </button>
                    </div>
                  ))}
                </div>

                {/* Error Message */}
                {error && (
                  <div className="p-3 mt-4 text-sm text-red-500 border border-red-500 rounded-lg bg-red-500/10">
                    {error}
                  </div>
                )}

                {/* Submit Buttons */}
                <div className="flex justify-end gap-3 mt-6">
                  <button
                    type="button"
                    onClick={() => setShowInviteModal(false)}
                    className="px-4 py-2 text-sm font-medium text-zinc-700 transition-colors hover:text-zinc-900"
                  >
                    Cerrar
                  </button>
                  {inviteForm.invites.length > 0 && (
                    <button
                      type="submit"
                      disabled={loading}
                      className="flex items-center px-6 py-2 text-sm font-medium text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600 disabled:opacity-50"
                    >
                      {loading ? (
                        "Enviando..."
                      ) : (
                        <>
                          <Mail className="w-4 h-4 mr-2" />
                          Enviar invitaciones
                        </>
                      )}
                    </button>
                  )}
                </div>
              </form>
            )}
          </div>
        </div>

        {/* Confetti Effect */}
        <style>
          {`
          @keyframes confetti-burst {
            0% {
              transform: translate(0, 0) rotate(0deg);
              opacity: 1;
            }
            100% {
              transform: translate(var(--tx), var(--ty)) rotate(var(--r));
              opacity: 0;
            }
          }
          .confetti {
            position: fixed;
            width: 8px;
            height: 8px;
            pointer-events: none;
            background: var(--color);
            left: 50%;
            top: 30%;
            animation: confetti-burst 1s ease-out forwards;
          }
          `}
        </style>
        {/* Add confetti elements */}
        {Array.from({ length: 100 }).map((_, i) => {
          const angle = (i / 100) * 360;
          const velocity = 30 + Math.random() * 20;
          const tx = Math.cos(angle * Math.PI / 180) * velocity;
          const ty = Math.sin(angle * Math.PI / 180) * velocity;
          const rotation = Math.random() * 360;
          const colors = ['#ff6b6b', '#4ecdc4', '#45b7d1', '#96ceb4', '#ffd93d'];
          const color = colors[Math.floor(Math.random() * colors.length)];
          
          return (
            <div
              key={i}
              className="confetti"
              style={{
                '--tx': `${tx}vw`,
                '--ty': `${ty}vh`,
                '--r': `${rotation}deg`,
                '--color': color,
                animationDelay: `${Math.random() * 0.2}s`,
              }}
            />
          );
        })}
      </Modal>
    </>
  );
};

export default EventModal;
