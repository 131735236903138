import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import api from '../../services/api';
import CommunityUpcomingEvents from '../communities/CommunityUpcomingEvents';

const CommunityPublic = () => {
  const { slug } = useParams();
  const [community, setCommunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCommunity = async () => {
      try {
        const response = await api.get(`/${slug}`);
        setCommunity(response);
        setLoading(false);
      } catch (err) {
        setError("Error al cargar la comunidad");
        setLoading(false);
      }
    };

    fetchCommunity();
  }, [slug]);

  const getJsonLd = () => {
    return {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": community.name,
      "description": community.description,
      "url": `https://firep1t.com/${community.slug}`,
      "logo": community.image_url || 'https://firep1t.com/default-community.jpg',
      "image": community.image_url || 'https://firep1t.com/default-community.jpg',
      "memberOf": {
        "@type": "Organization",
        "name": "Firepit",
        "url": "https://firep1t.com"
      },
      "member": {
        "@type": "OrganizationRole",
        "roleName": "member",
        "memberCount": community.member_count || 0
      },
      "event": community.events?.map(event => ({
        "@type": "Event",
        "name": event.name,
        "description": event.description,
        "startDate": event.start_date,
        "location": event.venue?.name,
        "url": `https://firep1t.com/${community.slug}/${event.slug}`
      })) || [],
      "sameAs": [
        community.facebook_url,
        community.twitter_url,
        community.linkedin_url,
        community.instagram_url
      ].filter(Boolean)
    };
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-16 h-16 border-4 border-orange-500 rounded-full border-t-transparent animate-spin"></div>
      </div>
    );
  }

  if (error || !community) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen px-4 py-12">
        <h1 className="mb-4 text-6xl font-bold text-orange-500">¡Uy!</h1>
        <h2 className="mb-8 text-2xl font-semibold text-zinc-200">
          No encontramos lo que estás buscando
        </h2>
        <p className="max-w-md mb-8 text-center text-zinc-400">
           Lo que estás buscando parece no existir o no está disponible justo ahora.
        </p>
        <a
          href="/"
          className="px-6 py-3 text-sm font-medium text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600"
        >
          Volver al inicio
        </a>
      </div>
    );
  }

  return (
    <div className="w-full mt-20">
      <Helmet>
        <title>{community.name} | Firepit</title>
        <meta name="description" content={community.description} />
        
        {/* OpenGraph tags */}
        <meta property="og:title" content={`${community.name} | Firepit`} />
        <meta property="og:description" content={community.description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={community.image_url || 'https://firep1t.com/default-community.jpg'} />
        <meta property="og:url" content={`https://firep1t.com/${community.slug}`} />
        
        {/* Additional meta tags */}
        <meta property="og:site_name" content="Firepit" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@firep1t_" />
        <meta name="twitter:creator" content="@firep1t_" />

        {/* JSON-LD structured data */}
        <script type="application/ld+json">
          {JSON.stringify(getJsonLd())}
        </script>
      </Helmet>

      {/* Hero Section with Community Image */}
      <div className="relative h-[70vh] mb-8 overflow-hidden">
        {community.image_url ? (
          <div className="relative w-full h-full">
            <img
              src={community.image_url}
              alt={community.name}
              className="absolute object-cover w-full h-full"
            />
            <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black" />
          </div>
        ) : (
          <div className="relative w-full h-full bg-zinc-900">
            <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/40 to-black" />
          </div>
        )}

        {/* Hero Content */}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="max-w-4xl px-4 mx-auto text-center">
            <div className="mb-6 text-sm font-medium tracking-wider text-orange-500 uppercase">
              {community.name}
            </div>
            <h1 className="mb-6 text-4xl font-bold tracking-tight text-white md:text-6xl">
              {community.description || 'Una comunidad apasionada por la cocina al fuego'}
            </h1>
            <button className="px-6 py-3 text-sm font-medium text-orange-500 transition-colors bg-white rounded-lg hover:bg-zinc-100">
              Unirme a la comunidad
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container px-4 pb-8 mx-auto">
        <div className="max-w-6xl mx-auto">
          {/* Events Section */}
          <div className="mb-16">
            <CommunityUpcomingEvents communityId={community.id} typeClassName="text-orange-500" />
          </div>

          {/* Stats Section */}
          <div className="grid gap-4 mb-8 md:grid-cols-3">
            <div className="p-6 py-12 text-center rounded-lg bg-zinc-950">
              <div className="mb-3 text-5xl font-bold text-orange-500">{(community.slug === 'hmo' ? '1000+' : community.member_count) || 0}</div>
              <div className="text-sm text-zinc-400">Miembros</div>
            </div>
            <div className="p-6 py-12 text-center rounded-lg bg-zinc-950">
              <div className="mb-3 text-5xl font-bold text-orange-500">{(community.slug === 'hmo' ? '50+' : community.event_count) || 0}</div>
              <div className="text-sm text-zinc-400">Eventos</div>
            </div>
            <div className="p-6 py-12 text-center rounded-lg bg-zinc-950">
              <div className="mb-3 text-5xl font-bold text-orange-500">{(community.slug === 'hmo' ? '3400+' : community.program_count) || 0}</div>
              <div className="text-sm text-zinc-400">Conexiones</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityPublic; 