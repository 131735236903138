import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useAuth } from "../../contexts/AuthContext";
import api from "../../services/api";
import SocialLogin from "./SocialLogin";
import CommunitySelector from "../communities/CommunitySelector";
import "./Register.css";

const Register = ({ onSuccess, onLoginClick, isModal = false }) => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { login } = useAuth();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    community_id: "",
  });
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "El nombre es requerido";
    if (!formData.email) newErrors.email = "El email es requerido";
    if (!formData.password) newErrors.password = "La contraseña es requerida";
    if (formData.password !== formData.password_confirmation) {
      newErrors.password_confirmation = "Las contraseñas no coinciden";
    }
    if (!selectedCommunity) {
      newErrors.community = "Debes seleccionar una comunidad";
    } else {
      formData.community_id = selectedCommunity.id;
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      setLoading(true);
      setErrors({});

      // Execute reCAPTCHA
      const token = await executeRecaptcha("register");

      // Add the reCAPTCHA token to the form data
      const registrationData = {
        ...formData,
        recaptcha_token: token,
      };

      // Register the user with the token
      const response = await api.post("/register", registrationData);

      // Login the user with the returned token and user data
      login(response.token, response.user);

      if (onSuccess) {
        onSuccess();
      }
      navigate("/dashboard");
    } catch (error) {
      console.error("Registration error:", error);
      if (error.errors) {
        setErrors(error.errors);
      } else {
        setErrors({
          general: error.message || "Error durante el registro",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const containerClass = isModal ? "modal-form" : "register-container";
  const formClass = isModal ? "" : "register-box";

  return (
    <div className={containerClass}>
      <div className={formClass}>
        {!isModal && <h2>Registro</h2>}
        {errors.general && (
          <div className="error-message">{errors.general}</div>
        )}

        <CommunitySelector
          onSelect={setSelectedCommunity}
          error={errors.community}
          selectedCommunity={selectedCommunity}
        />
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nombre</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className={errors.name ? "error" : ""}
            />
            {errors.name && (
              <span className="error-message">{errors.name}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={errors.email ? "error" : ""}
            />
            {errors.email && (
              <span className="error-message">{errors.email}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="password">Contraseña</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className={errors.password ? "error" : ""}
            />
            {errors.password && (
              <span className="error-message">{errors.password}</span>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="password_confirmation">Confirmar Contraseña</label>
            <input
              type="password"
              id="password_confirmation"
              name="password_confirmation"
              value={formData.password_confirmation}
              onChange={handleChange}
              className={errors.password_confirmation ? "error" : ""}
            />
            {errors.password_confirmation && (
              <span className="error-message">
                {errors.password_confirmation}
              </span>
            )}
          </div>

          <button
            type="submit"
            className="w-full px-4 py-2 font-bold text-white bg-orange-400 rounded hover:bg-orange-500"
            disabled={loading}
          >
            {loading ? "Registrando..." : "Registrarse"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
