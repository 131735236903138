import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const PrivateRoute = ({ children, requireAdmin = false }) => {
    const { user } = useAuth();
    const token = localStorage.getItem('token');
    
    if (!token) {
        return <Navigate to="/login" />;
    }

    if (requireAdmin && (!user || user.role !== 'admin')) {
		console.log('requireAdmin', requireAdmin, user);
        return <Navigate to="/" replace />;
    }
    
    return children;
};

export default PrivateRoute; 