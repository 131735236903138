import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import api from '../../services/api';
import './Login.css';

const SocialLogin = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            const response = await api.post('/auth/google', {
                credential: credentialResponse.credential
            });
            
            if (response.token) {
                localStorage.setItem('token', response.token);
                localStorage.setItem('user', JSON.stringify(response.user));
                navigate('/dashboard');
            }
        } catch (error) {
            console.error('Google login failed:', error);
            setError('Failed to authenticate with Google. Please try again.');
        }
    };

    const handleGoogleError = () => {
        setError('Google login failed. Please try again.');
    };

    const handleAppleLogin = async () => {
        try {
            window.location.href = `${process.env.REACT_APP_API_URL}/auth/apple`;
        } catch (error) {
            console.error('Apple login failed:', error);
            setError('Failed to initiate Apple login. Please try again.');
        }
    };

    return (
        <div className="social-login">
            {error && <div className="social-login-error">{error}</div>}
            
            <div className="google-login-wrapper">
                <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={handleGoogleError}
                    useOneTap={false}
                    theme="outline"
                    size="large"
                    width="100%"
                    text="continue_with"
                    shape="rectangular"
                />
            </div>
            
            <button 
                onClick={handleAppleLogin}
                className="hidden"
                type="button"
            >
                <svg className="apple-icon" viewBox="0 0 24 24">
                    <path d="M17.05 20.28c-.98.95-2.05.88-3.08.4-1.09-.5-2.08-.52-3.2 0-.83.38-1.71.89-3.1.38-1.47-.54-2.54-2.04-3.34-4.17-1.79-4.73-.42-11.42 3.31-11.72 1.23-.06 2.15.43 2.99.45.83.03 1.37-.28 2.99-.45 1.78-.19 3.13 1.04 3.81 2.21-3.46 2.27-2.89 7.27.59 8.67-.71 1.42-1.63 2.84-2.97 4.23zM12.9 5.88c-.14-2.56 1.94-4.74 4.38-4.88.28 2.72-2.02 4.9-4.38 4.88z"/>
                </svg>
                Continue with Apple
            </button>
        </div>
    );
};

export default SocialLogin; 