import React, { useState, useEffect, useRef } from 'react';
import { XCircle, Upload, Edit2, Code } from 'lucide-react';
import api from '../../services/api';
import HtmlEditorModal from './HtmlEditorModal';
import DOMPurify from 'dompurify';

const ImageUpload = ({ 
  label, 
  initialImage, 
  onImageChange, 
  previewSize = "w-24 h-24",
  accept = "image/*" 
}) => {
  const [preview, setPreview] = useState(initialImage);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  
  const handleFileChange = async (file) => {
    if (!file) return;
    
    // Create preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
    
    // Notify parent component
    onImageChange(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    const file = e.dataTransfer.files[0];
    if (file) handleFileChange(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const removeImage = () => {
    setPreview(null);
    onImageChange(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className="space-y-2">
      <label className="block text-sm font-medium text-zinc-700">{label}</label>
      
      <div 
        className={`relative border-2 border-dashed rounded-lg p-4 transition-colors
          ${isDragging ? 'border-orange-500 bg-orange-50' : 'border-zinc-300 hover:border-orange-400'}
          ${preview ? 'bg-zinc-50' : 'bg-white'}`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {preview ? (
          <div className="relative group">
            <img
              src={preview}
              alt="Vista previa"
              className={`${previewSize} object-cover rounded-lg mx-auto`}
            />
            <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity bg-black/50 rounded-lg">
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="p-1 text-white hover:text-orange-400 mx-1"
                title="Cambiar imagen"
              >
                <Edit2 size={20} />
              </button>
              <button
                type="button"
                onClick={removeImage}
                className="p-1 text-white hover:text-red-400 mx-1"
                title="Eliminar imagen"
              >
                <XCircle size={20} />
              </button>
            </div>
          </div>
        ) : (
          <div className="text-center p-4">
            <Upload className="mx-auto h-12 w-12 text-zinc-400" />
            <div className="mt-2">
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="text-sm font-medium text-orange-500 hover:text-orange-600"
              >
                Seleccionar archivo
              </button>
              <p className="text-xs text-zinc-500 mt-1">o arrastra y suelta</p>
            </div>
          </div>
        )}
        
        <input
          ref={fileInputRef}
          type="file"
          accept={accept}
          onChange={(e) => handleFileChange(e.target.files[0])}
          className="hidden"
        />
      </div>
    </div>
  );
};

const ProgramForm = ({ initialData, onSubmit, onCancel, submitLabel }) => {
  const [formData, setFormData] = useState(initialData || {
    name: '',
    short_description: '',
    description: '',
    is_active: true
  });
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    }
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleImageChange = async (file, type) => {
    const fieldName = type === 'logo' ? 'logo_url' : 'photo_url';
    
    if (!file) {
        // Explicitly set the URL to null when removing the image
        setFormData(prev => ({
            ...prev,
            [fieldName]: null
        }));
        return;
    }

    try {
        setUploading(true);
        setError(null);
        
        // Get pre-signed URL from your backend
        const { uploadUrl, fileUrl } = await api.post('/get-upload-url', {
            fileName: file.name,
            fileType: file.type,
            directory: 'programs'
        });

        // Upload directly to DigitalOcean Spaces
        await fetch(uploadUrl, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file.type
            }
        });

        // Update form data with the new URL
        setFormData(prev => ({
            ...prev,
            [fieldName]: fileUrl
        }));

    } catch (error) {
        console.error('Upload error:', error);
        setError('Error uploading image. Please try again.');
    } finally {
        setUploading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {error && (
        <div className="p-4 text-red-500 bg-red-100 rounded-md">
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <ImageUpload
          label="Logotipo"
          initialImage={formData.logo_url}
          onImageChange={(file) => handleImageChange(file, 'logo')}
          previewSize="w-32 h-32"
        />

        <ImageUpload
          label="Imagen de Portada"
          initialImage={formData.photo_url}
          onImageChange={(file) => handleImageChange(file, 'photo')}
          previewSize="w-full h-48"
        />
      </div>

      <div>
        <label className="block mb-1 text-sm font-medium text-zinc-700">
          Nombre
        </label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          className="w-full p-2 bg-white border border-zinc-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
          required
        />
      </div>

      <div>
        <label className="block mb-1 text-sm font-medium text-zinc-700">
          Descripción Corta
        </label>
        <input
          type="text"
          name="short_description"
          value={formData.short_description}
          onChange={handleInputChange}
          className="w-full p-2 bg-white border border-zinc-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
          required
        />
      </div>

      <div>
        <label className="block mb-1 text-sm font-medium text-zinc-700">
          Descripción Completa (HTML)
        </label>
        <div className="relative">
          <div 
            onClick={() => setIsEditorOpen(true)}
            className="w-full p-2 bg-zinc-50 border border-zinc-300 rounded-lg cursor-pointer hover:bg-zinc-100 transition-colors group flex items-center"
          >
            <div className="flex-1 truncate text-zinc-600">
              {formData.description ? (
                <div className="line-clamp-1">
                  {formData.description.replace(/<[^>]*>/g, ' ')}
                </div>
              ) : (
                <span className="text-zinc-400 italic">
                  Haz clic para editar el contenido HTML
                </span>
              )}
            </div>
            <Code 
              size={20} 
              className="text-zinc-400 group-hover:text-orange-500 ml-2"
            />
          </div>
        </div>
      </div>

      <div className="flex items-center">
        <input
          type="checkbox"
          name="is_active"
          checked={formData.is_active}
          onChange={handleInputChange}
          className="w-4 h-4 text-orange-500 border-zinc-300 rounded focus:ring-orange-500"
        />
        <label className="ml-2 text-sm font-medium text-zinc-700">
          Activo
        </label>
      </div>

      <div className="flex justify-end space-x-3">
        {onCancel && (
          <button
            type="button"
            onClick={onCancel}
            className="px-4 py-2 text-zinc-700 transition-colors hover:text-zinc-900"
          >
            Cancelar
          </button>
        )}
        <button
          type="submit"
          disabled={uploading}
          className="px-4 py-2 text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:opacity-50"
        >
          {uploading ? 'Subiendo...' : submitLabel || 'Guardar Programa'}
        </button>
      </div>

      <HtmlEditorModal
        isOpen={isEditorOpen}
        onClose={() => setIsEditorOpen(false)}
        initialValue={formData.description}
        onSave={(newContent) => {
          setFormData(prev => ({
            ...prev,
            description: newContent
          }));
        }}
      />
    </form>
  );
};

export default ProgramForm; 