import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getPrograms } from '../../services/api';

const ProgramsList = () => {
    const [programs, setPrograms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPrograms = async () => {
            try {
                const response = await getPrograms();
                // API returns array directly
                setPrograms(Array.isArray(response) ? response : []);
                setLoading(false);
            } catch (err) {
                setError('Error al cargar los programas');
                setLoading(false);
                console.error('Error fetching programs:', err);
            }
        };

        fetchPrograms();
    }, []);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="w-16 h-16 border-4 border-orange-500 rounded-full border-t-transparent animate-spin"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <p className="text-red-500">{error}</p>
            </div>
        );
    }

    if (!programs || programs.length === 0) {
        return (
            <div className="container px-4 py-8 mx-auto">
                <h1 className="mb-8 text-4xl font-bold text-white">Programas</h1>
                <p className="text-zinc-400">No hay programas disponibles en este momento.</p>
            </div>
        );
    }

    return (
        <div className="container px-4 py-8 mx-auto pt-28">
            <h1 className="mb-8 text-4xl font-bold text-white">Todo el movimiento de Firepit se basa en un ciclo anual de eventos divididos en tres verticales de impacto.
			</h1>
            
            {/* Group programs by type and render each category */}
            {Object.entries(
                programs.reduce((acc, program) => {
                    const type = program.type || 'Otros';  // Fallback category
                    return {
                        ...acc,
                        [type.name]: [...(acc[type.name] || []), program]
                    };
                }, {})
            ).map(([type, typePrograms]) => (
                <div key={type} className="mb-12">
                    {type !== 'Default' && (
                        <h2 className="mb-6 text-2xl font-semibold text-white">{type}</h2>
                    )}
                    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
                        {typePrograms.map((program) => (
                            <Link 
                                key={program.id} 
                                to={`/programas/${program.slug}`}
                                className="overflow-hidden transition-transform duration-300 bg-zinc-900 rounded-lg hover:transform hover:scale-105"
                            >
                                <div className="relative h-48">
                                    {program.photo_url ? (
                                        <div className="relative w-full h-full">
                                            <img 
                                                src={program.photo_url} 
                                                alt={program.name}
                                                className="absolute object-cover w-full h-full"
                                            />
                                            <div className="absolute inset-0 bg-gradient-to-b from-black/60 to-zinc-900" />
                                        </div>
                                    ) : (
										<div className="absolute inset-0 bg-gradient-to-b from-black/60 to-zinc-900" />
                                    )}
                                    {program.logo_url && (
                                        <div className="absolute inset-0 flex items-center justify-center">
                                            <img 
                                                src={program.logo_url} 
                                                alt={`${program.name} logo`}
                                                className="w-[175px]"
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="px-4 pb-4">
                                    <h2 className="mb-2 text-xl font-bold text-white">{program.name}</h2>
                                    <p className="text-zinc-400">{program.short_description}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProgramsList; 