import React from 'react';
import { Users, BarChart2, Layout } from 'lucide-react';
import AdminNav from './AdminNav';

const AdminDashboard = () => {
  return (
    <AdminNav>
      <h1 className="text-3xl font-bold mb-8">Admin Overview</h1>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-zinc-900 rounded-lg p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-zinc-400">Total Communities</p>
              <h3 className="text-2xl font-bold mt-1">24</h3>
            </div>
            <Users className="w-8 h-8 text-orange-500" />
          </div>
        </div>

        <div className="bg-zinc-900 rounded-lg p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-zinc-400">Active Users</p>
              <h3 className="text-2xl font-bold mt-1">1,234</h3>
            </div>
            <BarChart2 className="w-8 h-8 text-orange-500" />
          </div>
        </div>

        <div className="bg-zinc-900 rounded-lg p-6">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm text-zinc-400">Events This Month</p>
              <h3 className="text-2xl font-bold mt-1">56</h3>
            </div>
            <Layout className="w-8 h-8 text-orange-500" />
          </div>
        </div>
      </div>

      {/* Recent Activity */}
      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Recent Activity</h2>
        <div className="bg-zinc-900 rounded-lg p-6">
          <p className="text-zinc-400">Activity feed coming soon...</p>
        </div>
      </div>
    </AdminNav>
  );
};

export default AdminDashboard; 