import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './contexts/AuthContext';
import { CommunityProvider } from './contexts/CommunityContext';
import './styles/global.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
	<GoogleReCaptchaProvider
	  reCaptchaKey="YOUR_RECAPTCHA_SITE_KEY"
	  scriptProps={{
		async: true,
		defer: true,
		appendTo: 'head'
	  }}
	>
		<GoogleOAuthProvider clientId="623292231693-0pp6oo6q93jt3j3alhb8rfjmm2aq1bve.apps.googleusercontent.com">
			<HelmetProvider>
				<CommunityProvider>
					<AuthProvider>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</AuthProvider>
				</CommunityProvider>
			</HelmetProvider>
		</GoogleOAuthProvider>
	</GoogleReCaptchaProvider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

