import React, { useState } from "react";
import "../CommunityForm.css";

const CommunityForm = ({ community, onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    name: community?.name || "",
    description: community?.description || "",
    image_url: community?.image_url || "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Debes ingresar un nombre de comunidad";
    }

    if (!formData.description.trim()) {
      newErrors.description = "Debes ingresar una descripción de comunidad";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      setIsSubmitting(true);
      await onSubmit(formData);
    } catch (err) {
      console.error("Error al enviar el formulario:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="community-form">
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="form-group">
          <label htmlFor="name">Nombre *</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Ingresa el nombre de la comunidad"
            className={errors.name ? "error" : ""}
            disabled={isSubmitting}
          />
          {errors.name && (
            <span className="error-message">{errors.name}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="description">Descripción *</label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Ingresa la descripción de la comunidad"
            className={errors.description ? "error" : ""}
            disabled={isSubmitting}
            rows="4"
          />
          {errors.description && (
            <span className="error-message">{errors.description}</span>
          )}
        </div>

        <div className="form-group">
          <label htmlFor="image_url">URL de la imagen</label>
          <input
            type="url"
            id="image_url"
            name="image_url"
            value={formData.image_url}
            onChange={handleChange}
            placeholder="Ingresa la URL de la imagen"
            className={errors.image_url ? "error" : ""}
            disabled={isSubmitting}
          />
          {errors.image_url && (
            <span className="error-message">{errors.image_url}</span>
          )}
        </div>

        <div className="form-actions">
          <button
            type="button"
            onClick={onCancel}
            disabled={isSubmitting}
            className="px-4 py-2 text-zinc-600 transition-colors hover:bg-zinc-200 disabled:opacity-50"
          >
            Cancelar
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className="px-4 py-2 text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600 disabled:opacity-50"
          >
            {isSubmitting ? "Guardando..." : "Guardar Cambios"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CommunityForm; 