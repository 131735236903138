import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Users, BookOpen, Settings, Layout } from 'lucide-react';

const AdminNav = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const tabs = [
    { id: 'dashboard', label: 'Panel', path: '/admin', icon: <Layout className="w-5 h-5" /> },
    { id: 'communities', label: 'Comunidades', path: '/admin/communities', icon: <Users className="w-5 h-5" /> },
    { id: 'programs', label: 'Programas', path: '/admin/programs', icon: <BookOpen className="w-5 h-5" /> },
    { id: 'settings', label: 'Config', path: '/admin/settings', icon: <Settings className="w-5 h-5" /> },
  ];

  return (
    <div className="min-h-screen pt-16 pb-20 md:pb-0">
      <div className="container px-4 py-8 mx-auto">
        {/* Admin Header */}
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-2xl font-bold text-white md:text-3xl">Administración de Firepit</h1>
        </div>

        {/* Desktop Tabs */}
        <div className="hidden mb-6 space-x-4 border-b md:flex border-zinc-700">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`flex items-center space-x-2 px-4 py-2 -mb-px text-sm font-medium transition-colors border-b-2 ${
                location.pathname === tab.path
                  ? 'text-orange-500 border-orange-500'
                  : 'text-zinc-400 border-transparent hover:text-zinc-300'
              }`}
              onClick={() => navigate(tab.path)}
            >
              {tab.icon}
              <span>{tab.label}</span>
            </button>
          ))}
        </div>

        {/* Content */}
        <div className="mt-6">
          {children}
        </div>
      </div>

      {/* Mobile Bottom Navigation */}
      <div className="fixed bottom-0 left-0 right-0 z-50 bg-zinc-900 border-t md:hidden border-zinc-800">
        <div className="grid grid-cols-4">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`flex flex-col items-center justify-center py-3 transition-colors ${
                location.pathname === tab.path
                  ? 'text-orange-500'
                  : 'text-zinc-400 hover:text-zinc-300'
              }`}
              onClick={() => navigate(tab.path)}
            >
              {tab.icon}
              <span className="mt-1 text-xs font-medium">{tab.label}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminNav; 