import { SquareArrowOutUpRight } from 'lucide-react';
import React from 'react';

const Newsletter = ({ className = "py-6 bg-zinc-900" }) => {
  return (
      <section className={`${className}`}>
        <div className="grid-cols-1 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="w-full p-10 py-20 bg-orange-500 rounded-lg">
            <div className="max-w-2xl mx-auto text-center">
              <h2 className="mb-4 text-3xl font-bold">Únete a la lista de correos</h2>
              <p className="mb-8">
			  	Enviamos un recordatorio cada mes para los eventos de comunidad, y avisos importantes cuando eventos especiales están por llegar.
              </p>
              <a 
                href="https://eepurl.com/h9qvK1" 
                target="_blank" 
                rel="noopener noreferrer"
                className="inline-flex items-center px-6 py-3 text-orange-600 transition-all duration-300 bg-white rounded hover:bg-zinc-100 hover:scale-105"
              >
                Suscribirme <SquareArrowOutUpRight className="w-4 h-4 ml-2" />
              </a>
            </div>
          </div>
        </div>
    </section>
  );
};

export default Newsletter;