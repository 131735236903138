import React, { useState, useEffect } from "react";
import AdminNav from "./AdminNav";
import { Search, Plus, Users, MapPin, Edit2, Trash2 } from "lucide-react";
import Modal from "../common/Modal";
import CommunityForm from "../communities/CommunityForm";
import api from "../../services/api";
import { Link } from "react-router-dom";

const Communities = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [activeTab, setActiveTab] = useState("activas");
  const [communities, setCommunities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const tabs = [
    { id: "activas", label: "Activas" },
    { id: "inactivas", label: "Inactivas" },
  ];

  useEffect(() => {
    fetchCommunities();
  }, []);

  const fetchCommunities = async () => {
    try {
      setLoading(true);
      setError("");
      const response = await api.get("/communities");
      setCommunities(response?.data || []);
    } catch (err) {
      setError("Error al cargar las comunidades");
      console.error("Error fetching communities:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (community) => {
    setSelectedCommunity(community);
    setShowEditModal(true);
  };

  const handleDelete = async () => {
    try {
      await api.patch(`/communities/${selectedCommunity.id}/toggle-active`);
      fetchCommunities();
      setShowDeleteModal(false);
      setSelectedCommunity(null);
    } catch (err) {
      setError("Error al actualizar la comunidad");
      console.error("Error updating community:", err);
    }
  };

  const handleSubmit = async (formData) => {
    try {
      if (selectedCommunity) {
        await api.put(`/communities/${selectedCommunity.id}`, formData);
      } else {
        await api.post("/communities", formData);
      }
      fetchCommunities();
      setShowEditModal(false);
      setShowCreateForm(false);
      setSelectedCommunity(null);
    } catch (err) {
      setError("Error al guardar la comunidad");
      console.error("Error saving community:", err);
    }
  };

  const filteredCommunities = communities.filter(
    (community) =>
      (community?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        community?.description
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())) &&
      community?.is_active === (activeTab === "activas")
  );

  return (
    <AdminNav>
      <div className="flex flex-col space-y-6">
        {/* Header with Title and Create Button */}
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-bold text-white">Comunidades</h1>
            <p className="mt-1 text-sm text-zinc-400">
              Gestiona las comunidades de Firepit
            </p>
          </div>
          <div className="flex space-x-3">
            <button
              className="flex items-center px-4 py-4 space-x-2 text-white transition-colors bg-orange-500 rounded-full md:py-2 md:rounded-lg hover:bg-orange-600"
              onClick={() => setShowCreateForm(true)}
            >
              <Plus className="w-4 h-4" />
              <span className="hidden md:block">Crear Comunidad</span>
            </button>
          </div>
        </div>

        {/* Tabs */}
        <div className="flex mb-6 space-x-4 border-b border-zinc-700">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`px-4 py-2 -mb-px text-sm font-medium transition-colors border-b-2 ${
                activeTab === tab.id
                  ? "text-orange-500 border-orange-500"
                  : "text-zinc-400 border-transparent hover:text-zinc-300"
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>

        {/* Search Bar */}
        <div className="relative w-full">
          <Search className="absolute w-5 h-5 text-zinc-400 -translate-y-1/2 pointer-events-none left-4 top-1/2" />
          <input
            style={{ paddingLeft: "44px" }}
            type="text"
            className="w-full pr-4 text-white placeholder-zinc-400 bg-zinc-900 border border-zinc-700 rounded-lg h-11 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
            placeholder="Buscar comunidades..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Communities List */}
        <div className="divide-y divide-zinc-800 rounded-lg bg-zinc-900/50">
          {loading ? (
            <div className="flex items-center justify-center py-8">
              <div className="w-8 h-8 border-b-2 border-orange-500 rounded-full animate-spin"></div>
            </div>
          ) : error ? (
            <div className="p-4 mb-6 text-red-500 border border-red-500 rounded-lg bg-red-500/10">
              {error}
            </div>
          ) : filteredCommunities.length === 0 ? (
            <div className="py-8 text-center text-zinc-400">
              {searchTerm
                ? "No se encontraron comunidades que coincidan con tu búsqueda."
                : "No hay comunidades. ¡Crea una para comenzar!"}
            </div>
          ) : (
            <div className="divide-y divide-zinc-800">
              {filteredCommunities.map((community) => (
                <div
                  key={community.id}
                  className="flex items-start justify-between p-6 transition-colors hover:bg-zinc-800/50"
                >
                  <div className="flex-1">
                    <div className="flex items-center space-x-4">
                      <div className="items-center justify-center hidden w-12 h-12 text-xl font-bold text-white bg-orange-500 rounded-lg md:flex">
                        {community.name.charAt(0)}
                      </div>
                      <div>
                        <div className="flex items-center">
                          <h3 className="text-lg font-semibold text-white">
                            {community.name}
                          </h3>
                          <Link
                            to={`/communities/${community.id}/admin`}
                            className="ml-2 text-sm text-orange-500 hover:text-orange-400"
                          >
                            Administrar →
                          </Link>
                        </div>
                        <p className="mt-1 text-sm text-zinc-400">
                          {community.description}
                        </p>
                        <div className="flex items-center mt-2 text-sm text-zinc-400">
                          <Users className="w-4 h-4 mr-1" />
                          <span>{community.members_count || 0} miembros</span>
                          {!community.is_active && (
                            <span className="ml-2 px-2 py-0.5 bg-red-500/10 text-red-500 rounded-full text-xs">
                              Inactiva
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex ml-4 space-x-2">
                    <button
                      className="p-2 text-zinc-400 transition-colors rounded-lg hover:text-white hover:bg-zinc-700"
                      onClick={() => handleEdit(community)}
                      title="Editar comunidad"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                    <button
                      className="p-2 text-zinc-400 transition-colors rounded-lg hover:text-red-500 hover:bg-zinc-700"
                      onClick={() => {
                        setSelectedCommunity(community);
                        setShowDeleteModal(true);
                      }}
                      title={
                        community.is_active
                          ? "Desactivar comunidad"
                          : "Activar comunidad"
                      }
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Create/Edit Modal */}
        <Modal
          isOpen={showCreateForm || showEditModal}
          onClose={() => {
            setShowCreateForm(false);
            setShowEditModal(false);
            setSelectedCommunity(null);
          }}
          title={
            selectedCommunity ? "Editar Comunidad" : "Crear Nueva Comunidad"
          }
          size="medium"
        >
          <div className="p-6">
            <CommunityForm
              community={selectedCommunity}
              onSuccess={handleSubmit}
              onCancel={() => {
                setShowCreateForm(false);
                setShowEditModal(false);
                setSelectedCommunity(null);
              }}
            />
          </div>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal
          isOpen={showDeleteModal}
          onClose={() => {
            setShowDeleteModal(false);
            setSelectedCommunity(null);
          }}
          title="Confirmar Acción"
          size="small"
        >
          <div className="p-6">
            <p className="mb-6">
              ¿Estás seguro que deseas{" "}
              {selectedCommunity?.is_active ? "desactivar" : "activar"} "
              {selectedCommunity?.name}"?
            </p>
            <div className="flex justify-end space-x-3">
              <button
                className="px-4 py-2 text-zinc-400 transition-colors hover:text-white"
                onClick={() => {
                  setShowDeleteModal(false);
                  setSelectedCommunity(null);
                }}
              >
                Cancelar
              </button>
              <button
                className="px-4 py-2 text-white transition-colors bg-red-500 rounded-lg hover:bg-red-600"
                onClick={handleDelete}
              >
                {selectedCommunity?.is_active ? "Desactivar" : "Activar"}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </AdminNav>
  );
};

export default Communities;
