import { Award, Bolt, Compass, Globe, MapPin, Rocket, SquareArrowOutUpRight, UserRoundCheck } from 'lucide-react';
import React from 'react';
import { Helmet } from 'react-helmet-async';

const Organizadores = () => {

  const cityCategories = [
    {
      icon: <MapPin className='w-20 h-20 mb-2 text-orange-400' />, 
      title: 'Ciudad Piloto',
      description: 'El desarrollo de un ecosistema es un tema novedoso y poco explorado.',
      button: 'Aplica para Firepit Mix'
    },
    {
		icon: <Compass className='w-20 h-20 mb-2 text-orange-400' />, 
      title: 'Ciudad Emergente',
      description: 'Ecosistema de emprendimiento en pleno crecimiento, algunos eventos y comunidades.',
      button: 'Ponte en contacto'
    },
    {
		icon: <Globe className='w-20 h-20 mb-2 text-orange-400' />, 
      title: 'Ciudad Consolidada',
      description: 'Ecosistema emprendedor ya establecido, comunidades activas y networking.',
      button: 'Ponte en contacto'
    }
  ];

  const benefits = [
    {
      icon: <Rocket className='w-20 h-20 mb-2 text-orange-400' />, 
      title: 'Impacto Local',
      description: 'Transforma tu ciudad en un epicentro de innovación y emprendimiento, conectando ideas con acciones.'
    },
    {
      icon: <UserRoundCheck className='w-20 h-20 mb-2 text-orange-400' />, 
      title: 'Liderazgo Comunitario',
      description: 'Destaca como líder en tu comunidad, impulsando proyectos que generan cambios significativos.'
    },
    {
      icon: <Award className='w-20 h-20 mb-2 text-orange-400' />, 
      title: 'Reconocimiento Nacional',
      description: 'Sé reconocido como un referente en el ecosistema de emprendimiento a nivel regional y nacional.'
    },
    {
      icon: <Bolt className='w-20 h-20 mb-2 text-orange-400' />, 
      title: 'Acceso a Recursos',
      description: 'Obtén herramientas, mentorías y conexiones exclusivas para garantizar el éxito de tus eventos.'
    }
  ];

  return (
    <div className="min-h-screen bg-zinc-900">
	  <Helmet>
        <title>Firepit - Organiza Firepit en tu ciudad</title>
        <meta name="description" content="Descubre cómo puedes convertirte en un organizador Firepit y liderar la transformación de tu comunidad a través de eventos de impacto."/>
        
        {/* OpenGraph tags */}
        <meta property="og:title" content="Firepit — Conviertete en un mentor de la comunidad" />
        <meta property="og:description" content="Descubre cómo puedes convertirte en un organizador Firepit y liderar la transformación de tu comunidad a través de eventos de impacto." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://res.firep1t.com/static/organizers.png" />
        <meta property="og:url" content="https://firep1t.com/organizadores" />
        
        {/* Additional meta tags */}
        <meta property="og:site_name" content="Firepit" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@firep1t_" />
      </Helmet>

      {/* Hero Section */}
      <section className="relative h-[600px] mt-20 flex items-center bg-[linear-gradient(rgba(15,15,15,0.3),rgba(0,0,0,0.7),rgba(24,24,27,1)),url('https://res.firep1t.com/static/organizers.png')] bg-center bg-cover">
        <div className="absolute inset-0" />
        <div className="relative z-10 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h2 className="mb-4 text-sm font-bold tracking-widest text-orange-400 uppercase">Organiza en tu ciudad</h2>
            <h1 className="mb-6 text-4xl font-bold md:text-6xl">
            	Juntos, prendámos el mundo en fuego.
            </h1>
            <p className="mt-6 text-lg leading-8 text-zinc-300">
              Descubre cómo puedes convertirte en un organizador Firepit y liderar la transformación de tu comunidad a través de eventos de impacto.
            </p>
          </div>
        </div>
      </section>

      {/* City Categories Section */}
      <div className="pt-24 pb-16 bg-zinc-900">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight text-orange-400 sm:text-4xl">
              Categorías de Ciudades
            </h2>
            <p className="mt-6 text-lg leading-8 text-zinc-300">
              No importa el tamaño o el nivel de desarrollo de tu ciudad, hay un lugar para cada comunidad dentro de Firepit.
            </p>
          </div>
          <div className="grid max-w-5xl grid-cols-1 mx-auto mt-16 gap-x-8 gap-y-12 sm:grid-cols-3">
            {cityCategories.map((category) => (
              <div key={category.title} className="flex flex-col items-center justify-center gap-2 p-6 py-10 rounded-lg bg-zinc-700/50">
                {category.icon}
                <h3 className="text-xl font-semibold text-white ">{category.title}</h3>
                <p className="text-center text-zinc-300">{category.description}</p>
                {/* 
				<button className="px-4 py-2 mt-4 text-sm font-semibold text-white bg-orange-500 rounded-md hover:bg-orange-600">
                  {category.button}
                </button>
				*/}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Why Become an Organizer */}
      <div className="py-24 bg-zinc-900">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight text-orange-400 sm:text-4xl">
              ¿Por qué ser un Organizador Firepit?
            </h2>
            <p className="mt-6 text-lg leading-8 text-zinc-300">
              Ser organizador te brinda una plataforma única para liderar, conectar y transformar tu comunidad a través de eventos de alto impacto.
            </p>
          </div>
          <div className="grid max-w-5xl grid-cols-1 mx-auto mt-16 gap-x-8 gap-y-12 sm:grid-cols-2">
            {benefits.map((benefit) => (
              <div key={benefit.title} className="flex gap-4 p-6 rounded-lg bg-zinc-700/50">
                {benefit.icon}
                <div className='flex flex-col gap'>
                  <h3 className="text-xl font-semibold text-white">{benefit.title}</h3>
                  <p className="mt-3 text-zinc-300">{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Call to Action */}
      <div id="postulate" className="py-24 bg-zinc-900">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              ¡Próximamente!
            </h2>
            <p className="mt-6 text-lg leading-8 text-zinc-300">
              Estamos trabajando para abrir nuevas oportunidades de organización. Mantente al tanto para ser parte de esta experiencia.
            </p>
            <button disabled className="inline-flex items-center px-6 py-3 mx-auto mt-6 text-lg font-semibold text-white bg-gray-600 rounded-md cursor-not-allowed">
              <SquareArrowOutUpRight className="w-4 h-4 mr-2" />
              Agenda una reunión
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organizadores;