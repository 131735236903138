import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaYoutube, FaTiktok, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import Logo from './Logo';

const Footer = () => {
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await fetch('/api/programs');
        const data = await response.json();
        setPrograms(data);
      } catch (error) {
        console.error('Error fetching programs:', error);
      }
    };

    fetchPrograms();
  }, []);

  const handleTranslate = () => {
    if (window.google && window.google.translate) {
      new window.google.translate.TranslateElement({
        pageLanguage: 'es',
        includedLanguages: 'en,fr,pt', // Add more languages as needed
        layout: null,
        autoDisplay: false
      }, 'google_translate_element');
    } else {
      console.error("Google Translate API not loaded");
    }
  };

  return (
    <footer className="pb-16 bg-gradient-to-t from-black to-zinc-900">
      <div className='flex flex-col items-center justify-center gap-4 py-28'>
        <Logo />
        <div className="flex flex-wrap gap-6 mt-12 text-2xl justify-center text-white w-[80vw]">
          <Link to="https://www.facebook.com/firep1t" target="_blank" rel="noopener nofollow" className="bg-[#1877F2] hover:opacity-80 px-3 py-2 rounded-full flex gap-2 items-center font-bold">
            <FaFacebook /> <span className='text-sm'>Facebook</span>
          </Link>
          <Link to="https://www.instagram.com/firep1t" target="_blank" rel="noopener nofollow" className="bg-[#E4405F] hover:opacity-80 px-3 py-2 rounded-full flex gap-2 items-center font-bold">
            <FaInstagram /> <span className='text-sm'>Instagram</span>
          </Link>
          <Link to="https://www.x.com/firep1t_" target="_blank" rel="noopener nofollow" className="flex items-center gap-2 px-3 py-2 font-bold bg-transparent rounded-full hover:opacity-80">
            <FaXTwitter />
          </Link>
          <Link to="https://www.tiktok.com/@firep1t" target="_blank" rel="noopener nofollow" className="flex items-center gap-2 px-3 py-2 font-bold bg-black rounded-full hover:opacity-80">
            <FaTiktok /> <span className='text-sm'>TikTok</span>
          </Link>
          <Link to="https://www.youtube.com/@firep1t" target="_blank" rel="noopener nofollow" className="bg-[#FF0000] hover:opacity-80 px-3 py-2 rounded-full flex gap-2 items-center font-bold">
            <FaYoutube /> <span className='text-sm'>YouTube</span>
          </Link>
        </div>
      </div>
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid gap-8 md:grid-cols-4">
          <div>
            <h2 className="mb-4 text-2xl font-bold">Comunidad</h2>
            <ul className="space-y-2">
              <li><Link to="/nosotros" className="text-zinc-400 hover:text-white">Nosotros</Link></li>
              <li><Link to="/medios" className="text-zinc-400 hover:text-white">Medios</Link></li>
              <li><Link to="/embajadores" className="text-zinc-400 hover:text-white">Embajadores</Link></li>
              <li><Link to="/mentores" className="text-zinc-400 hover:text-white">Mentores</Link></li>
              <li><Link to="/organizadores" className="text-zinc-400 hover:text-white">Organizadores</Link></li>
            </ul>
          </div>
          <div>
            <h2 className="mb-4 text-2xl font-bold">Programas</h2>
            <ul className="space-y-2">
              {programs.length > 0 ? (
                programs.map((program) => (
                  <li key={program.id}>
                    <Link to={'/programas/'+program.slug || "#"} className="text-zinc-400 hover:text-white">
                      {program.name}
                    </Link>
                  </li>
                ))
              ) : (
                <li><span className="text-zinc-400">Cargando programas...</span></li>
              )}
            </ul>
          </div>
          <div>
          </div>
          <div className='md:text-end'>
            <h2 className="mb-4 text-2xl font-bold">Plataformas</h2>
            <ul className="space-y-2">
              <li><Link to="/login" className="text-zinc-400 hover:text-white">Mi Firepit →</Link></li>
			  <li><br /></li>
              <li><Link to="/privacidad" className="text-zinc-400 hover:text-white">Aviso de Privacidad</Link></li>
              <li><Link to="/terminos" className="text-zinc-400 hover:text-white">Condiciones Legales</Link></li>
              <li><Link to="/datos" className="text-zinc-400 hover:text-white">Protección de Datos</Link></li>
			  <li>
     			 <div id="google_translate_element"></div>
			  </li>
            </ul>
          </div>
        </div>
        <div className="pt-8 mt-12 border-t border-zinc-800">
          <div className="flex flex-col items-center justify-center gap-4 md:flex-row">
            <div className="text-sm text-center text-zinc-400">
              ©2025 Firepit - El Ciclo del Fuego A. C.
              <br />
              Todos los derechos reservados.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 