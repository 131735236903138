import React, { useState, useEffect, memo } from "react";
import api from "../../services/api";
import EventModal from "../events/EventModal";
import { useAuth } from "../../contexts/AuthContext";
import { useCommunity } from "../../contexts/CommunityContext";
import EventCard from "../events/EventCard";

const CommunityUpcomingEvents = ({
  communityId,
  typeClassName = "text-orange-500",
}) => {
  const [eventCategories, setEventCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { user } = useAuth();
  const { activeCommunity } = useCommunity();

  const canViewAllEvents = user?.role === 'admin' || 
    (activeCommunity?.id === communityId && activeCommunity?.pivot?.role === 'admin');

  const handleEventUpdate = (updatedEvent) => {
    setEventCategories(prevCategories => {
      return prevCategories.map(category => {
        // Update in upcoming events
        const updatedUpcoming = category.upcoming_events.map(event => 
          event.id === updatedEvent.id ? updatedEvent : event
        );

        // Update in past events
        const updatedPast = category.past_events.map(event => 
          event.id === updatedEvent.id ? updatedEvent : event
        );

        return {
          ...category,
          upcoming_events: updatedUpcoming,
          past_events: updatedPast
        };
      });
    });

    // Update the selected event if it's the one being displayed in modal
    if (selectedEvent?.id === updatedEvent.id) {
      setSelectedEvent(updatedEvent);
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await api.get(`/communities/${communityId}/events-by-category`);
        
        // Filter events based on user permissions
        const filteredCategories = response.map(category => ({
          ...category,
          upcoming_events: category.upcoming_events.filter(event => 
            canViewAllEvents || 
            !['draft', 'cancelled'].includes(event.status)
          ),
          past_events: category.past_events.filter(event => 
            canViewAllEvents || 
            !['draft', 'cancelled'].includes(event.status)
          )
        })).filter(category => 
          category.upcoming_events.length > 0 || category.past_events.length > 0
        );

        setEventCategories(filteredCategories);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching events:", err);
        setError("Error al cargar los eventos");
        setLoading(false);
      }
    };

    if (communityId) {
      fetchEvents();
    }
  }, [communityId, canViewAllEvents]);

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="w-8 h-8 border-b-2 border-orange-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500 rounded-lg bg-red-500/10">{error}</div>
    );
  }

  if (eventCategories.length === 0) {
    return (
      <div className="text-center">
        <p className="text-zinc-400">No hay eventos próximos.</p>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <h2 className={`text-4xl font-bold text-white`}>
        Eventos
      </h2>
      {[...eventCategories].reverse().map((category) => (
        <div key={category.type_name}>
          <h3 className={`mb-6 text-sm font-medium tracking-wider uppercase ${typeClassName}`}>
            {category.type_name}
          </h3>

          <div>
            <div className="w-full pb-2 overflow-x-auto [&::-webkit-scrollbar]:h-1.5 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:transparent [&::-webkit-scrollbar-thumb]:bg-zinc-400/20 hover:[&::-webkit-scrollbar-thumb]:bg-zinc-400/40">
              <div className="flex gap-6 min-w-min">
                {category.upcoming_events.length > 0 &&
                  category.upcoming_events.map((event) => (
                    <EventCard 
                      key={event.id} 
                      event={event} 
                      isPast={false} 
                      onClick={setSelectedEvent}
                      typeClassName={typeClassName}
                    />
                  ))}
                {category.past_events.length > 0 &&
                  category.past_events.map((event) => (
                    <EventCard 
                      key={event.id} 
                      event={event} 
                      isPast={true} 
                      onClick={setSelectedEvent}
                      typeClassName={typeClassName}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      ))}

      {selectedEvent && (
        <EventModal 
          event={selectedEvent} 
          onClose={() => setSelectedEvent(null)} 
          onEventUpdate={handleEventUpdate}
        />
      )}
    </div>
  );
};

export default CommunityUpcomingEvents;
