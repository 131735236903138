import React, { useState, useEffect, useRef } from "react";
import { useCommunity } from "../../../contexts/CommunityContext";
import { ChevronLeft, ChevronRight, MapPin } from "lucide-react";
import "./VenueForm.css";

const loadGoogleMapsScript = (callback) => {
  if (window.google && window.google.maps) {
    callback();
    return;
  }

  window.initMap = callback;

  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyC5v55PEJJXkAakDS1jC97sFDrne24bx-g&libraries=places&callback=initMap`;
  script.async = true;
  script.defer = true;
  script.onerror = () => {
    console.error("Error loading Google Maps script");
  };
  document.head.appendChild(script);
};

const VenueForm = ({ venue, onSubmit, onCancel }) => {
  const { activeCommunity } = useCommunity();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    name: venue?.name || "",
    address: venue?.address || "",
    description: venue?.description || "",
    capacity: venue?.capacity || "",
    city: venue?.city || "",
    state: venue?.state || "",
    country: venue?.country || "",
    postal_code: venue?.postal_code || "",
    latitude: venue?.latitude || "",
    longitude: venue?.longitude || "",
    additional_info: venue?.additional_info || "",
    is_active: venue?.is_active ?? true,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");
  const autocompleteRef = useRef(null);
  const googleRef = useRef(null);
  const inputRef = useRef(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    const initializeGoogleMaps = () => {
      if (!mapRef.current || !inputRef.current) return;

      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["establishment", "geocode"],
        }
      );

      // Initialize map
      const map = new window.google.maps.Map(mapRef.current, {
        zoom: 15,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        center: { lat: 0, lng: 0 }, // Default center
      });

      // Initialize marker
      const marker = new window.google.maps.Marker({
        map,
        draggable: true,
      });

      // If editing a venue or have coordinates, set initial position
      if (formData.latitude && formData.longitude) {
        const position = new window.google.maps.LatLng(
          formData.latitude,
          formData.longitude
        );
        map.setCenter(position);
        marker.setPosition(position);
      }

      // Handle marker drag
      marker.addListener("dragend", () => {
        const position = marker.getPosition();
        setFormData((prev) => ({
          ...prev,
          latitude: position.lat(),
          longitude: position.lng(),
        }));
      });

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (!place.geometry) {
          console.error("No geometry for selected place");
          return;
        }

        // Update map and marker
        map.setCenter(place.geometry.location);
        marker.setPosition(place.geometry.location);

        // Initialize address components
        let addressComponents = {
          street_number: "",
          route: "",
          locality: "",
          administrative_area_level_1: "",
          country: "",
          postal_code: "",
        };

        // Extract address components
        place.address_components.forEach((component) => {
          component.types.forEach((type) => {
            switch (type) {
              case "locality":
                addressComponents.locality = component.long_name;
                break;
              case "administrative_area_level_1":
                addressComponents.administrative_area_level_1 =
                  component.long_name;
                break;
              case "country":
                addressComponents.country = component.long_name;
                break;
              case "postal_code":
                addressComponents.postal_code = component.long_name;
                break;
              default:
                break;
            }
          });
        });

        const newData = {
          address: place.formatted_address,
          city: addressComponents.locality,
          state: addressComponents.administrative_area_level_1,
          country: addressComponents.country,
          postal_code: addressComponents.postal_code || "",
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        };

        setFormData((prev) => ({
          ...prev,
          ...newData,
        }));
      });

      autocompleteRef.current = autocomplete;
      googleRef.current = window.google;
      markerRef.current = marker;
    };

    if (mapLoaded) {
      loadGoogleMapsScript(initializeGoogleMaps);
    }

    return () => {
      if (autocompleteRef.current && googleRef.current) {
        googleRef.current.maps.event.clearInstanceListeners(
          autocompleteRef.current
        );
      }
    };
  }, [mapLoaded]);

  // Separate useEffect to handle map div mounting
  useEffect(() => {
    if (currentStep === 2) {
      setMapLoaded(true);
    }
  }, [currentStep]);

  useEffect(() => {
    if (venue) {
      setFormData({
        name: venue.name || "",
        address: venue.address || "",
        description: venue.description || "",
        capacity: venue.capacity || "",
        city: venue.city || "",
        state: venue.state || "",
        country: venue.country || "",
        postal_code: venue.postal_code || "",
        latitude: venue.latitude || "",
        longitude: venue.longitude || "",
        additional_info: venue.additional_info || "",
        is_active: venue.is_active ?? true,
      });
    }
  }, [venue]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const validateStep = (step, isSubmitting = false) => {
    const newErrors = {};
    const shouldValidate = isSubmitting || !venue;

    if (step === 1) {
      if (shouldValidate) {
        if (!formData.name.trim()) {
          newErrors.name = "El nombre es obligatorio";
        }
        if (!formData.capacity) {
          newErrors.capacity = "La capacidad es obligatoria";
        } else if (
          isNaN(formData.capacity) ||
          parseInt(formData.capacity) <= 0
        ) {
          newErrors.capacity = "La capacidad debe ser un número positivo";
        }
      }
    }

    if (step === 2) {
      if (shouldValidate) {
        if (!formData.address.trim()) {
          newErrors.address = "La dirección es obligatoria";
        }
        if (!formData.city.trim()) {
          newErrors.city = "La ciudad es obligatoria";
        }
        if (!formData.state.trim()) {
          newErrors.state = "El estado es obligatorio";
        }
        if (!formData.country.trim()) {
          newErrors.country = "El país es obligatorio";
        }
        if (!formData.postal_code.trim()) {
          newErrors.postal_code = "El código postal es obligatorio";
        }
        if (!formData.latitude || !formData.longitude) {
          newErrors.address = "Por favor selecciona una dirección de las sugerencias";
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep(currentStep)) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep(currentStep, true)) return;

    try {
      setIsSubmitting(true);
      setError("");

      const venueData = {
        ...formData,
        capacity: parseInt(formData.capacity),
        latitude: parseFloat(formData.latitude),
        longitude: parseFloat(formData.longitude),
      };

      await onSubmit(venueData);
    } catch (err) {
      console.error("Error creando sede:", err);
      setError(err.message || "Error al crear sede");
    } finally {
      setIsSubmitting(false);
      onCancel();
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="form-group">
              <label htmlFor="name">Nombre *</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Ingresa el nombre de la sede"
                className={errors.name ? "error" : ""}
                disabled={isSubmitting}
                autoComplete="off"
              />
              {errors.name && (
                <span className="error-message">{errors.name}</span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="description">Descripción</label>
              <textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Ingresa la descripción de la sede"
                rows="4"
                disabled={isSubmitting}
                autoComplete="off"
              />
            </div>

            <div className="form-group">
              <label htmlFor="capacity">Capacidad *</label>
              <input
                type="number"
                id="capacity"
                name="capacity"
                value={formData.capacity}
                onChange={handleChange}
                placeholder="Ingresa la capacidad de la sede"
                min="1"
                className={errors.capacity ? "error" : ""}
                disabled={isSubmitting}
                autoComplete="off"
              />
              {errors.capacity && (
                <span className="error-message">{errors.capacity}</span>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="additional_info">Información Adicional</label>
              <textarea
                id="additional_info"
                name="additional_info"
                value={formData.additional_info}
                onChange={handleChange}
                placeholder="Ingresa información adicional"
                rows="3"
                disabled={isSubmitting}
                autoComplete="off"
              />
            </div>
          </>
        );

      case 2:
        return (
          <>
            <div className="form-group">
              <label htmlFor="address">Dirección *</label>
              <div className="relative">
                <MapPin className="absolute w-5 h-5 text-zinc-400 -translate-y-1/2 left-3 top-1/2" />
                <input
                  ref={inputRef}
                  type="text"
                  id="address"
                  name="address"
                  value={formData.address}
                  style={{ paddingLeft: "3rem" }}
                  onChange={handleChange}
                  placeholder="Comienza a escribir para buscar una dirección"
                  className={`pl-10 ${errors.address ? "error" : ""}`}
                  disabled={isSubmitting}
                  autoComplete="off"
                />
              </div>
              {errors.address && (
                <span className="error-message">{errors.address}</span>
              )}
            </div>

            {/* Map */}
            <div
              ref={mapRef}
              className="w-full h-48 mb-4 overflow-hidden border-2 border-zinc-400 rounded-lg"
            ></div>

            <div className="grid grid-cols-2 gap-4">
              <div className="form-group">
                <label htmlFor="city">Ciudad</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  className="text-zinc-400"
                  readOnly
                />
              </div>

              <div className="form-group">
                <label htmlFor="state">Estado/Provincia</label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={formData.state}
                  className="text-zinc-400"
                  readOnly
                />
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="form-group">
                <label htmlFor="country">País</label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  value={formData.country}
                  className="text-zinc-400"
                  readOnly
                />
              </div>

              <div className="form-group">
                <label htmlFor="postal_code">Código Postal</label>
                <input
                  type="text"
                  id="postal_code"
                  name="postal_code"
                  value={formData.postal_code}
                  className="text-zinc-400"
                  readOnly
                />
              </div>
            </div>

            <div className="mt-2 text-sm text-zinc-400">
              <p>Arrastra el pin en el mapa para ajustar la ubicación exacta</p>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className="venue-form">
      {error && <div className="mb-4 error-message">{error}</div>}

      {/* Progress Steps */}
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center w-full">
          {[
            { step: 1, label: "Información" },
            { step: 2, label: "Ubicación" },
          ].map(({ step, label }) => (
            <div
              key={step}
              className={`flex items-center ${step !== 2 ? "flex-1" : ""}`}
            >
              <div className="flex flex-col items-center">
                <div
                  className={`w-10 h-10 rounded-full flex items-center justify-center text-sm font-medium border-2 transition-colors
                    ${currentStep === step
                      ? "border-orange-500 text-orange-500"
                      : currentStep > step
                      ? "border-green-500 bg-green-500 text-white"
                      : "border-zinc-600 text-zinc-500"
                    }`}
                >
                  {currentStep > step ? "✓" : step}
                </div>
                <span className={`mt-2 text-sm text-center font-medium
                  ${currentStep === step
                    ? "text-orange-500"
                    : currentStep > step
                    ? "text-green-500"
                    : "text-zinc-500"
                  }`}
                >
                  {label}
                </span>
              </div>
              {step !== 2 && (
                <div
                  className={`h-0.5 w-full mx-4 transition-colors ${
                    currentStep > step ? "bg-green-500" : "bg-zinc-700"
                  }`}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      <form onSubmit={(e) => {
        if (currentStep !== 2) {
          e.preventDefault();
          return;
        }
        handleSubmit(e);
      }} autoComplete="off">
        {renderStepContent()}

        <div className="flex justify-between mt-6">
          <button
            type="button"
            onClick={handleBack}
            disabled={currentStep === 1 || isSubmitting}
            className="flex items-center px-4 py-2 text-zinc-400 transition-colors bg-zinc-800 rounded-lg hover:text-white disabled:opacity-50"
          >
            <ChevronLeft className="w-4 h-4 mr-2" />
            Volver
          </button>
          
          <div className="flex space-x-3">
            <button
              type="button"
              onClick={onCancel}
              disabled={isSubmitting}
              className="px-4 py-2 text-zinc-400 transition-colors hover:text-white disabled:opacity-50"
            >
              Cancelar
            </button>
            
            {currentStep < 2 ? (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleNext();
                }}
                disabled={isSubmitting}
                className="flex items-center px-4 py-2 text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600 disabled:opacity-50"
              >
                Siguiente
                <ChevronRight className="w-4 h-4 ml-2" />
              </button>
            ) : (
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 text-white transition-colors bg-orange-500 rounded-lg hover:bg-orange-600 disabled:opacity-50"
              >
                {isSubmitting
                  ? "Guardando..."
                  : venue
                  ? "Actualizar Sede"
                  : "Crear Sede"}
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default VenueForm;
