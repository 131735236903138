import React, { useState, useEffect, useRef } from 'react';
import api from '../../services/api';
import './CommunitySelector.css';

const CommunitySelector = ({ onSelect, error, selectedCommunity }) => {
    const [communities, setCommunities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fetchError, setFetchError] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        fetchCommunities();
    }, []);

    useEffect(() => {
        // Auto-select first community if none is selected
        if (communities.length > 0 && !selectedCommunity) {
            onSelect(communities[0]);
        }
    }, [communities, selectedCommunity, onSelect]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const fetchCommunities = async () => {
        try {
            setLoading(true);
            const response = await api.get('/communities');
            setCommunities(response.data);
        } catch (err) {
            console.error('Error fetching communities:', err);
            setFetchError('Failed to load communities');
            setCommunities([]);
        } finally {
            setLoading(false);
        }
    };

    const getInitials = (name) => {
        return name
            .split(' ')
            .map(word => word[0])
            .join('')
            .toUpperCase()
            .slice(0, 2);
    };

    const handleSelect = (community) => {
        onSelect(community);
        setIsOpen(false);
    };

    if (loading) {
        return <div className="community-selector-loading">Cargando comunidades...</div>;
    }

    if (fetchError) {
        return <div className="community-selector-error">{fetchError}</div>;
    }

    return (
        <div className="community-selector-container">
            <label className="community-selector-label">
                Selecciona una comunidad
                {error && <span className="community-selector-error">{error}</span>}
            </label>
            <div className="community-dropdown" ref={dropdownRef}>
                <button
                    type="button"
                    className={`community-dropdown-toggle ${error ? 'has-error' : ''}`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {selectedCommunity ? (
                        <>
                            {selectedCommunity.image_url ? (
                                <img 
                                    src={selectedCommunity.image_url} 
                                    alt={selectedCommunity.name} 
                                    className="community-image"
                                />
                            ) : (
                                <div className="community-initials">
                                    {getInitials(selectedCommunity.name)}
                                </div>
                            )}
                            <span className="selected-community-name">
                                {selectedCommunity.name}
                            </span>
                        </>
                    ) : (
                        <span className="placeholder">Selecciona una comunidad</span>
                    )}
                    <svg className={`dropdown-arrow ${isOpen ? 'open' : ''}`} viewBox="0 0 24 24">
                        <path d="M7 10l5 5 5-5" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>

                {isOpen && (
                    <div className="community-dropdown-menu">
                        {communities.length === 0 ? (
                            <div className="no-communities">No hay comunidades disponibles</div>
                        ) : (
                            communities.map(community => (
                                <button
                                    key={community.id}
                                    type="button"
                                    className={`community-option ${selectedCommunity?.id === community.id ? 'selected' : ''}`}
                                    onClick={() => handleSelect(community)}
                                >
                                    {community.image_url ? (
                                        <img 
                                            src={community.image_url} 
                                            alt={community.name} 
                                            className="community-image"
                                        />
                                    ) : (
                                        <div className="community-initials">
                                            {getInitials(community.name)}
                                        </div>
                                    )}
                                    <span className="community-name">{community.name}</span>
                                </button>
                            ))
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CommunitySelector; 