import React, { useState, useEffect } from 'react';
import { useCommunity } from '../contexts/CommunityContext';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';
import { Link } from 'react-router-dom';
import EventModal from './events/EventModal';
import EventCard from './events/EventCard';

const Dashboard = () => {
    const { activeCommunity } = useCommunity();
    const { user } = useAuth();
    const [registeredEvents, setRegisteredEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedEvent, setSelectedEvent] = useState(null);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                setLoading(true);
                setError('');

                // Fetch events the user is registered to
                const registeredResponse = await api.get('/my-tickets');
                console.log('Registered tickets response:', registeredResponse);
                
                const tickets = registeredResponse || [];
                // Extract events from tickets
                const uniqueEvents = tickets.reduce((acc, attendee) => {
                    const event = attendee.ticket?.event;
                    if (event && !acc.some(e => e.id === event.id)) {
                        acc.push(event);
                    }
                    return acc;
                }, []);
                console.log('Unique registered events:', uniqueEvents);
                setRegisteredEvents(uniqueEvents);

                // Only fetch upcoming events if there's an active community
                if (activeCommunity?.id) {
                    try {
                        const upcomingResponse = await api.get(`/communities/${activeCommunity.id}/events`, {
                            params: { status: 'registration_open' }
                        });
                        console.log('Upcoming events response:', upcomingResponse);
                        
                        // Filter out any undefined events and events user is already registered to
                        const validEvents = (upcomingResponse || []).filter(event => 
                            event && 
                            event.id && 
                            !uniqueEvents.some(e => e.id === event.id)
                        );
                        console.log('Valid upcoming events:', validEvents);
                        setUpcomingEvents(validEvents);
                    } catch (communityError) {
                        console.error('Error fetching community events:', communityError);
                        setUpcomingEvents([]);
                    }
                } else {
                    setUpcomingEvents([]);
                }
            } catch (err) {
                console.error('Error fetching events:', err);
                setError('No se pudieron cargar los eventos');
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, [activeCommunity]);

    const handleEventUpdate = (updatedEvent) => {
        // Update in registered events
        setRegisteredEvents(prev => 
            prev.map(event => event.id === updatedEvent.id ? updatedEvent : event)
        );

        // Update in upcoming events
        setUpcomingEvents(prev => 
            prev.map(event => event.id === updatedEvent.id ? updatedEvent : event)
        );

        // Update selected event if it's the one being displayed
        if (selectedEvent?.id === updatedEvent.id) {
            setSelectedEvent(updatedEvent);
        }
    };

    return (
        <div className="min-h-screen pt-24 pb-20 text-white bg-black">
            <div className="container px-4 mx-auto">
                {/* Header Section */}
                <div className="p-4 mb-6 rounded-lg bg-zinc-900 md:p-6">
                    <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                        <div className="flex items-center">
                            {user?.avatar_url ? (
                                <img src={user?.avatar_url} alt={user?.name || 'Usuario'} className="w-12 h-12 rounded-full md:w-16 md:h-16" />
                            ) : (
                                <div className="flex items-center justify-center w-12 h-12 text-xl font-bold bg-orange-500 rounded-full md:w-16 md:h-16">
                                    {user?.name?.charAt(0) || '?'}
                                </div>
                            )}
                            <div className="ml-4">
                                <h1 className="text-2xl font-bold md:text-3xl">{user?.name || 'Usuario'}</h1>
                                <div className="flex items-center mt-1 text-sm text-zinc-400">
                                    <span>
                                        {activeCommunity?.pivot?.role === 'admin' ? 'Organizador de ' : 'Miembro de '}
                                    </span>
                                    <span className="ml-1">{activeCommunity?.name || 'ninguna comunidad'}</span>
                                </div>
                                {activeCommunity?.pivot?.role === 'admin' && (
                                    <Link
                                        to={`/communities/${activeCommunity.id}/admin`}
                                        className="inline-block mt-2 text-sm text-orange-500 hover:text-orange-400"
                                    >
                                        Administrar Comunidad →
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                {error && (
                    <div className="p-4 mb-6 text-red-500 rounded-lg bg-red-500/10">
                        {error}
                    </div>
                )}

                {/* My Registered Events */}
                <div className="mb-8">
                    <h2 className="mb-4 text-xl font-bold">Ya registrado en:</h2>
                    {loading ? (
                        <div className="space-y-4 animate-pulse">
                            {[1, 2].map(i => (
                                <div key={i} className="h-[280px] bg-zinc-900 rounded-lg"></div>
                            ))}
                        </div>
                    ) : registeredEvents.length > 0 ? (
                        <div className="w-full pb-2 overflow-x-auto [&::-webkit-scrollbar]:h-1.5 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:transparent [&::-webkit-scrollbar-thumb]:bg-zinc-400/20 hover:[&::-webkit-scrollbar-thumb]:bg-zinc-400/40">
                            <div className="flex gap-6 min-w-min">
                                {registeredEvents.map(event => event && (
                                    <EventCard 
                                        key={event.id} 
                                        event={event} 
                                        isPast={new Date(event.end_date) < new Date()} 
                                        onClick={setSelectedEvent}
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="p-6 text-center rounded-lg bg-zinc-900">
                            <div className="text-zinc-400">No estás registrado en ningún evento</div>
                        </div>
                    )}
                </div>

                {/* Upcoming Events with Open Registration */}
                <div className="mb-8">
                    <h2 className="mb-4 text-xl font-bold">Eventos Disponibles:</h2>
                    {loading ? (
                        <div className="space-y-4 animate-pulse">
                            {[1, 2].map(i => (
                                <div key={i} className="h-[280px] bg-zinc-900 rounded-lg"></div>
                            ))}
                        </div>
                    ) : upcomingEvents.length > 0 ? (
                        <div className="w-full pb-2 overflow-x-auto [&::-webkit-scrollbar]:h-1.5 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:transparent [&::-webkit-scrollbar-thumb]:bg-zinc-400/20 hover:[&::-webkit-scrollbar-thumb]:bg-zinc-400/40">
                            <div className="flex gap-6 min-w-min">
                                {upcomingEvents.map(event => event && (
                                    <EventCard 
                                        key={event.id} 
                                        event={event} 
                                        isPast={false}
                                        onClick={setSelectedEvent}
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="p-6 text-center rounded-lg bg-zinc-900">
                            <div className="text-zinc-400">No hay eventos disponibles para registro</div>
                        </div>
                    )}
                </div>

                {/* Placeholder Sections */}
                <div className="space-y-8">
                    {/* Projects Placeholder */}
                    <div className="p-6 rounded-lg bg-zinc-900">
                        <div className="flex items-center justify-between mb-4">
                            <h2 className="text-xl font-bold">Mis Proyectos</h2>
                            <span className="text-sm text-zinc-400">Próximamente</span>
                        </div>
                        <div className="py-8 text-center text-zinc-400">
                            Esta función estará disponible pronto
                        </div>
                    </div>

                    {/* Contacts Placeholder */}
                    <div className="p-6 rounded-lg bg-zinc-900">
                        <div className="flex items-center justify-between mb-4">
                            <h2 className="text-xl font-bold">Mis Contactos</h2>
                            <span className="text-sm text-zinc-400">Próximamente</span>
                        </div>
                        <div className="py-8 text-center text-zinc-400">
                            Esta función estará disponible pronto
                        </div>
                    </div>

                    {/* Community Points Placeholder */}
                    <div className="p-6 rounded-lg bg-zinc-900">
                        <div className="flex items-center justify-between mb-4">
                            <h2 className="text-xl font-bold">Puntos de Comunidad</h2>
                            <span className="text-sm text-zinc-400">Próximamente</span>
                        </div>
                        <div className="py-8 text-center text-zinc-400">
                            Esta función estará disponible pronto
                        </div>
                    </div>
                </div>

                {selectedEvent && (
                    <EventModal 
                        event={selectedEvent} 
                        onClose={() => setSelectedEvent(null)} 
                        onEventUpdate={handleEventUpdate}
                    />
                )}
            </div>
        </div>
    );
};

export default Dashboard; 