import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import SocialLogin from './SocialLogin';
import Register from './Register';
import Modal from '../common/Modal';
import './Login.css';

const Login = ({ onSuccess, onRegisterClick, isModal = false }) => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const [showRegister, setShowRegister] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            const response = await api.post('/login', formData);
            login(response.token, response.user);
            if (onSuccess) {
                onSuccess();
            }
            navigate('/dashboard');
        } catch (error) {
            console.error('Login error:', error);
            setError(error.message || 'Login failed');
        } finally {
            setIsLoading(false);
        }
    };

    const handleRegisterSuccess = () => {
        setShowRegister(false);
        navigate('/dashboard');
    };

    return (
        <>
            <div className="flex flex-col items-center justify-center w-full h-full pt-32 bg-gradient-to-b from-black to-zinc-900">
				<div className="w-full max-w-md">
					<h1 className="mb-8 text-4xl font-bold text-white">
						Iniciar Sesión <br></br>
						<small className="text-zinc-400">Mi Firepit</small>
					</h1>
					<div className="w-full p-10 bg-white rounded-lg">
						{error && <div className="error-message">{error}</div>}
						
						<form onSubmit={handleSubmit}>
							<div className="form-group">
								<label htmlFor="email">Email</label>
								<input
									type="email"
									id="email"
									name="email"
									autoComplete="email"
									value={formData.email}
									className="text-zinc-900"
									onChange={handleChange}
									required
								/>
							</div>

							<div className="form-group">
								<label htmlFor="password">Contraseña</label>
								<input
									type="password"
									id="password"
									name="password"
									autoComplete="current-password"
									className="text-zinc-900"
									value={formData.password}
									onChange={handleChange}
									required
								/>
							</div>

							<button 
								type="submit" 
								className="w-full px-4 py-2 font-bold text-white bg-orange-400 rounded hover:bg-orange-500"
								disabled={isLoading}
							>
								{isLoading ? 'Iniciando sesión...' : 'Iniciar Sesión'}
							</button>
						</form>

						<div className="auth-divider">
							<span>O</span>
						</div>

						<SocialLogin onSuccess={onSuccess} />
					</div>
				</div>
            </div>

            <Modal
                isOpen={showRegister}
                onClose={() => setShowRegister(false)}
                title="Registro"
            >
                <Register 
                    isModal={true}
                    onSuccess={handleRegisterSuccess}
                    onLoginClick={() => setShowRegister(false)}
                />
            </Modal>
        </>
    );
};

export default Login; 