import React from "react";

const Logo = () => {
  return (
    <svg
      width="150px"
      height="100%"
      viewBox="0 0 598 188"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Firepit Logo</title>
      <g
        id="Firepit-Branding"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Firepit-Main-Logo--B-Copy"
          transform="translate(-717.000000, -510.000000)"
        >
          <g id="Group" transform="translate(717.400000, 510.000000)">
            <g
              id="Group-3-Copy-4"
              transform="translate(0.000000, 154.400000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <g id="FIREPIT">
                <polygon
                  id="Path"
                  points="0 0 29.6 0 29.6 5.83390915 6.71187411 5.83390915 6.71187411 14.4168906 26.148784 14.4168906 26.148784 19.8940499 6.71187411 19.8940499 6.71187411 32.8 0 32.8"
                ></polygon>
                <polygon
                  id="Path"
                  points="100 0 106.4 0 106.4 32.8 100 32.8"
                ></polygon>
                <path
                  d="M180,0 L196.982644,0 C199.546061,0 201.76769,0.262316059 203.64753,0.786948177 C205.52737,1.31158029 207.086782,2.07054809 208.325768,3.06385157 C209.564753,4.05715504 210.486871,5.26730646 211.092123,6.69430582 C211.697374,8.12130518 212,9.7371721 212,11.5419066 C212,12.7590531 211.850467,13.9167413 211.551402,15.0149712 C211.252336,16.1132011 210.793057,17.1239923 210.173565,18.0473448 C209.554072,18.9706974 208.774366,19.792621 207.834446,20.5131158 C206.894526,21.2336106 205.790832,21.8316912 204.523364,22.3073576 L211.786382,32.8 L203.497997,32.8 L197.217623,23.4825336 L197.025367,23.4825336 L186.771696,23.4615483 L186.771696,32.8 L180,32.8 L180,0 Z M197.153538,17.7115803 C198.435247,17.7115803 199.556742,17.5646833 200.518024,17.2708893 C201.479306,16.9770953 202.283934,16.5643847 202.931909,16.0327575 C203.579884,15.5011303 204.064085,14.854084 204.384513,14.0916187 C204.70494,13.3291533 204.865154,12.4792493 204.865154,11.5419066 C204.865154,9.70919173 204.224299,8.29268501 202.94259,7.29238644 C201.660881,6.29208787 199.731197,5.79193858 197.153538,5.79193858 L186.771696,5.79193858 L186.771696,17.7115803 L197.153538,17.7115803 Z"
                  id="Shape"
                ></path>
                <polygon
                  id="Path"
                  points="284 0 312.352663 0 312.352663 5.83390915 290.752663 5.83390915 290.752663 12.381318 309.881657 12.381318 309.881657 17.8584773 290.752663 17.8584773 290.752663 26.9870761 312.8 26.9870761 312.8 32.8 284 32.8"
                ></polygon>
                <path
                  d="M385.6,0 L402.867845,0 C405.38258,0 407.562017,0.272808701 409.406156,0.818426104 C411.250295,1.36404351 412.780092,2.15099168 413.995547,3.17927063 C415.211002,4.20754958 416.115608,5.46316912 416.709365,6.94612924 C417.303122,8.42908936 417.6,10.100917 417.6,11.9616123 C417.6,13.710386 417.306614,15.3262529 416.719843,16.8092131 C416.133071,18.2921732 415.235451,19.5757731 414.026981,20.6600128 C412.818511,21.7442525 411.292207,22.590659 409.448068,23.1992322 C407.603929,23.8078055 405.424492,24.1120921 402.909758,24.1120921 L392.243091,24.0911068 L392.243091,32.8 L385.6,32.8 L385.6,0 Z M403.035494,18.3411388 C404.292862,18.3411388 405.393058,18.1907443 406.336084,17.8899552 C407.279109,17.5891661 408.068457,17.1624653 408.704126,16.6098528 C409.339795,16.0572403 409.8148,15.3857112 410.129142,14.5952655 C410.443484,13.8048198 410.600655,12.9269354 410.600655,11.9616123 C410.600655,10.0169759 409.968478,8.50253785 408.704126,7.41829814 C407.439773,6.33405843 405.550229,5.79193858 403.035494,5.79193858 L392.243091,5.79193858 L392.243091,18.3411388 L403.035494,18.3411388 Z"
                  id="Shape"
                ></path>
                <polygon
                  id="Path"
                  points="488.8 0 495.2 0 495.2 32.8 488.8 32.8"
                ></polygon>
                <polygon
                  id="Path"
                  points="578.638205 5.83390915 566.4 5.83390915 566.4 0 597.6 0 597.6 5.83390915 585.361795 5.83390915 585.361795 32.8 578.638205 32.8"
                ></polygon>
              </g>
            </g>
            <g id="Logo" transform="translate(249.600000, 0.000000)">
              <polygon
                id="Path-10"
                fill="#FFA20E"
                points="4.34114053 64.5 1.20490854e-16 76.15 31.1863544 88 0 100.6 4.34114053 111.95 42.8625255 97 42.8625255 111.95 55.4867617 111.95 55.4867617 97 93.6588595 111.95 97.9501018 100.6 65.9653768 88.25 97.9501018 75.35 93.6588595 64.5 49.0997963 80.9"
              ></polygon>
              <g
                id="Group-10"
                transform="translate(20.857434, -0.000000)"
                fill="#FF0000"
              >
                <path
                  d="M12.6945726,78.0261566 L5.73550498,68.6377285 C2.38461867,64.1170719 2.62435388,57.8753141 6.31211813,53.625 L10.5780241,48.7083515 C11.9236167,47.1574955 12.8890545,45.3142452 13.3976578,43.325 C13.9083387,41.3276293 14.0233671,39.2496497 13.7362633,37.2081169 L13.3872292,34.7262117 C13.2436398,33.705179 13.9549482,32.7610662 14.975981,32.6174768 C15.5598178,32.535371 16.1481312,32.7338747 16.5628761,33.1529134 L20.3506632,36.9799157 C24.5986635,41.2718958 24.8868349,48.0901803 21.0161337,52.7252932 L19.2631045,54.8245222 C16.3293191,58.3376909 16.3442307,63.4509555 19.2984566,66.9469532 L21.7021952,69.7915106 C23.380825,71.7779823 25.9784191,72.7383724 28.5455612,72.32166 C31.1769587,71.8945174 33.4992326,70.3603861 34.9245706,68.1075844 L39.9434827,60.175 C40.5294551,59.248848 40.253685,58.0230291 39.3275331,57.4370567 C38.5862148,56.9680279 37.6248965,57.0413954 36.9633419,57.6174912 L30.645987,63.1187776 C30.054319,63.6340146 29.1569952,63.5720551 28.6417582,62.9803871 C28.2931163,62.5800272 28.1968051,62.018676 28.392057,61.525 L29.65302,58.3367745 C30.2418473,56.8479804 30.7105516,55.3144394 31.054642,53.7508451 L31.6104888,51.225 C32.2908669,48.1332676 31.5751191,44.8987995 29.6538164,42.3827868 L24.9247626,36.1899268 C20.1855183,29.9837218 19.4394055,21.6047148 23.0075111,14.6587778 L27.6818427,5.55937976 C28.0632617,4.8168814 28.9743767,4.52416876 29.7168751,4.90558779 C30.2208274,5.16446645 30.5376782,5.6834437 30.5376782,6.25 L30.5376782,9.58847686 C30.5376782,11.5249432 30.7251723,13.4568604 31.0975361,15.3571885 L31.6104888,17.975 C32.3063001,21.5260154 34.1515077,24.7493113 36.8613163,27.147394 L42.237421,31.9050526 C45.5087635,34.8000725 48.1083996,38.3745925 49.854632,42.3787771 L52.5677189,48.6 C55.8438781,56.1123714 54.5025059,64.8396476 49.1215707,71.0214336 L44.814187,75.9698902 C40.6154335,80.7935491 34.7936376,83.9101137 28.4512437,84.7294158 C22.3724688,85.514664 16.3444746,82.9502132 12.6945726,78.0261566 Z"
                  id="Path-9"
                ></path>
              </g>
            </g>
            <g
              id="®"
              transform="translate(357.000000, 98.000000)"
              fill="#FFA20E"
              fillRule="nonzero"
            >
              <path
                d="M21,10.4300666 C21,4.57564225 16.4043768,0 10.5699334,0 L10.4300666,0 C4.57564225,0 0,4.57564225 0,10.4300666 L0,10.5699334 C0,16.4043768 4.57564225,21 10.4300666,21 L10.5699334,21 C16.4043768,21 21,16.4043768 21,10.5699334 L21,10.4300666 Z M19.0618459,10.4300666 L19.0618459,10.5699334 C19.0618459,15.3254044 15.3254044,19.0618459 10.5699334,19.0618459 L10.4300666,19.0618459 C5.67459562,19.0618459 1.93815414,15.3254044 1.93815414,10.5699334 L1.93815414,10.4300666 C1.93815414,5.67459562 5.67459562,1.93815414 10.4300666,1.93815414 L10.5699334,1.93815414 C15.3254044,1.93815414 19.0618459,5.67459562 19.0618459,10.4300666 Z M6.41389153,15.9448145 L8.43196955,15.9448145 L8.43196955,11.9885823 L10.4700285,11.9885823 C10.5499524,11.9885823 10.6098953,11.9885823 10.6698382,11.9686013 L12.9476689,15.9448145 L15.2654615,15.9448145 L12.7278782,11.6489058 C14.0865842,11.1893435 14.9457659,9.99048525 14.9457659,8.57183635 L14.9457659,8.55185538 C14.9457659,6.33396765 13.4471931,5.05518554 10.9096099,5.05518554 L6.41389153,5.05518554 L6.41389153,15.9448145 Z M8.45195052,10.3301618 L8.45195052,6.79352997 L10.6898192,6.79352997 C12.0285442,6.79352997 12.887726,7.47288297 12.887726,8.55185538 C12.887726,9.65080875 12.0685062,10.3301618 10.7297812,10.3301618 L8.45195052,10.3301618 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
