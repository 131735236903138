import React, { memo } from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";

const EventCard = memo(({ event, isPast, onClick, typeClassName = "text-orange-500", minWidth = "280px" }) => {
  const getEventStatusInfo = (event) => {
    if (isPast) return { text: "Evento pasado", className: "text-zinc-400" };
    
    const now = new Date();
    const registrationDeadline = new Date(event.registration_deadline);
    
    if (event.status === "registration_open" && now <= registrationDeadline) {
      return { 
        text: "Registro abierto", 
        className: "text-green-500",
        showRegistrationButton: true 
      };
    }
    
    if (event.status === "published") {
      return { 
        text: "Próximamente", 
        className: "text-blue-500" 
      };
    }
    
    if (event.status === "draft") {
      return { 
        text: "No publicado", 
        className: "text-purple-500" 
      };
    }
    
    if (event.status === "cancelled") {
      return { 
        text: "Cancelado", 
        className: "text-red-800" 
      };
    }
    
    return { text: "Registro cerrado", className: "text-zinc-400" };
  };

  const statusInfo = getEventStatusInfo(event);

  return (
    <div
      className={`flex-none bg-zinc-900 relative overflow-hidden rounded-lg cursor-pointer group scale-[0.98] hover:scale-[1] hover:opacity-90 transition-all duration-300 ease-in-out ${
        isPast 
          ? "opacity-70 hover:opacity-90" 
          : "border-transparent"
      }`}
	  style={{ width: minWidth }}
      onClick={() => onClick(event)}
    >
      <div className="relative h-[175px]">
        {event.photo_url || event.image_url ? (
          <img
            src={event.photo_url || event.image_url}
            alt={event.name}
            className={`absolute inset-0 object-cover w-full h-full ${
              isPast ? "grayscale" : ""
            }`}
            loading="lazy"
          />
        ) : (
          <div className="absolute inset-0 bg-zinc-800" />
        )}

        {/* Gradient Overlay */}
        <div className="absolute inset-0 transition-opacity bg-gradient-to-b from-transparent to-zinc-900" />
      </div>

      {/* Event Info */}
      <div className="relative bottom-0 left-0 right-0 p-4 pt-0 mt-[-25px]">
        <div className={`mb-1 text-sm font-medium ${typeClassName}`}>
          {event.program?.name || "Firepit Mix"}
        </div>
        <h3 className="mb-2 text-2xl font-bold text-white">{event.name}</h3>
        <div className="flex flex-col gap-2">
          <div className="text-sm text-zinc-300">
            {format(new Date(event.start_date), "d 'de' MMMM", {
              locale: es,
            })}
            {", "}
            {format(new Date(event.start_date), "h:mm aaa", {
              locale: es,
            })}
          </div>
          <div className={`text-sm font-medium ${statusInfo.className}`}>
            {statusInfo.text}
          </div>
        </div>
      </div>
    </div>
  );
});

export default EventCard; 