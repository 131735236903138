import React, { useState, useEffect } from "react";
import { Search, Plus, Edit2, Trash2, Users } from "lucide-react";
import AdminNav from "./AdminNav";
import Modal from "../common/Modal";
import api from "../../services/api";
import CreateProgramModal from "../programs/CreateProgramModal";
import EditProgramModal from "../programs/EditProgramModal";

const Programs = () => {
  const [programs, setPrograms] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [activeTab, setActiveTab] = useState("activos");

  const tabs = [
    { id: "activos", label: "Activos" },
    { id: "inactivos", label: "Inactivos" },
  ];

  useEffect(() => {
    fetchPrograms();
  }, []);

  const fetchPrograms = async () => {
    try {
      setLoading(true);
      setError("");
      const response = await api.get("/programs");
      setPrograms(response || []);
    } catch (err) {
      setError("Error al cargar los programas");
      console.error("Error fetching programs:", err);
      setPrograms([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (formData) => {
    try {
      if (selectedProgram) {
        await api.put(`/programs/${selectedProgram.id}`, formData);
      } else {
        await api.post("/programs", formData);
      }
      fetchPrograms();
      handleCloseModals();
    } catch (err) {
      setError("Error al guardar el programa");
      console.error("Error saving program:", err);
    }
  };

  const handleDelete = async () => {
    try {
      await api.patch(`/programs/${selectedProgram.id}/toggle-active`);
      fetchPrograms();
      handleCloseModals();
    } catch (err) {
      setError("Error al eliminar el programa");
      console.error("Error deleting program:", err);
    }
  };

  const handleEdit = (program) => {
    setSelectedProgram(program);
    setShowEditModal(true);
  };

  const handleCloseModals = () => {
    setShowCreateModal(false);
    setShowEditModal(false);
    setShowDeleteModal(false);
    setSelectedProgram(null);
  };

  const filteredPrograms = programs.filter(
    (program) =>
      (program?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        program?.short_description
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())) &&
      program?.is_active === (activeTab === "activos")
  );

  return (
    <AdminNav>
      <div className="flex flex-col space-y-6">
        {/* Header with Title and Create Button */}
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-bold text-white">Programas</h1>
            <p className="mt-1 text-sm text-zinc-400">
              Gestiona los programas de Firepit
            </p>
          </div>
          <div className="flex space-x-3">
            <button
              className="flex items-center px-4 py-4 space-x-2 text-white transition-colors bg-orange-500 rounded-full md:rounded-lg md:py-2 hover:bg-orange-600"
              onClick={() => setShowCreateModal(true)}
            >
              <Plus className="w-4 h-4" />
              <span className="hidden md:block">Crear Programa</span>
            </button>
          </div>
        </div>

        {/* Tabs */}
        <div className="flex mb-6 space-x-4 border-b border-zinc-700">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`px-4 py-2 -mb-px text-sm font-medium transition-colors border-b-2 ${
                activeTab === tab.id
                  ? "text-orange-500 border-orange-500"
                  : "text-zinc-400 border-transparent hover:text-zinc-300"
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>

        {/* Search Bar */}
        <div className="relative w-full">
          <Search className="absolute w-5 h-5 text-zinc-400 -translate-y-1/2 pointer-events-none left-4 top-1/2" />
          <input
            type="text"
            style={{ paddingLeft: "44px" }}
            className="w-full pl-12 pr-4 text-white placeholder-zinc-400 bg-zinc-900 border border-zinc-700 rounded-lg h-11 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
            placeholder="Buscar programas..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Programs List */}
        <div className="divide-y divide-zinc-800 rounded-lg bg-zinc-900/50">
          {loading ? (
            <div className="flex items-center justify-center py-8">
              <div className="w-8 h-8 border-b-2 border-orange-500 rounded-full animate-spin"></div>
            </div>
          ) : error ? (
            <div className="p-4 mb-6 text-red-500 border border-red-500 rounded-lg bg-red-500/10">
              {error}
            </div>
          ) : filteredPrograms.length === 0 ? (
            <div className="py-8 text-center text-zinc-400">
              {searchTerm
                ? "No se encontraron programas que coincidan con tu búsqueda."
                : "No hay programas. ¡Crea uno para comenzar!"}
            </div>
          ) : (
            <div className="divide-y divide-zinc-800">
              {filteredPrograms.map((program) => (
                <div
                  key={program.id}
                  className="flex items-start justify-between p-6 transition-colors hover:bg-zinc-800/50"
                >
                  <div className="flex-1">
                    <div className="flex items-center space-x-4">
                      {program.logo_url && (
                        <img
                          src={program.logo_url}
                          alt={program.name}
                          className="object-cover w-12 h-12 rounded-lg"
                        />
                      )}
                      <div>
                        <h3 className="flex items-center text-lg font-semibold text-white">
                          {program.name}
                          {!program.is_active && (
                            <span className="ml-2 px-2 py-0.5 bg-red-500/10 text-red-500 rounded-full text-xs">
                              Inactivo
                            </span>
                          )}
                        </h3>
                        <p className="mt-1 text-sm text-zinc-400">
                          {program.short_description}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex ml-4 space-x-2">
                    <button
                      className="p-2 text-zinc-400 transition-colors rounded-lg hover:text-white hover:bg-zinc-700"
                      onClick={() => handleEdit(program)}
                      title="Editar programa"
                    >
                      <Edit2 className="w-4 h-4" />
                    </button>
                    <button
                      className="p-2 text-zinc-400 transition-colors rounded-lg hover:text-red-500 hover:bg-zinc-700"
                      onClick={() => {
                        setSelectedProgram(program);
                        setShowDeleteModal(true);
                      }}
                      title={
                        program.is_active
                          ? "Desactivar programa"
                          : "Activar programa"
                      }
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Create Modal */}
      <CreateProgramModal
        isOpen={showCreateModal}
        onClose={handleCloseModals}
        onSubmit={handleSubmit}
      />

      {/* Edit Modal */}
      <EditProgramModal
        isOpen={showEditModal}
        onClose={handleCloseModals}
        onSubmit={handleSubmit}
        program={selectedProgram}
      />

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={showDeleteModal}
        onClose={handleCloseModals}
        title="Confirmar Acción"
        size="small"
      >
        <div className="p-6">
          <p className="mb-6">
            ¿Estás seguro que deseas{" "}
            {selectedProgram?.is_active ? "desactivar" : "activar"} "
            {selectedProgram?.name}"?
          </p>
          <div className="flex justify-end space-x-3">
            <button
              className="px-4 py-2 text-zinc-400 transition-colors hover:text-white"
              onClick={handleCloseModals}
            >
              Cancelar
            </button>
            <button
              className="px-4 py-2 text-white transition-colors bg-red-500 rounded-lg hover:bg-red-600"
              onClick={handleDelete}
            >
              {selectedProgram?.is_active ? "Desactivar" : "Activar"}
            </button>
          </div>
        </div>
      </Modal>
    </AdminNav>
  );
};

export default Programs;
