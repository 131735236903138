import React, { useState, useEffect } from "react";
import { getCommunityEvents } from "../../services/api";
import { useCommunity } from "../../contexts/CommunityContext";
import { useAuth } from "../../contexts/AuthContext";
import EventModal from "../events/EventModal";
import EventCard from "../events/EventCard";

const UpcomingEvents = ({ programId }) => {
  const [events, setEvents] = useState({ upcoming: [], past: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { activeCommunity } = useCommunity();
  const { user } = useAuth();

  const canViewAllEvents = user?.role === 'admin' || 
    (activeCommunity?.pivot?.role === 'admin');

  const handleEventUpdate = (updatedEvent) => {
    setEvents(prevEvents => {
      const now = new Date();
      const eventDate = new Date(updatedEvent.start_date);
      const isUpcoming = eventDate > now;

      // Remove the event from both arrays first
      const filteredUpcoming = prevEvents.upcoming.filter(e => e.id !== updatedEvent.id);
      const filteredPast = prevEvents.past.filter(e => e.id !== updatedEvent.id);

      // Add the updated event to the appropriate array if it meets the visibility criteria
      if (canViewAllEvents || !['draft', 'cancelled'].includes(updatedEvent.status)) {
        if (isUpcoming) {
          filteredUpcoming.push(updatedEvent);
          filteredUpcoming.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
        } else {
          filteredPast.push(updatedEvent);
          filteredPast.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
        }
      }

      return {
        upcoming: filteredUpcoming,
        past: filteredPast
      };
    });

    // Update the selected event if it's the one being displayed in modal
    if (selectedEvent?.id === updatedEvent.id) {
      setSelectedEvent(updatedEvent);
    }
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        if (!activeCommunity?.id) return;

        const response = await getCommunityEvents(activeCommunity.id);
        const allEvents = Array.isArray(response)
          ? response
          : response.data || [];

        // Filter events for this program and based on permissions
        const programEvents = allEvents.filter(
          (event) =>
            event.program_id === programId &&
            (canViewAllEvents || !['draft', 'cancelled'].includes(event.status))
        );

        const now = new Date();

        // Split into upcoming and past events
        const upcoming = programEvents
          .filter((event) => new Date(event.start_date) > now)
          .sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

        const past = programEvents
          .filter((event) => new Date(event.start_date) <= now)
          .sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

        setEvents({
          upcoming,
          past,
        });
      } catch (err) {
        console.error("Error fetching events:", err);
        setError("Failed to load events");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [activeCommunity, programId, canViewAllEvents]);

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="w-8 h-8 border-b-2 border-orange-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-red-500 rounded-lg bg-red-500/10">{error}</div>
    );
  }

  if (events.upcoming.length === 0 && events.past.length === 0) {
    return (
      <div className="p-6 text-center rounded-lg bg-zinc-900">
        <p className="text-zinc-400">Este programa no tiene eventos programados.</p>
      </div>
    );
  }

  return (
    <div className="space-y-12">
      {/* Upcoming Events Section */}
      {events.upcoming.length > 0 && (
        <div>
          <h3 className="mb-6 text-2xl font-bold text-white">
            Próximos Eventos
          </h3>
          <div className="grid gap-6 sm:grid-cols-3">
            {events.upcoming.map((event) => (
              <EventCard
                key={event.id}
                event={event}
                isPast={false}
                onClick={setSelectedEvent}
                typeClassName="text-orange-500"
                minWidth="auto"
              />
            ))}
          </div>
        </div>
      )}

      {/* Past Events Section */}
      {events.past.length > 0 && (
        <div>
          <h3 className="mb-6 text-2xl font-bold text-white">Eventos Pasados</h3>
          <div className="grid gap-6 sm:grid-cols-4">
            {events.past.slice(0, 10).map((event) => (
              <EventCard
                key={event.id}
                event={event}
                isPast={true}
                onClick={setSelectedEvent}
                typeClassName="text-orange-500"
                minWidth="auto"
              />
            ))}
          </div>
          {/* Show count of additional past events */}
          {events.past.length > 10 && (
            <div className="mt-4 text-center">
              <p className="text-zinc-400">
                +{events.past.length - 10} more past events
              </p>
            </div>
          )}
        </div>
      )}

      {/* Event Modal */}
      {selectedEvent && (
        <EventModal
          event={selectedEvent}
          onClose={() => setSelectedEvent(null)}
          onEventUpdate={handleEventUpdate}
		  minWidth="auto"
        />
      )}
    </div>
  );
};

export default UpcomingEvents;
