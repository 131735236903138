import { Award, Bolt, Brain, Compass, Lightbulb, icons, MentorIcon, Rocket, Share, SquareArrowOutUpRight } from 'lucide-react';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';

const Mentores = () => {

  const benefits = [
    {
      icon: <Brain className='w-20 h-20 mb-2 text-blue-400' />, 
      title: 'Inspiración',
      description: 'Inspira a otros con tus conocimientos y experiencia, ayudando a desarrollar las ideas del futuro.'
    },
    {
      icon: <Compass className='w-20 h-20 mb-2 text-blue-400' />, 
      title: 'Orientación',
      description: 'Guía a emprendedores y visionarios en su camino hacia el éxito, ofreciendo dirección estratégica.'
    },
    {
      icon: <Lightbulb className='w-20 h-20 mb-2 text-blue-400' />, 
      title: 'Innovación',
      description: 'Forma parte de un ecosistema que fomenta la creatividad y la generación de soluciones únicas.'
    },
    {
      icon: <Award className='w-20 h-20 mb-2 text-blue-400' />, 
      title: 'Reconocimiento',
      description: 'Sé reconocido como un pilar de la comunidad y como un mentor destacado en tu industria.'
    }
  ];

  const exclusiveBenefits = [
    {
        title: "Visibilidad en la Comunidad",
        description: "Obtén reconocimiento por tus contribuciones en eventos, redes sociales y publicaciones de Firepit." 
    },
    {
        title: "Acceso Exclusivo",
        description: "Participa en sesiones cerradas con líderes de la industria y recibe información privilegiada sobre tendencias y estrategias."
    },
    {
        title: "Red de Mentores",
        description: "Conéctate con otros mentores y expertos, creando alianzas estratégicas y oportunidades de colaboración."
    },
    {
        title: "Impacto Personalizado",
        description: "Elige proyectos que te apasionen y trabaja directamente con equipos que buscan tus habilidades únicas."
    },
    {
        title: "Desarrollo Continuo",
        description: "Accede a talleres exclusivos diseñados para mejorar tus habilidades como mentor y líder."
    }
  ];

  const stories = [
    {
      quote: "Mentorear a jóvenes emprendedores me ha permitido aprender tanto como enseño. Firepit ha sido un puente increíble para conectar generaciones de innovación.",
      author: "María López",
      role: "Fundadora de Innovatech — Desde 2022"
    }
  ];

  return (
    <div className="min-h-screen bg-zinc-900">
      <Helmet>
        <title>Firepit — Conviertete en un mentor de la comunidad</title>
        <meta name="description" content="Únete al grupo selecto de mentores que están marcando la diferencia al guiar a la próxima generación de emprendedores y visionarios."/>
        
        {/* OpenGraph tags */}
        <meta property="og:title" content="Firepit — Conviertete en un mentor de la comunidad" />
        <meta property="og:description" content="Únete al grupo selecto de mentores que están marcando la diferencia al guiar a la próxima generación de emprendedores y visionarios." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://res.firep1t.com/static/mentors.png" />
        <meta property="og:url" content="https://firep1t.com/mentores" />
        
        {/* Additional meta tags */}
        <meta property="og:site_name" content="Firepit" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@firep1t_" />
      </Helmet>

      {/* Hero Section */}
      <section className="relative h-[600px] mt-20 flex items-center bg-[linear-gradient(rgba(15,15,15,0.3),rgba(0,0,0,0.7),rgba(24,24,27,1)),url('https://res.firep1t.com/static/mentors.png')] bg-center bg-cover">
        <div className="absolute inset-0" />
        <div className="relative z-10 px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h2 className="mb-4 text-sm font-bold tracking-widest text-blue-400 uppercase">Conviértete en Mentor</h2>
            <h1 className="mb-6 text-4xl font-bold md:text-6xl">
              Deja huella en el ecosistema de innovación.
            </h1>
            <p className="mt-6 text-lg leading-8 text-zinc-300">
              Únete al grupo selecto de mentores que están marcando la diferencia al guiar a la próxima generación de emprendedores y visionarios.
            </p>
          </div>
        </div>
      </section>

      {/* Why become a mentor section */}
      <div className="pt-24 pb-16 bg-zinc-900">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight text-blue-400 sm:text-4xl">
              ¿Por qué ser un Mentor Firepit?
            </h2>
            <p className="mt-6 text-lg leading-8 text-zinc-300">
              Ser mentor es una oportunidad para compartir tu experiencia, dejar un legado y aprender continuamente.
            </p>
          </div>
          <div className="grid max-w-5xl grid-cols-1 mx-auto mt-16 gap-x-8 gap-y-12 sm:grid-cols-2">
            {benefits.map((benefit) => (
              <div key={benefit.title} className="flex gap-4 p-6 rounded-lg bg-zinc-700/50">
                {benefit.icon}
                <div className='flex flex-col gap'>
                  <h3 className="text-xl font-semibold text-white">{benefit.title}</h3>
                  <p className="mt-3 text-zinc-300">{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="h-[2px] max-w-5xl mx-auto bg-zinc-700"></div>

      {/* Impact Stories */}
      <div className="py-24 bg-zinc-900">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="mb-4 text-sm font-bold tracking-widest text-blue-400 uppercase">Historias de Impacto</h2>
            <h1 className="mb-6 text-4xl font-bold md:text-6xl">
              El testimonio de nuestros {''}
              <span className="border-b-4 border-blue-500">Mentores</span>.
            </h1>
          </div>
          <div className="grid max-w-5xl grid-cols-1 gap-8 mx-auto mt-16 sm:grid-cols-2">
            {stories.map((story) => (
              <div key={story.author} className="relative p-6 rounded-lg bg-zinc-800">
                <div className="relative">
                  <blockquote className="text-lg text-zinc-300">
                    "{story.quote}"
                  </blockquote>
                  <footer className="mt-4">
                    <p className="text-base font-semibold text-white">{story.author}</p>
                    <p className="text-sm text-blue-400">{story.role}</p>
                  </footer>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Exclusive Benefits */}
      <div className="py-24 bg-zinc-800">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Beneficios Exclusivos para Mentores
            </h2>
            <p className="mt-6 text-lg leading-8 text-zinc-300">
              Ser parte de Firepit como mentor te conecta con experiencias únicas que te impulsan a crecer y contribuir de manera significativa.
            </p>
          </div>
          <div className="grid max-w-5xl grid-cols-1 mx-auto mt-16 gap-x-8 gap-y-12 sm:grid-cols-2">
            {exclusiveBenefits.map((benefit) => (
              <div key={benefit.title} className="p-6 transition-all duration-300 rounded-lg bg-zinc-700/50 ring-1 ring-blue-400/20 hover:ring-4 hover:bg-blue-200/10 hover:ring-blue-500">
                <h3 className="text-xl font-semibold text-white">{benefit.title}</h3>
                <p className="mt-3 text-zinc-300">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Application Form */}
      <div id="postulate" className="py-24 bg-zinc-900">
        <div className="px-6 mx-auto max-w-7xl lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
              Comienza tu viaje como Mentor
            </h2>
            <p className="mt-6 text-lg leading-8 text-zinc-300">
              Únete a nuestra red de mentores y deja tu huella en el ecosistema de innovación.
            </p>
            <a href='https://calendar.app.google/WtuoLQyJ5A2d3jZE7' target="_blank" className="inline-flex items-center px-6 py-3 mx-auto mt-6 text-lg font-semibold text-white transition-colors bg-blue-500 rounded-md hover:bg-blue-600" rel="noreferrer">
              <SquareArrowOutUpRight className="w-4 h-4 mr-2" />
              Agenda una reunión
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mentores;
