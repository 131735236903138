import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { X } from 'lucide-react';
import './Modal.css';

const Modal = ({ isOpen, onClose, children, title, size = 'medium', footer }) => {
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            setIsClosing(false);
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [isOpen]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            onClose();
        }, 300); // Match animation duration
    };

    if (!isOpen) {
        return null;
    }

    const modalContent = (
        <div className={`modal-container ${isClosing ? 'closing' : ''}`}>
            <div className="modal-backdrop" onClick={handleClose} />
            <div className={`modal-content modal-${size}`}>
                <div className="modal-header">
                    {title && <h2>{title}</h2>}
                    <button className="modal-close" onClick={handleClose}>
                        <X className="w-6 h-6" />
                    </button>
                </div>
                <div className="modal-body">
                    {children}
                </div>
                {footer && (
                    <div className="modal-footer">
                        {footer}
                    </div>
                )}
            </div>
        </div>
    );

    return createPortal(modalContent, document.body);
};

export default Modal; 