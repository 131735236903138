import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const AuthCallback = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const token = searchParams.get('token');
        const error = searchParams.get('error');
        const user = searchParams.get('user');

        if (token && user) {
            localStorage.setItem('token', token);
            localStorage.setItem('user', user);
            navigate('/dashboard');
        } else if (error) {
            navigate('/login', { state: { error } });
        } else {
            navigate('/login');
        }
    }, [navigate, searchParams]);

    return (
        <div className="auth-callback">
            <h2>Processing authentication...</h2>
        </div>
    );
};

export default AuthCallback; 