import React, { createContext, useContext, useState } from 'react';

export const CommunityContext = createContext(null);

export const CommunityProvider = ({ children }) => {
    const [activeCommunity, setActiveCommunity] = useState(() => {
        const stored = sessionStorage.getItem('activeCommunity');
        return stored ? JSON.parse(stored) : null;
    });

    const [userCommunities, setUserCommunities] = useState([]);

    const setCurrentCommunity = (community) => {
        setActiveCommunity(community);
        if (community) {
            sessionStorage.setItem('activeCommunity', JSON.stringify(community));
        } else {
            sessionStorage.removeItem('activeCommunity');
            setUserCommunities([]);
        }
    };

    return (
        <CommunityContext.Provider 
            value={{ 
                activeCommunity, 
                setCurrentCommunity,
                userCommunities,
                setUserCommunities
            }}
        >
            {children}
        </CommunityContext.Provider>
    );
};

export const useCommunity = () => {
    const context = useContext(CommunityContext);
    if (!context) {
        throw new Error('useCommunity must be used within a CommunityProvider');
    }
    return context;
}; 