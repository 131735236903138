import React from 'react';
import Modal from '../common/Modal';
import ProgramForm from './ProgramForm';

const EditProgramModal = ({ isOpen, onClose, onSubmit, program }) => {
  const initialData = program ? {
    name: program.name,
    short_description: program.short_description,
    description: program.description,
    logo_url: program.logo_url || '',
    photo_url: program.photo_url || '',
    is_active: program.is_active
  } : null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Edit Program"
      size="medium"
    >
      <ProgramForm
        initialData={initialData}
        onSubmit={(formData) => onSubmit(formData)}
        onCancel={onClose}
        submitLabel="Update"
      />
    </Modal>
  );
};

export default EditProgramModal; 